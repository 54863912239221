import type { useI18nContext } from "@procore/core-react";
import { object, date } from "yup";
import { getBaseSchemaForRequestAndAssignment } from "../validation-schema";

export const assignmentSchema = (
   I18n: ReturnType<typeof useI18nContext>,
   includeStatus: boolean,
   selectedWorkDays: { [key: string]: boolean },
   assignmentByAllocation: boolean,
   assignmentByTime: boolean,
) => {
   const baseSchema = getBaseSchemaForRequestAndAssignment(
      I18n,
      selectedWorkDays,
      assignmentByAllocation,
      assignmentByTime,
      includeStatus,
   );

   return object().shape({
      resource: object().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      ...baseSchema,
   });
};

export const assignmentAlertsSchema = (
   I18n: ReturnType<typeof useI18nContext>,
   shouldScheduleDelivery: boolean,
) => {
   if (!shouldScheduleDelivery) return;

   return object().shape({
      scheduled_day: date().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      hour: object().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      minute: object().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      day_night: object().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
   });
};

import type { AssignmentFormValues } from "./types";
import { commonInitialValuesForRequestAndAssignment } from "../constants";

export const formInitialValues = (projectId?: string): AssignmentFormValues => {
   return {
      ...commonInitialValuesForRequestAndAssignment,
      resource: null,
      project: projectId
         ? {
              id: projectId,
              label: "", // Label is required for typing but the form field only needs ID
           }
         : null,
   };
};

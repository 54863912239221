import type { Dispatch, SetStateAction } from "react";
import React, { useMemo } from "react";
import { MultiSelect, Checkbox, Switch, Panel, Box, Token } from "@procore/core-react";
import type { ganttFilterOptionTypes, ganttFilterType, projectStatus } from "./prop-types";
import { FilterTaskType } from "./prop-types";
import type { JobTitle } from "@laborchart-modules/common/dist/postgres/schemas/types/job_titles";
import { INITIAL_GANTT_FILTER } from "./gantt-container";
import "./gantt-container.css";

export const statuses: projectStatus[] = [
   { id: 0, name: "active" },
   { id: 1, name: "inactive" },
   { id: 2, name: "pending" },
];

// export const dateQualifiers: { label: string; name: string }[] = [
//    { label: dateQualifier.ONORBEFORE, name: "On or before" },
//    { label: dateQualifier.ONORAFTER, name: "On or after" },
//    { label: dateQualifier.AFTER, name: "After" },
//    { label: dateQualifier.BEFORE, name: "Before" },
//    { label: dateQualifier.ON, name: "On" },
// ];

type GanttFilterPanelProps = {
   ganttFilter: ganttFilterType;
   setGanttFilter: Dispatch<SetStateAction<ganttFilterType>>;
   onClose: () => void;
   jobTitles: JobTitle[];
};

export const GanttFilterPanel = (props: GanttFilterPanelProps) => {
   const { ganttFilter, setGanttFilter, onClose, jobTitles } = props;
   const jobTitlesMapped: Array<{ id: string; name: string }> = useMemo(
      () =>
         jobTitles.map((jobTitle) => ({
            id: jobTitle.id,
            name: jobTitle.name,
         })),
      [jobTitles],
   );
   // const getDateQualifiersName = (label: dateQualifier | null): string | null => {
   //    return dateQualifiers.find((qualifier) => qualifier.label === label)?.name ?? null;
   // };
   const handleFilterChange = (value: any, filterType: ganttFilterOptionTypes): void => {
      setGanttFilter((ganttFilter) => ({
         ...ganttFilter,
         [filterType]: value,
      }));
   };
   const handleOnlyShowChange = (onlyShow: FilterTaskType): void => {
      const current = ganttFilter.onlyShow;
      const shouldRemove = current.includes(onlyShow);
      let updated: FilterTaskType[] = [];

      if (shouldRemove) updated = current.filter((el) => el !== onlyShow);
      else updated = [...current, onlyShow];

      setGanttFilter((ganttFilter) => ({
         ...ganttFilter,
         onlyShow: updated,
      }));
   };
   // const handleDateQualifierChange = (item: string, dateFilter: string): void => {
   //    setGanttFilter((ganttFilter: any) => ({
   //       ...ganttFilter,
   //       [dateFilter]: {
   //          qualifier: item,
   //          date: ganttFilter[dateFilter].date,
   //       },
   //    }));
   // };
   // const handleDateChange = (date: Date | null, dateFilter: string): void => {
   //    setGanttFilter((ganttFilter: any) => ({
   //       ...ganttFilter,
   //       [dateFilter]: {
   //          qualifier: ganttFilter[dateFilter].qualifier,
   //          date: date?.toLocaleDateString() || "",
   //       },
   //    }));
   // };

   const clearAllFilters = (): void => {
      setGanttFilter(INITIAL_GANTT_FILTER);
   };

   const tokenRenderer = ({ option, removeToken }: any) => (
      <Token
         style={{
            margin: "0 4px 0 -2px",
            padding: "0",
         }}
      >
         <Token.Label
            style={{
               paddingLeft: "8px",
            }}
         >
            {option.name}
         </Token.Label>
         <Token.Remove
            onClick={removeToken}
            style={{
               marginRight: "1px",
            }}
         />
      </Token>
   );

   return (
      <div className="filterPanel">
         <Panel
            style={{
               border: "1px solid #e0e0e0",
               width: "400px",
            }}
         >
            <Panel.Header onClose={onClose}>
               <Panel.Title>Filters</Panel.Title>
               <Panel.HeaderAction title={"Reset"} onClick={clearAllFilters}>
                  <Panel.Title style={{ fontSize: "14px" }}>Clear all filters</Panel.Title>
               </Panel.HeaderAction>
            </Panel.Header>
            <Panel.Body>
               <Box style={{ flexDirection: "column" }}>
                  <label className="ganttFilterLabel">
                     <h3 className="filterName">Job Title</h3>
                     <MultiSelect
                        getLabel={(jobTitle) => jobTitle.name}
                        options={jobTitlesMapped}
                        tokenRenderer={tokenRenderer}
                        value={ganttFilter.jobTitles}
                        onChange={(value) => handleFilterChange(value, "jobTitles")}
                        className="ganttFilterMultiselect"
                     />
                  </label>
                  <label className="ganttFilterLabel">
                     <h3 className="filterName">Status</h3>
                     <MultiSelect
                        getLabel={(status) => status.name}
                        options={statuses}
                        tokenRenderer={tokenRenderer}
                        value={ganttFilter.projectStatuses}
                        onChange={(value) => handleFilterChange(value, "projectStatuses")}
                        className="ganttFilterMultiselect"
                     />
                  </label>
                  <div className="ganttFilterLabel">
                     <h3 className="filterName">Only Show</h3>
                     <label style={{ marginBottom: "10px" }}>
                        <Checkbox
                           checked={ganttFilter.onlyShow.includes(FilterTaskType.ASSIGNMENTS)}
                           onChange={() => handleOnlyShowChange(FilterTaskType.ASSIGNMENTS)}
                        >
                           Assignments
                        </Checkbox>
                     </label>
                     <label style={{ marginBottom: "10px" }}>
                        <Checkbox
                           checked={ganttFilter.onlyShow.includes(FilterTaskType.REQUESTS)}
                           onChange={() => handleOnlyShowChange(FilterTaskType.REQUESTS)}
                        >
                           Requests
                        </Checkbox>
                     </label>
                  </div>
                  <label className="ganttFilterLabel">
                     <h3 className="filterName">Hide Empty Projects</h3>
                     <Switch
                        checked={ganttFilter.hideEmptyProject}
                        onChange={() =>
                           handleFilterChange(!ganttFilter.hideEmptyProject, "hideEmptyProject")
                        }
                     />
                  </label>
                  {/*<div className="ganttFilterLabel">
                     <h3 className="filterName">Start Date</h3>
                     <div style={{ display: "flex" }}>
                        <Select
                           style={{ maxWidth: "40%", marginRight: "15px" }}
                           label={getDateQualifiersName(ganttFilter.startDate.qualifier)}
                           onSelect={({ item }) => handleDateQualifierChange(item, "startDate")}
                        >
                           {dateQualifiers.map(
                              (dateQualifier: Record<string, string>, index: number) => {
                                 return (
                                    <Select.Option
                                       key={index}
                                       value={dateQualifier.label}
                                       selected={
                                          ganttFilter.startDate.qualifier === dateQualifier.label
                                       }
                                    >
                                       {dateQualifier.name}
                                    </Select.Option>
                                 );
                              },
                           )}
                        </Select>
                        <DateSelect
                           disabled={!ganttFilter.startDate.qualifier}
                           value={
                              ganttFilter.startDate.date
                                 ? new Date(ganttFilter.startDate.date)
                                 : undefined
                           }
                           onChange={(date) => handleDateChange(date, "startDate")}
                        />
                     </div>
                  </div>
                  <div className="ganttFilterLabel">
                     <h3 className="filterName">End Date</h3>
                     <div style={{ display: "flex" }}>
                        <Select
                           style={{ maxWidth: "40%", marginRight: "15px" }}
                           label={getDateQualifiersName(ganttFilter.endDate.qualifier)}
                           onSelect={({ item }) => handleDateQualifierChange(item, "endDate")}
                        >
                           {dateQualifiers.map(
                              (dateQualifier: Record<string, string>, index: number) => {
                                 return (
                                    <Select.Option
                                       key={index}
                                       value={dateQualifier.label}
                                       selected={
                                          ganttFilter.startDate.qualifier === dateQualifier.label
                                       }
                                    >
                                       {dateQualifier.name}
                                    </Select.Option>
                                 );
                              },
                           )}
                        </Select>
                        <DateSelect
                           disabled={!ganttFilter.endDate.qualifier}
                           value={
                              ganttFilter.endDate.date
                                 ? new Date(ganttFilter.endDate.date)
                                 : undefined
                           }
                           onChange={(date) => handleDateChange(date, "endDate")}
                        />
                     </div>
                  </div>*/}
               </Box>
            </Panel.Body>
         </Panel>
      </div>
   );
};

import { DateUtils } from "@/lib/utils/date";
import type {
   ColumnMap,
   IntegratedField,
   ProjectListServerFilter,
   SortModel,
   StreamResponseConversion,
} from "@/react/prop-types";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import { FilterFieldType } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import type { GetIntegratedFieldsResponse } from "@laborchart-modules/lc-core-api/dist/api/company/get-integrated-fields";
import {
   LinkCellRenderer,
   MultiSelectFilterRenderer,
   type ColumnDefinition,
   type DataTableConfig,
} from "@procore/data-table";
import { DateFilter } from "../data-table/custom-filters/date-filter";
import MultiSelectPillFilter from "../data-table/custom-filters/multiselect-pill-filter";
import { NumericValueFilter } from "../data-table/custom-filters/numeric-value-filter";
import { StatusFilter } from "../data-table/custom-filters/status-filter";
import { convertDataTableFilters } from "../data-table/helpers";
import { FindProjectsSortBy } from "./project-list-enums";
import type { CoreApiSort } from "./project-list-prop-types";
import type { AppliedRoleOption } from "@laborchart-modules/lc-core-api/dist/api/projects/get-applied-role-options";
import { streamPeopleOptions } from "../common/queries/queries";

export function convertDataTableSort(sort: SortModel[]): CoreApiSort {
   if (sort.length == 0) return {};
   const sortObj: CoreApiSort = {
      sort_by: sortFieldMaps[sort[0].field],
      direction: sortOrderMap[sort[0].sort],
   };
   // If the sort field is custom_field, then add the custom_field_id
   // fieldId will only be there in case of custom_field, it has been added explicitly to identify the custom_field
   if (sort[0].fieldId) {
      sortObj["sort_by"] = sortFieldMaps["custom_field"];
      sortObj["custom_field_id"] = sort[0].fieldId;
   }
   return sortObj;
}

export const defaultProjectListTableConfig: DataTableConfig = {
   columnState: [
      {
         field: "project_name",
         hidden: false,
         pinned: null,
         width: 140,
         sort: "asc",
         sortIndex: 0,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "color",
         hidden: false,
         pinned: null,
         width: 140,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "project_hash",
         hidden: false,
         pinned: null,
         width: 230,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "address_1",
         hidden: false,
         pinned: null,
         width: 368,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "address_2",
         hidden: false,
         pinned: null,
         width: 368,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "city_town",
         hidden: false,
         pinned: null,
         width: 140,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "state_province",
         hidden: false,
         pinned: null,
         width: 140,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "zipcode",
         hidden: false,
         pinned: null,
         width: 140,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "status",
         hidden: false,
         pinned: null,
         width: 140,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "groups",
         hidden: true,
         pinned: null,
         width: 140,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "tag_instances",
         hidden: true,
         pinned: null,
         width: 140,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "bid_rate",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "percent_complete",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "wage_overrides",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "start_date",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "est_end_date",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "project_type",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "customer_name",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "created_by",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "created_at",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "country",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "daily_start_time",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "daily_end_time",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "timezone",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "procore_id",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
   ],
   columnGroupState: [],
   filters: {},
   rowHeight: 49,
   serverFilters: [],
};

export function filterGroupDetails({
   groupOptions,
   projectGroupList,
}: {
   groupOptions: StreamResponseConversion | null;
   projectGroupList: string[];
}) {
   // Filter the groupOptions based on the projectGroupList
   const filtered = groupOptions?.filter((groupOption) =>
      projectGroupList.includes(groupOption.id),
   );
   return filtered;
}

const DATE_FIELDS: any[] = ["start_date", "est_end_date"];
const NUMERIC_FIELDS: any[] = ["bid_rate", "percent_complete"];
const NESTED_FIELDS_TYPE: any[] = [FilterFieldType.BOOL, FilterFieldType.MULTI_SELECT];
const NUMERIC_FIELDS_TYPE: any[] = [FilterFieldType.NUMBER, FilterFieldType.CURRENCY];

export const convertProjectDataTableFilters = (filters: ProjectListServerFilter[]) =>
   convertDataTableFilters(
      filters,
      {
         nameMapper: filterNameMaps,
         typeMapper: filterTypeMaps,
      },
      {
         numeric: NUMERIC_FIELDS,
         nestedTypes: NESTED_FIELDS_TYPE,
         numericTypes: NUMERIC_FIELDS_TYPE,
         date: DATE_FIELDS,
      },
   );

// Filter Name Maps
export const filterNameMaps: { [key: string]: string } = {
   start_date: "Project Start Date",
   est_end_date: "Project End Date",
   status: "Project Status",
   bid_rate: "Project Estimated Average Rate",
   percent_complete: "Project Percent Complete",
   tag_instances: "Project Tags",
};
// Filter Type Maps
export const filterTypeMaps: { [key: string]: FilterFieldType } = {
   start_date: FilterFieldType.DATE,
   est_end_date: FilterFieldType.DATE,
   status: FilterFieldType.SELECT,
   bid_rate: FilterFieldType.NUMBER,
   percent_complete: FilterFieldType.NUMBER,
   tag_instances: FilterFieldType.MULTI_SELECT,
   date: FilterFieldType.DATE,
   bool: FilterFieldType.BOOL,
   currency: FilterFieldType.CURRENCY,
   "multi-select": FilterFieldType.MULTI_SELECT,
   number: FilterFieldType.NUMBER,
   select: FilterFieldType.SELECT,
   text: FilterFieldType.TEXT,
};

// Sort Field Maps
export const sortFieldMaps: { [key: string]: FindProjectsSortBy } = {
   project_name: FindProjectsSortBy.NAME,
   start_date: FindProjectsSortBy.START_DATE,
   est_end_date: FindProjectsSortBy.EST_END_DATE,
   project_hash: FindProjectsSortBy.NUMBER,
   address_1: FindProjectsSortBy.ADDRESS_1,
   address_2: FindProjectsSortBy.ADDRESS_2,
   city_town: FindProjectsSortBy.CITY_TOWN,
   state_province: FindProjectsSortBy.STATE_PROVINCE,
   zipcode: FindProjectsSortBy.ZIP_CODE,
   project_type: FindProjectsSortBy.TYPE,
   status: FindProjectsSortBy.STATUS,
   customer_name: FindProjectsSortBy.CUSTOMER_NAME,
   created_at: FindProjectsSortBy.CREATED_AT,
   country: FindProjectsSortBy.COUNTRY,
   daily_start_time: FindProjectsSortBy.DAILY_START_TIME,
   daily_end_time: FindProjectsSortBy.DAILY_END_TIME,
   timezone: FindProjectsSortBy.TIMEZONE,
   percent_complete: FindProjectsSortBy.PERCENT_COMPLETE,
   wage_overrides: FindProjectsSortBy.WAGE_OVERRIDES,
   bid_rate: FindProjectsSortBy.BID_RATE,
   custom_field: FindProjectsSortBy.CUSTOM_FIELD,
   tag_instances: FindProjectsSortBy.TAG_INSTANCES,
   procore_id: FindProjectsSortBy.HAS_PROCORE_MAPPING,
};

// Sort Order Maps
const sortOrderMap: { [key: string]: Order } = {
   asc: Order.ASCENDING,
   desc: Order.DESCENDING,
};

//Function to get date filter token text
export function getDateFilterTokenText(item: any): string {
   let tokenText = "";
   if (item.value.length > 0) {
      const selectedDate = DateUtils.getShortNumericDate(new Date(item.value[0].date));
      const label = item.value[0].classifier.label;
      tokenText = `${item.headerName} ${label} ${selectedDate}`;
   }
   return tokenText;
}
//Function to get custom filter token text
export function customFilterTokenText(item: any, field: string, I18n: any, label?: string): string {
   return `${I18n.t((label ?? "views.company.workforce_planning.projects.") + field)} ${
      item.value.length > 0 ? item.value[0].classifier.value : ""
   } ${item.value.length > 0 ? item.value[0].stringValue : ""}`;
}

export function editableFields(integratedFields: GetIntegratedFieldsResponse | null) {
   return integratedFields?.data.projects_integrated_fields
      .filter((field) => field.locked)
      .reduce((acc, field) => {
         acc[`${field.property}_editable`] = field.locked ? false : true;
         return acc;
      }, {} as { [key: string]: any });
}

export function filterGroupIds({
   groupOptions,
   editedGroupIds,
}: {
   groupOptions: StreamResponseConversion | null;
   editedGroupIds: StreamResponseConversion;
}) {
   const set1 = new Set(editedGroupIds?.map((item) => item.id));
   const resultObject = {} as any;
   groupOptions?.forEach((item) => {
      resultObject[item.id] = set1.has(item.id);
   });
   return resultObject;
}

export function isFieldLocked(integratedField: IntegratedField[], field: string) {
   return integratedField.some(
      (integratedField: IntegratedField) =>
         integratedField.property === field && integratedField.locked,
   );
}
export const columnHeadersMap: ColumnMap = {
   name: "project_name",
   job_number: "project_hash",
   color: "color",
   address_1: "address_1",
   address_2: "address_2",
   city_town: "city_town",
   state_province: "state_province",
   zip_code: "zipcode",
   type: "project_type",
   status: "status",
   customer_name: "customer_name",
   created_by: "created_by",
   created_at: "created_at",
   country: "country",
   start_date: "start_date",
   est_end_date: "est_end_date",
   daily_start_time: "daily_start_time",
   daily_end_time: "daily_end_time",
   timezone: "timezone",
   bid_rate: "bid_rate",
   percent_complete: "percent_complete",
   wage_overrides: "wage_overrides",
   groups: "groups",
   tag_instances: "tag_instances",
   procore_id: "procore_id",
};
export const filterRendererMap: ColumnMap = {
   tag_instances: MultiSelectPillFilter,
   status: StatusFilter,
   bid_rate: NumericValueFilter,
   percent_complete: NumericValueFilter,
   start_date: DateFilter,
   est_end_date: DateFilter,
};

export const filterFieldMap: ColumnMap = {
   tag_instances: "tag_instances",
   status: "status",
   bid_rate: "bid_rate",
   percent_complete: "percent_complete",
   start_date: "start_date",
   est_end_date: "est_end_date",
};

export const fieldTranslationKeyMap: ColumnMap = {
   project_name: "views.company.workforce_planning.projects.name",
   color: "views.company.workforce_planning.projects.color",
   start_date: "views.company.workforce_planning.projects.start_date",
   est_end_date: "views.company.workforce_planning.projects.est_end_date",
   project_hash: "views.company.workforce_planning.projects.project_number",
   address_1: "views.company.workforce_planning.projects.address",
   address_2: "views.company.workforce_planning.projects.address_2",
   city_town: "views.company.workforce_planning.projects.city",
   state_province: "views.company.workforce_planning.projects.state",
   zipcode: "views.company.workforce_planning.projects.postal",
   tag_instances: "views.company.workforce_planning.projects.tags",
   project_type: "views.company.workforce_planning.projects.type",
   status: "views.company.workforce_planning.projects.status",
   groups: "views.company.workforce_planning.projects.groups",
   customer_name: "views.company.workforce_planning.projects.customer",
   created_by: "views.company.workforce_planning.projects.created_by",
   created_at: "views.company.workforce_planning.projects.created",
   country: "views.company.workforce_planning.projects.country",
   daily_start_time: "views.company.workforce_planning.projects.daily_start_time",
   daily_end_time: "views.company.workforce_planning.projects.daily_end_time",
   timezone: "views.company.workforce_planning.projects.timezone",
   bid_rate: "views.company.workforce_planning.projects.est_avg_rate",
   percent_complete: "views.company.workforce_planning.projects.percent_complete",
   wage_overrides: "views.company.workforce_planning.projects.wage_overrides",
   procore_id: "views.company.workforce_planning.projects.linked_to_procore",
};

export const legacyFieldNamesMap: ColumnMap = {
   project_name: "name",
   color: "color",
   project_hash: "job_number",
   address_1: "address_1",
   city_town: "city_town",
   state_province: "state_province",
   zipcode: "zip_code",
   tag_instances: "tag_instances",
   status: "status",
   procore_id: "has_procore_mapping",
   created_by: "creator_name",
   created_at: "created_at",
   address_2: "address_2",
   country: "country",
   daily_end_time: "daily_end_time",
   daily_start_time: "daily_start_time",
   timezone: "timezone",
   start_date: "start_date",
   est_end_date: "est_end_date",
   bid_rate: "bid_rate",
   customer_name: "customer_name",
   percent_complete: "percent_complete",
   project_type: "type",
   wage_overrides: "wage_overrides",
   groups: "group_ids",
};

export function generateProjectRoleColumnDefinitions(
   groupId: string,
   roles?: AppliedRoleOption[],
   config?: any,
): ColumnDefinition[] {
   if (!roles) return [];

   const columnStateMap = new Map(config?.columnState.map((column: any) => [column.field, column]));

   const columnDefinitions: ColumnDefinition[] = roles.map((r: AppliedRoleOption) => {
      // Check if there's existing configuration for this field
      const existingConfig: any = columnStateMap.get(`positions_${r.position_id}`);
      return {
         field: `positions_${r.position_id}`,
         headerName: r.position_name,
         cellRenderer: LinkCellRenderer,
         cellRendererParams: {
            getURL: (value: any) => {
               return value ? value.href : "";
            },
         },
         getStringFormattedValue: (value) => value.label,
         editable: false,
         filterRenderer: MultiSelectFilterRenderer,
         filterProps: {
            getFilterOptions: async () => {
               return await streamPeopleOptions(groupId);
            },
         },
         hidden: existingConfig ? existingConfig.hidden : true,
         sortable: false,
      };
   });
   return columnDefinitions;
}

import type { SerializedAttachment } from "@laborchart-modules/common/dist/rethink/serializers/attachment-serializer";
import type {
   ActivitiesResponseProps,
   ProjectAttachmentsData,
   ProjectDetailFormValues,
   ProjectRoleData,
   ProjectTagData,
   ProjectWageOverrideData,
} from "./types";
import Resizer from "react-image-file-resizer";
import { getAttachedDate } from "@laborchart-modules/common/dist/datetime";
import { DateUtils } from "@/lib/utils/date";
import { months } from "@/react/shared/constants";
import { v4 } from "uuid";
import type { ActivityCategory } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import type { ActivityEntityType } from "@laborchart-modules/common/dist/reql-builder/procedures/find-activity";
import type { DateTimeOptions } from "@procore/globalization-toolkit";
import { CurrencyFormatter, DateTimeFormatter } from "@procore/globalization-toolkit";
import type { CannedMessage } from "@laborchart-modules/common";
import { stripAndReturnHTMLTokens } from "../../communications/dynamic-tokens/token-utils";
import type { CannedMessageType } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import {
   renderAdd,
   renderChange,
   renderRemove,
} from "./project-detail-cards/project-activies-info-card";

export function compareDates({
   startDate,
   endDate,
}: {
   startDate: Date;
   endDate: ProjectDetailFormValues["est_end_date"] | undefined;
}) {
   if (!startDate) {
      return true;
   }
   const formattedStartDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
   );
   if (endDate) {
      const formattedEndDate = new Date(
         endDate.getFullYear(),
         endDate.getMonth(),
         endDate.getDate(),
      );
      if (formattedEndDate.getTime() === formattedStartDate.getTime()) {
         return true;
      }
      return formattedEndDate.getTime() > formattedStartDate.getTime();
   }
   return true;
}

export function getUniqueAttachmentIds(
   attachments: SerializedAttachment[],
   deletedAttachmentIds: string[],
) {
   // Get array of attachment IDs from attachments by user
   const addedAttachmentIds = attachments.map((attachment) => attachment.id);

   // addedAttachmentIds, and convert to Set for uniqueness to remove duplicates if any
   let uniqueAttachmentIds: any = new Set([...addedAttachmentIds]);

   // Remove IDs that are in deletedAttachmentIds array
   deletedAttachmentIds.forEach((id) => {
      uniqueAttachmentIds.delete(id);
   });

   // Convert Set back to array
   uniqueAttachmentIds = Array.from(uniqueAttachmentIds);

   return uniqueAttachmentIds;
}

export function prepareTagPayload(
   attachments: SerializedAttachment[],
   deletedAttachmentIds: string[],
   selectedTagId: string,
) {
   const payload: ProjectTagData = {
      tag_instances: {
         [selectedTagId]: {
            attachment_ids: getUniqueAttachmentIds(attachments, deletedAttachmentIds),
         },
      },
   };

   return payload;
}

export function prepareAttachmentsPayload(
   attachments: SerializedAttachment[],
   deletedAttachmentIds: string[],
) {
   const payload: ProjectAttachmentsData = {
      attachment_ids: getUniqueAttachmentIds(attachments, deletedAttachmentIds),
   };

   return payload;
}

export function prepareOverridePayload(selectedOverrideId: string, wage: number | null) {
   const payload: ProjectWageOverrideData = {
      wage_overrides: {
         [selectedOverrideId]: wage,
      },
   };
   return payload;
}

export function formatFullDateTime(timestamp: number): string {
   if (isNaN(timestamp) || timestamp < 0) {
      return "";
   }

   const date = new Date(timestamp);
   const month = months[date.getUTCMonth()];
   const day = date.getUTCDate();
   const year = date.getUTCFullYear();

   let hours = date.getUTCHours();
   const minutes = date.getUTCMinutes();
   const ampm = hours >= 12 ? "PM" : "AM";
   hours = hours % 12;
   hours = hours ? hours : 12;
   const strMinutes = minutes < 10 ? "0" + minutes : minutes;

   hours = (hours + 19) % 24;

   return `${month} ${day}, ${year} at ${hours}:${strMinutes} ${ampm} EST`;
}

export function getInitials(fullName: string): string {
   const words = fullName.split(" ");
   if (words.length < 2) {
      return "";
   }
   const initials = words.slice(0, 2).map((word) => word.charAt(0).toUpperCase());
   return initials.join("");
}

export const getFileDimensions = (file: string) => {
   return new Promise<{ width: number; height: number }>((resolve) => {
      const img = new Image();
      img.src = file;
      img.addEventListener("load", () => resolve({ width: img.width, height: img.height }));
   });
};

export const resizeFile = (
   file: File,
   maxSize: number,
   minSize?: number,
   outputType: string = "file",
) => {
   return new Promise((resolve) => {
      Resizer.imageFileResizer(
         file,
         maxSize,
         maxSize,
         "png",
         100,
         0,
         (uri) => {
            resolve(uri);
         },
         outputType,
         minSize ?? undefined,
         minSize ?? undefined,
      );
   });
};

export const readFile = (file: File) => {
   return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
   });
};

export const readFileFromUrl = async (name: string, url: string) => {
   const response = await fetch(url);
   const blob = await response.blob();
   return new File([blob], name ?? "file_name.png", {
      type: blob.type,
   });
};

function processDataForView(breakdown: any, selectedView: string): Array<[number, number]> {
   const assignments = breakdown.daily_ass_totals;
   const requests = breakdown.daily_request_totals;
   const data: Array<[number, number]> = [];

   const processEntry = (k: string, yVal: number) => {
      data.push([getAttachedDate(Number(k)).getTime(), yVal]);
   };

   if (selectedView === "both") {
      const keys =
         Object.keys(requests).length >= Object.keys(assignments).length ? requests : assignments;
      Object.keys(keys).forEach((k) => {
         const yVal = (requests[k]?.count || 0) + (assignments[k]?.count || 0);
         processEntry(k, yVal);
      });
   } else {
      const source = selectedView === "requests" ? requests : assignments;
      Object.keys(source).forEach((k) => processEntry(k, source[k].count));
   }

   return data;
}

export function processTotalsDataForGraph(
   totals: any,
   selectedView: "assignments" | "requests" | "both",
   I18n: {
      t: (t: string) => string;
   },
): Highcharts.SeriesAreaOptions[] {
   return totals.breakdown_data
      .map((breakdown: any) => ({
         name: breakdown.name ?? I18n.t("views.company.workforce_planning.none"),
         color: breakdown.color ?? "#ACB5B9",
         type: "area" as const,
         data: processDataForView(breakdown, selectedView),
      }))
      .filter((series: Highcharts.SeriesAreaOptions) => {
         if (series.data) {
            return series.data.length > 0;
         }
         return false;
      });
}

export const getDateString = (date: Date) => {
   return `${DateUtils.getMonth(date)} ${date.getDate()}, ${date.getFullYear()}`;
};

export function prepareRolesPayload(
   selectedRoleId: string,
   jobTitleValue: string,
   personIdValue: string,
   archived: boolean,
) {
   const payload: ProjectRoleData = {
      roles: [
         {
            id: selectedRoleId !== "" ? selectedRoleId : v4(),
            position_id: jobTitleValue,
            person_id: personIdValue,
            archived,
         },
      ],
   };
   return payload;
}

export const prepareActivitiesPayload = (
   entityId: string,
   entityType: string,
   includedCategories: string[] | string,
   limitActivities: number,
   nextStartingAfter?: string,
) => {
   const payload = {
      entity_id: entityId,
      entity_type: entityType as ActivityEntityType,
      included_categories: includedCategories as ActivityCategory[],
      limit: limitActivities,
      starting_after: nextStartingAfter,
   };
   return payload;
};

export const formatDate = (date?: number) => {
   if (!date) return "";
   const dateTimeOptions = {
      timeZone: "UTC",
      dateStyle: "short",
   };
   const dateFormatter = new DateTimeFormatter(dateTimeOptions as DateTimeOptions);
   const dateFull = getAttachedDate(date);
   return dateFormatter.formatDateTime(dateFull);
};

export const formatCurrency = (value?: number, I18n?: any): string => {
   if (!value) {
      return "";
   }
   const currencyOptions = { locale: I18n.currentLocale(), currencyIsoCode: "USD" };
   const currencyFormatter = new CurrencyFormatter(currencyOptions);
   return currencyFormatter.formatCurrency(value);
};

export function prepareCannedMessagePayload(
   alertContent: { subject: string; content: string },
   formValues: any,
   projectId: string,
   type: CannedMessageType,
): Partial<CannedMessage> {
   const { content: apiReadySubject, dynamicTokens: subjectTokens } = stripAndReturnHTMLTokens(
      alertContent.subject,
      projectId,
   );
   const { content: apiReadyContent, dynamicTokens: contentTokens } = stripAndReturnHTMLTokens(
      alertContent.content,
      projectId,
   );

   const dynamic_tokens = subjectTokens.concat(contentTokens);

   const payload = {
      content: apiReadyContent,
      subject: apiReadySubject,
      include_signature: false,
      is_group: formValues.send_as.value === "group",
      owner_id: projectId,
      type: type,
      dynamic_tokens: dynamic_tokens,
   };

   return payload;
}

export function getCustomAlertsInitialValues(cannedMessage: CannedMessage | null, I18n: any) {
   if (!cannedMessage || !cannedMessage.is_group) {
      return {
         send_as: {
            id: "individual",
            value: "individual",
            label: I18n.t("views.company.workforce_planning.communications.individual_messages"),
         },
      };
   } else {
      return {
         send_as: {
            id: "group",
            value: "group",
            label: I18n.t("views.company.workforce_planning.communications.group_messages"),
         },
      };
   }
}

export function getCustomAssignmentsTearsheetHeader(I18n: any, type: CannedMessageType): string {
   switch (type) {
      case "assignment-new":
         return I18n.t("views.company.workforce_planning.communications.customized_new_assignment");
      case "assignment-edit":
         return I18n.t(
            "views.company.workforce_planning.communications.customized_updated_assignment",
         );
      case "assignment-transfer":
         return I18n.t(
            "views.company.workforce_planning.communications.customized_assignment_transfer",
         );
      case "assignment-delete":
         return I18n.t(
            "views.company.workforce_planning.communications.customized_deleted_assignment",
         );
      default:
         return I18n.t("views.company.workforce_planning.communications.assignment_alert");
   }
}

const translationsLabelsActivityCard = [
   {
      name: "name",
      translation: "views.company.workforce_planning.time_off.name",
   },
   {
      name: "color",
      translation: "views.company.workforce_planning.projects.color",
   },
   {
      name: "status",
      translation: "views.company.workforce_planning.projects.status",
   },
   {
      name: "timezone",
      translation: "views.company.workforce_planning.projects.timezone",
   },
   {
      name: "address_1",
      translation: "views.company.workforce_planning.projects.address",
   },
   {
      name: "address_2",
      translation: "views.company.workforce_planning.projects.address_2",
   },
   {
      name: "city_town",
      translation: "views.company.workforce_planning.projects.city",
   },
   {
      name: "state_province",
      translation: "views.company.workforce_planning.projects.state",
   },
   {
      name: "zipcode",
      translation: "views.company.workforce_planning.projects.postal",
   },
   {
      name: "country",
      translation: "views.company.workforce_planning.projects.country",
   },
   {
      name: "profile_pic_url",
      translation: "views.company.workforce_planning.projects.profile_pic_url",
   },
   {
      name: "job_number",
      translation: "views.company.workforce_planning.projects.job_number",
   },
   {
      name: "project_type",
      translation: "views.company.workforce_planning.projects.project_type",
   },
   {
      name: "start_date",
      translation: "views.company.workforce_planning.start_date",
   },
   {
      name: "end_date",
      translation: "views.company.workforce_planning.end_date",
   },
   {
      name: "daily_start_time",
      translation: "views.company.workforce_planning.time_off.modals.labels.daily_start_time",
   },
   {
      name: "daily_end_time",
      translation: "views.company.workforce_planning.time_off.modals.labels.daily_end_time",
   },
   {
      name: "bid_rate",
      translation: "views.company.workforce_planning.projects.est_avg_rate",
   },
   {
      name: "percent_complete",
      translation: "views.company.workforce_planning.projects.percent_complete",
   },
   {
      name: "customer_name",
      translation: "views.company.workforce_planning.projects.customer",
   },
   {
      name: "group_ids",
      translation: "views.company.workforce_planning.projects.groups",
   },
   {
      name: "custom_fields",
      translation: "views.company.workforce_planning.projects.custom_fields",
   },
];

export const getTranslationLabel = (label: string, I18n: any): string => {
   const translationItem = translationsLabelsActivityCard.find((item) => item.name === label);
   return translationItem ? I18n.t(translationItem.translation) : label;
};

export const customFieldsCard = (data: ActivitiesResponseProps) => {
   const { custom_field_name, custom_field_type, new_val, old_val } = data.meta || {};

   if (!data.meta) return null;

   switch (custom_field_type) {
      case "text":
      case "number":
      case "select":
      case "multi_select":
      case "hex-color":
         if (new_val !== null && old_val !== null)
            return renderChange(custom_field_name as string, old_val, new_val);
         if (new_val === null && old_val !== undefined)
            return renderRemove(custom_field_name as string, old_val);
         if (old_val === null && new_val !== undefined)
            return renderAdd(custom_field_name as string, new_val);
         break;
      case "date":
         if (new_val !== null && old_val !== null)
            return renderChange(
               custom_field_name as string,
               formatDate(old_val as number),
               formatDate(new_val as number),
            );
         if (new_val === null && old_val !== null)
            return renderRemove(custom_field_name as string, formatDate(old_val as number));
         if (old_val === null && new_val !== null)
            return renderAdd(custom_field_name as string, formatDate(new_val as number));
         break;
      case "bool": {
         const yesValue = I18n.t("views.company.workforce_planning.boolean_values.yes_value");
         const noValue = I18n.t("views.company.workforce_planning.boolean_values.no_value");
         if (new_val !== null && old_val !== null) {
            return renderChange(
               custom_field_name as string,
               old_val ? yesValue : noValue,
               new_val ? yesValue : noValue,
            );
         }
         if (new_val === null && old_val !== null) {
            return renderRemove(custom_field_name as string, old_val ? yesValue : noValue);
         }
         if (old_val === null && new_val !== null) {
            return renderAdd(custom_field_name as string, new_val ? yesValue : noValue);
         }
         break;
      }
      case "currency":
         if (new_val !== null && old_val !== null)
            return renderChange(
               custom_field_name as string,
               formatCurrency(old_val as number, I18n),
               formatCurrency(new_val as number, I18n),
            );
         if (new_val === null && old_val !== null)
            return renderRemove(
               custom_field_name as string,
               formatCurrency(old_val as number, I18n),
            );
         if (old_val === null && new_val !== null)
            return renderAdd(custom_field_name as string, formatCurrency(new_val as number, I18n));
         break;
      default:
         return null;
   }
};

import { PLACEHOLDER_KEYS } from "@/react/components/tearsheets/constants";
import { DropDownType } from "@/react/components/tearsheets/types";
import React, { useState, useCallback } from "react";
import { formatOptions } from "../helper";
import type { FormattedOption } from "@/react/prop-types";
import { CurrentAssignmentsBanner } from "@/react/components/tearsheets/assignment/current-assignments-banner";
import type { BannerVariant } from "@procore/core-react/dist/Banner/Banner.types";

export const useDropDownSelectionHandler = (assignmentCreationSupportData: any) => {
   const [categoryOptions, setCategoryOptions] = React.useState<FormattedOption[]>([]);
   const [subCategoryOptions, setSubCategoryOptions] = React.useState<FormattedOption[]>([]);
   const [categoryPlaceHolder, setCategoryPlaceHolder] = React.useState<string>(
      PLACEHOLDER_KEYS.category.project_selected_first,
   );
   const [subCategoryPlaceHolder, setSubCategoryPlaceHolder] = React.useState<string>(
      PLACEHOLDER_KEYS.subCategory.category_selected_first,
   );
   const [currentAssignments, setCurrentAssignments] = useState<React.ReactNode>(null);

   const resetSubCategory = useCallback(() => {
      setSubCategoryOptions([]);
      setSubCategoryPlaceHolder(PLACEHOLDER_KEYS.subCategory.category_selected_first);
   }, []);

   const handleSelection = useCallback(
      (option: FormattedOption, type: string) => {
         const { id, label } = option;
         const { data } = assignmentCreationSupportData ?? {};
         if (!data) return;

         switch (type) {
            case DropDownType.PROJECT: {
               setCategoryOptions([]);
               resetSubCategory();
               if (data?.grouped_cost_code_options?.[id]) {
                  const categoryOptions = formatOptions(data.grouped_cost_code_options[id]);
                  setCategoryOptions(categoryOptions);
                  setCategoryPlaceHolder(
                     categoryOptions.length
                        ? PLACEHOLDER_KEYS.category.select_category
                        : PLACEHOLDER_KEYS.category.project_no_options,
                  );
               } else {
                  setCategoryPlaceHolder(PLACEHOLDER_KEYS.category.project_no_options);
               }
               break;
            }

            case DropDownType.CATEGORY: {
               setSubCategoryOptions([]);
               if (data?.grouped_label_options?.[id]) {
                  const subCategoryOptions = formatOptions(data.grouped_label_options[id]);
                  setSubCategoryOptions(subCategoryOptions);
                  setSubCategoryPlaceHolder(
                     subCategoryOptions.length
                        ? PLACEHOLDER_KEYS.subCategory.select_subcategory
                        : PLACEHOLDER_KEYS.subCategory.category_no_options,
                  );
               } else {
                  setSubCategoryPlaceHolder(PLACEHOLDER_KEYS.subCategory.category_no_options);
               }
               break;
            }

            case DropDownType.RESOURCE: {
               const bannerProps = {
                  variant: "info" as BannerVariant,
                  personId: id,
                  icon: "info",
                  personName: label,
               };
               const currentAssignmentsBanner = <CurrentAssignmentsBanner {...bannerProps} />;
               setCurrentAssignments(currentAssignmentsBanner);
               break;
            }

            default:
               break;
         }
      },
      [assignmentCreationSupportData, resetSubCategory],
   );

   return {
      categoryOptions,
      subCategoryOptions,
      categoryPlaceHolder,
      subCategoryPlaceHolder,
      currentAssignments,
      handleSelection,
      resetSubCategory,
   };
};

import { DEFAULT_START_TIME, DEFAULT_END_TIME, workDays } from "@/react/shared/constants";
import { timeOptions } from "@/lib/utils/timezones";

export const PLACEHOLDER_KEYS = {
   category: {
      project_no_options:
         "views.company.workforce_planning.category_placeholder.project_no_options",
      project_selected_first:
         "views.company.workforce_planning.category_placeholder.project_selected_first",
      select_category: "views.company.workforce_planning.category_placeholder.select_category",
   },
   subCategory: {
      category_no_options:
         "views.company.workforce_planning.subcategory_placeholder.category_no_options",
      category_selected_first:
         "views.company.workforce_planning.subcategory_placeholder.category_selected_first",
      select_subcategory:
         "views.company.workforce_planning.subcategory_placeholder.select_subcategory",
   },
};

// Common Initial Values
export const commonInitialValuesForRequestAndAssignment = {
   project: null,
   status: null,
   category: null,
   subcategory: null,
   start_date: new Date(),
   end_date: null,
   start_time: {
      id: DEFAULT_START_TIME,
      label: timeOptions.find(({ id }) => id === DEFAULT_START_TIME)!.label,
   },
   end_time: {
      id: DEFAULT_END_TIME,
      label: timeOptions.find(({ id }) => id === DEFAULT_END_TIME)!.label,
   },
   assignment_by_time: true,
   assignment_by_allocation: false,
   work_days: workDays,
   percent_allocated: 100,
};

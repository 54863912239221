import { DateUtils } from "@/lib/utils/date";
import { defaultStore } from "@/stores/default-store";
import { getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import { AlertContext } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import type { SendOneOffProjectAlertsPayload } from "@laborchart-modules/lc-core-api/dist/api/alerts/send-one-off-project-alerts";
import type { SerializedFindAssignment } from "@laborchart-modules/lc-core-api/dist/api/assignments";
import { stripAndReturnHTMLTokens } from "../../communications/dynamic-tokens/token-utils";
import type { SerializedFindTimeOff } from "@laborchart-modules/lc-core-api/dist/api/time-off/find-time-off";
import type { AssignmentFormattedTypes } from "./types";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import { dateSortOptions } from "@/react/shared/constants";

export function getCurrentAssignmentsFormatted(
   currentAssignments: SerializedFindAssignment[] | undefined,
   scheduledTimeOff: SerializedFindTimeOff[] | null,
): AssignmentFormattedTypes[] | undefined {
   const formattedData = currentAssignments?.map((assignmentDetails) => ({
      assignmentId: assignmentDetails.id,
      formattedAssignmentDetails: `${assignmentDetails.project.name}, ${DateUtils.formatDetachedDay(
         assignmentDetails.start_day,
         defaultStore.getDateFormat(),
      )} - ${DateUtils.formatDetachedDay(assignmentDetails.end_day, defaultStore.getDateFormat())}`,
      start_day: assignmentDetails.start_day,
   }));
   scheduledTimeOff?.forEach((timeOffDetails) => {
      formattedData?.push({
         assignmentId: timeOffDetails.id,
         formattedAssignmentDetails: `${timeOffDetails.reason}, ${DateUtils.formatDetachedDay(
            timeOffDetails.start_day,
            defaultStore.getDateFormat(),
         )} - ${DateUtils.formatDetachedDay(timeOffDetails.end_day, defaultStore.getDateFormat())}`,
         start_day: timeOffDetails.start_day,
      });
   });
   formattedData?.sort((a, b) => {
      const dateA = new Date(a.start_day).getTime();
      const dateB = new Date(b.start_day).getTime();
      return dateSortOptions[1].value === Order.ASCENDING ? dateA - dateB : dateB - dateA;
   });

   return formattedData;
}

export function formatOneOffAlertsPayload(
   alertContent: any,
   formValues: any,
   shouldScheduleDelivery: boolean,
   projectId: string,
   recipientsToIgnore?: string[],
): SendOneOffProjectAlertsPayload {
   const { content: apiReadySubject, dynamicTokens: subjectTokens } = stripAndReturnHTMLTokens(
      alertContent.subject,
      projectId,
   );
   const { content: apiReadyContent, dynamicTokens: contentTokens } = stripAndReturnHTMLTokens(
      alertContent.content,
      projectId,
   );

   const dynamicTokens = subjectTokens.concat(contentTokens);
   const template = {
      content: apiReadyContent,
      subject: apiReadySubject,
      dynamic_tokens: dynamicTokens,
      is_group: false,
      owner_id: projectId,
      type: "assignment-new",
      include_signature: false,
   };

   const payload: SendOneOffProjectAlertsPayload = {
      assignments_start_day: getDetachedDay(formValues.start_date),
      assignments_end_day: getDetachedDay(formValues.end_date),
      context: AlertContext.OPEN,
      people_ids_to_ignore: recipientsToIgnore ?? [],
      project_id: projectId,
      template: template,
   };

   if (shouldScheduleDelivery) {
      payload.context = AlertContext.SCHEDULED;
      payload.scheduled_day = getDetachedDay(formValues.scheduled_day);
      payload.scheduled_time =
         formValues.day_night.value === "pm"
            ? formValues.hour.value + formValues.minute.value + 12
            : formValues.hour.value + formValues.minute.value;
   }

   return payload;
}

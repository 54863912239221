import type { Filter } from "@/lib/components/chip-filter/chip-filter";
import { authManager } from "@/lib/managers/auth-manager";
import { useGroupContext } from "@/react/providers/group-context-provider";
import { AuthAction, usePermissionContext } from "@/react/providers/permission-context-provider";
import { Assignment2Store as AssignmentStore } from "@/stores/assignment-2-store.core";
import { defaultStore } from "@/stores/default-store";
import { SettingsStore } from "@/stores/settings-store.core";
import type {
   CalendarModel,
   Gantt,
   MenuItemConfig,
   Panel,
   Store,
   TaskModelConfig,
   ViewPreset,
} from "@bryntum/gantt";
import {
   DateHelper,
   PresetManager,
   ProjectModel,
   StringHelper,
   Model,
   TaskModel,
} from "@bryntum/gantt";
import type { BryntumGanttProps } from "@bryntum/gantt-react";
import { BryntumGantt } from "@bryntum/gantt-react";
import "@bryntum/gantt/gantt.stockholm.css";
import { getAttachedDate, getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import { Ban, CaretsInVertical, CaretsOutVertical, EllipsisVertical } from "@procore/core-icons";
import { Box, DetailPage, Link, P, Spinner, useI18nContext } from "@procore/core-react";
import { timeFormat, timeParse } from "d3-time-format";
import React, {
   useCallback,
   useEffect,
   useImperativeHandle,
   useMemo,
   useRef,
   useState,
} from "react";
import { renderToString } from "react-dom/server";
import {
   ProjectTearsheetProvider,
   useProjectTearsheet,
} from "../tearsheets/project/project-tearsheet";
import { getCalendarName, getCalendarsGanttData } from "./gantt-calendar";
import {
   getGanttConfigurePanelValues,
   updateGanttConfigurePanelLocalStorage,
} from "./gantt-config-panel";
import "./gantt-container.css";
import { GanttModalType, showGanttModal } from "./gantt-modals";
import { GanttControlPanel } from "./gantt-control-panel";
import { GanttFilterPanel, statuses } from "./gantt-filter-panel";
import type {
   Category,
   GanttOptions,
   GanttProject,
   GroupableTask,
   GroupedTasks,
   RawGanttData,
   Task,
   TaskLookup,
   ganttFilterType,
   noSubcategoryTasks,
} from "./prop-types";
import { TaskType } from "./prop-types";

export const GanttContainer = () => {
   return (
      <ProjectTearsheetProvider projectsTableApi={undefined}>
         <Container />
      </ProjectTearsheetProvider>
   );
};

export const INITIAL_GANTT_FILTER: ganttFilterType = {
   jobTitles: [],
   projectStatuses: [],
   onlyShow: [],
   hideEmptyProject: false,
};

const sortPriorityLevels = {
   [TaskType.PROJECT]: 0,
   [TaskType.ASSIGNMENT]: 1,
   [TaskType.REQUEST]: 2,
   [TaskType.CATEGORY]: 3,
   [TaskType.SUBCATEGORY]: 4,
};

/**
 * Represents a reference to a Gantt project tearsheet.
 */
export type GantProjectTearsheetRef = {
   /**
    * Opens the tearsheet for the specified project.
    *
    * @param projectId - The ID of the project to open the tearsheet for.
    */
   handleOpenTearsheet: (projectId: string) => void;
};

/**
 * This component is a wrapper around the ProjectTearsheetProvider that allows us
 * render the ProjectTearsheetProvider to prevent re-rendering the entire GanttContainer
 * handling internally the state of the provider
 */
export const GantProjectTearsheet = React.forwardRef<GantProjectTearsheetRef>((_, ref) => {
   const { dispatch: projectTearsheetDispatch } = useProjectTearsheet();

   const handleOpenTearsheet = useCallback(
      (projectId: string) => {
         projectTearsheetDispatch({ type: "open-project-detail", projectId });
      },
      [projectTearsheetDispatch],
   );

   useImperativeHandle(ref, () => ({
      handleOpenTearsheet,
   }));

   return null;
});

const Container = () => {
   const isTimeoffReasonAvailable = !!authManager
      .authedUser()
      ?.permissionLevel()
      ?.viewPeopleTimeoff();
   const groupId = localStorage.getItem("selectedGroupId") ?? useGroupContext().groupId;
   const I18n = useI18nContext();
   const ganttFilterSaved: ganttFilterType | null = JSON.parse(
      localStorage.getItem("gantt-filter")!,
   );
   const { checkAuthAction } = usePermissionContext();

   const [ganttData, setGanttData] = useState<RawGanttData>();
   const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

   /* For more information on what is inside of the PresetManager, you can view the file `node_modules/@bryntum/gantt/gantt.module.js`
      and search for line "var PresetManager = class extends PresetStore"

      You can also check the Bryntum docs here for examples on how to use or alter this PresetManager or create custom ViewPreset's:
         - https://bryntum.com/products/gantt/docs/api/Scheduler/preset/PresetManager
         - https://bryntum.com/products/gantt/docs/api/Scheduler/preset/ViewPreset
   */
   const availablePresets = (PresetManager.records.slice(3, 10) as ViewPreset[]).map(
      (preset: ViewPreset) => {
         return {
            id: "my_" + preset.id,
            base: preset.id,
            // mainUnit and defaultSpan are used to dynamically set the viewing range of our gantt
            mainUnit: preset.mainUnit,
            defaultSpan: preset.defaultSpan,
            // timeResolution makes drag-and-drop snaps to your unit
            timeResolution: {
               unit: "day",
               increment: 1,
            },
         };
      },
   ) as ViewPreset[];

   const viewPreset =
      availablePresets.find(
         (preset: any) => preset.base === localStorage.getItem("gantt-view-preset"),
      ) ?? availablePresets.at(-1);

   const mainUnitInMs = (() => {
      const ONE_DAY = 1000 * 60 * 60 * 24;
      switch (viewPreset?.mainUnit) {
         case "day":
            return ONE_DAY;
         case "week":
            return ONE_DAY * 7;
         case "month":
            return ONE_DAY * 30;
         case "year":
            return ONE_DAY * 365;
         default:
            return ONE_DAY * 365;
      }
   })();

   const mainUnitOffset = mainUnitInMs * (viewPreset?.defaultSpan ?? 1);

   const START_DATE = new Date();
   START_DATE.setTime(START_DATE.getTime() - mainUnitOffset * 4);
   const END_DATE = new Date();
   END_DATE.setTime(END_DATE.getTime() + mainUnitOffset * 4);

   const [currentGanttRange, setCurrentGanttRange] = useState({
      startDay: getDetachedDay(START_DATE),
      endDay: getDetachedDay(END_DATE),
   });
   const earliestStartDateViewed = useRef<number>(currentGanttRange.startDay);
   const latestEndDateViewed = useRef<number>(currentGanttRange.endDay);

   const [search, setSearch] = useState<string>(localStorage.getItem("gantt-search") ?? "");
   const prevSearch = useRef<string>(search);
   const [paidShiftHours, setPaidShiftHours] = useState(8); // Paid shift is 8 hours by default
   const fetchTimeoutId = useRef<number>(NaN);
   const [ganttFilter, setGanttFilter] = useState<ganttFilterType>(
      ganttFilterSaved ?? { ...INITIAL_GANTT_FILTER, projectStatuses: [statuses[0]] }, // Select active projects by default
   );
   const [canViewProject, setCanViewProject] = useState(false);
   const ganttTearsheetRef = useRef<GantProjectTearsheetRef>(null);

   useEffect(() => {
      const canViewProject = checkAuthAction(AuthAction.VIEW_PROJECT);
      setCanViewProject(canViewProject);
   }, [checkAuthAction]);

   useEffect(() => {
      localStorage.setItem("gantt-filter", JSON.stringify(ganttFilter));
      fetchData();
   }, [ganttFilter]);

   // const getDateFilterFormatted = (date: string): string => {
   //    const dateObj = new Date(date);
   //    const monthStr = `${dateObj.getMonth() < 10 ? "0" : ""}${dateObj.getMonth()}`;
   //    const dayStr = `${dateObj.getDate() < 10 ? "0" : ""}${dateObj.getDate()}`;
   //    const yearStr = `${dateObj.getFullYear()}`;

   //    return `${yearStr}${monthStr}${dayStr}`;
   // };

   const getFilterFormatted = (filter: ganttFilterType): Record<string, Filter[]> => {
      const appliedFilters: Record<string, Filter[]> = {};

      if (filter.jobTitles?.length)
         appliedFilters["Job Titles"] = filter.jobTitles.map((jobTitle) => {
            return {
               property: "position_id",
               negation: false,
               filterName: "Job Titles",
               value: jobTitle.id,
            } as Filter;
         });

      if (filter.projectStatuses?.length)
         appliedFilters["Status"] = filter.projectStatuses.map((status) => {
            return {
               property: "status",
               negation: false,
               filterName: "Status",
               value: status.name,
            } as Filter;
         });

      if (filter.onlyShow?.length)
         appliedFilters["Only Show"] = filter.onlyShow.map((value) => {
            return {
               property: "only_show",
               negation: false,
               filterName: "Only Show",
               value,
            } as Filter;
         });

      if (filter.hideEmptyProject)
         appliedFilters["Hide Empty Projects"] = [
            {
               property: "only_projects_with_data",
               negation: false,
               filterName: "Hide Empty Projects",
               value: true,
            } as Filter,
         ];

      // if (filter.startDate?.qualifier && filter.startDate?.date.length)
      //    appliedFilters["Start Date"] = [
      //       {
      //          property: "start_date",
      //          negation: false,
      //          filterName: "Start Date",
      //          value: getDateFilterFormatted(filter.startDate.date),
      //          classifier: filter.startDate.qualifier,
      //       } as Filter,
      //    ];

      // if (filter.endDate?.qualifier && filter.endDate?.date.length)
      //    appliedFilters["Est End Date"] = [
      //       {
      //          property: "est_end_date",
      //          negation: false,
      //          filterName: "Est End Date",
      //          value: getDateFilterFormatted(filter.endDate.date),
      //          classifier: filter.endDate.qualifier,
      //       } as Filter,
      //    ];

      return appliedFilters;
   };

   const fetchData = async () => {
      ganttRef.current?.instance.mask({
         mode: "bright",
         cls: "gantt-loading-mask",
         html: (
            <div style={{ height: "100%", width: "100%", display: "grid", placeItems: "center" }}>
               <Spinner loading={true} />
            </div>
         ),
      });

      const startDay = earliestStartDateViewed.current;
      const endDay = latestEndDateViewed.current;

      const params: GanttOptions = {
         skip: 0,
         projectSort: "name",
         group_id: groupId,
         startDay,
         endDay,
         search: search,
      };

      const appliedFilters = getFilterFormatted(ganttFilter);
      params.filters = appliedFilters;

      const rawGanttData = await AssignmentStore.getRawProjectsGanttData(params);

      ganttRef.current?.instance.unmask();
      setGanttData(rawGanttData);
      setIsInitialLoad(false);
   };

   useEffect(() => {
      const fetchCostingData = async () => {
         try {
            const costingInfo = await SettingsStore.getCostingInfo()?.payload;

            setPaidShiftHours(costingInfo?.data?.paid_shift_hours ?? 8);
         } catch (e) {
            console.error(e);
         }
      };

      fetchCostingData();
   }, []);

   useEffect(() => {
      // Making sure we don't call fetchData on the for this effect on initial page load. We only want to execute this
      // after the user themselves updates the input value.
      if (search != prevSearch.current) {
         prevSearch.current = search;
         localStorage.setItem("gantt-search", search ?? "");
         fetchData();
      }
   }, [search]);

   useEffect(() => {
      // clearing interval if this event fires again before 500ms to achieve a debounce effect
      clearTimeout(fetchTimeoutId.current);
      const currentStartDate = currentGanttRange.startDay;
      const currentEndDate = currentGanttRange.endDay;

      const hasViewRangeExtended = () => {
         // If we've already zoomed out past the starting or ending point of the current range then there's no need to fetch
         // more data because we've already loaded a larger set of data which includes the current range.
         let rangeViewExtended = false;

         if (currentStartDate < earliestStartDateViewed.current) {
            rangeViewExtended = true;
            earliestStartDateViewed.current = currentStartDate;
         }
         if (currentEndDate > latestEndDateViewed.current) {
            rangeViewExtended = true;
            latestEndDateViewed.current = currentEndDate;
         }

         return rangeViewExtended;
      };

      // Wait until we go 500ms without receiving another zoom event to check if we need to fetch more data (debounced for performance)
      fetchTimeoutId.current = window.setTimeout(() => {
         if (hasViewRangeExtended()) {
            fetchData();
         }
      }, 500);
   }, [currentGanttRange]);

   const defaultDateFormat = defaultStore.getDateFormat();
   const getDatesRangeString = useCallback(
      (startDate: Date, endDate: Date, startTime?: string, endTime?: string) => {
         return `${DateHelper.format(startDate, defaultDateFormat)} ${
            startTime ?? ""
         } - ${DateHelper.format(endDate, defaultDateFormat)} ${endTime ?? ""}`;
      },
      [defaultDateFormat],
   );
   // Function to parse "HH:MM:SS" strings into Date objects
   const parseTimeString = useCallback((timeString: string): Date => {
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      const date = new Date();
      date.setHours(hours, minutes, seconds, 0);
      return date;
   }, []);
   // Function that returns the percentage allocation of the assignment/request
   const getPercentAllocation = useCallback(
      (projectJobNumber: string, id: string): number | null => {
         const project = ganttData?.projects.find(
            (project) => project.job_number === projectJobNumber,
         );

         const task =
            project?.assignments.find((assignment) => assignment.id === id) ??
            project?.requests.find((request) => request.id === id);

         return task?.percent_allocated ?? null;
      },
      [ganttData],
   );
   const getTaskTimeAllocation = useCallback(
      (originalData: any): string => {
         const { startTime, endTime, projectJobNumber, id } = originalData;

         if (!startTime || !endTime) {
            return `${getPercentAllocation(projectJobNumber, id)}`;
         } else {
            const startTime = formatTime(parseTimeString(originalData.startTime));
            const endTime = formatTime(parseTimeString(originalData.endTime));

            return `${startTime} - ${endTime}`;
         }
      },
      [ganttData],
   );
   // Function that returns custom tooltip template for tasks
   const tooltipTemplateCallback = useCallback(
      (taskRecord: any, startDate: Date, endDate: Date): string => {
         const originalData = taskRecord.originalData;
         const { startTime, endTime } = originalData;

         let isAssignmentOrRequest = false,
            taskTimeAllocation = "";

         if (originalData.type === TaskType.ASSIGNMENT || originalData.type === TaskType.REQUEST) {
            isAssignmentOrRequest = true;
            taskTimeAllocation = getTaskTimeAllocation(originalData);
         }

         return `<div>
         <div style="text-align: center">${StringHelper.encodeHtml(
            getDatesRangeString(startDate, endDate),
         )}</div>
         ${
            isAssignmentOrRequest
               ? `<div style="text-align: center">
               ${StringHelper.encodeHtml(
                  `${startTime && endTime ? taskTimeAllocation : `${taskTimeAllocation}%`}`,
               )}
            </div>`
               : ""
         }
         </div>`;
      },
      [ganttData],
   );
   /**
    * This function returns a pill displaying the number of open requests that appear on the project's bar
    */
   const requestsNumberPill = useCallback((requestsNumber: number): string => {
      if (!requestsNumber) return "";

      const pillsHeader = `${requestsNumber} open request${requestsNumber > 1 ? "s" : ""}`;

      return `<div class='ganttProjectRequestsPill'>${pillsHeader}</div>`;
   }, []);

   const dateFormat = timeFormat("%m/%d/%Y");
   const formatTime = timeFormat("%-I:%M %p"); // formats the time to "h:mm am/pm" (eg. 1:30 pm)
   let lastSortEventTarget: null | HTMLElement = null;

   const hideWeekends = (gantt: Gantt, value: boolean) => {
      const { timeAxis } = gantt;

      gantt.element.classList.toggle("b-hide-weekends", value);

      gantt.runWithTransition(() => {
         if (value) {
            timeAxis.filterBy(
               (tick: any) =>
                  timeAxis.unit !== "day" ||
                  (tick.startDate.getDay() !== 6 && tick.startDate.getDay() !== 0),
            );
         } else {
            timeAxis.clearFilters();
         }
      });
   };

   let droppingGridRow = false;
   const ganttConfig: BryntumGanttProps = useMemo(() => {
      return {
         startDate: getAttachedDate(earliestStartDateViewed.current),
         endDate: getAttachedDate(latestEndDateViewed.current),
         visibleDate: new Date(),
         presets: availablePresets,
         viewPreset: viewPreset,
         autoAdjustTimeAxis: false,
         rowHeight: getGanttConfigurePanelValues().rowHeight,
         // infiniteScroll: true,
         //#region Columns
         columns: [
            {
               alwaysClearCell: false, // v6 defaults this to true, so we're explicitly setting back to false to maintain prior behavior for now
               type: "name",
               text: "Project Name",
               field: "name",
               width: 250,
               htmlEncode: false,
               // This headerRenderer function expects you to return a string, so we're using the renderToString method to generate
               // static HTML string of our React components. This static HTML does not include any JavaScript or interactivity,
               // such as event handlers like onClick, so we're using a setTimeout to defer the execution of the querySelectors
               // until after the current callstack is clear (ie. until after the header finishes rendering). This async defermnet
               // ensures that the DOM elements are ready and available when we attempt to get them with querySelector.
               headerRenderer: ({ headerElement, column }) => {
                  // This updates the lastSortEventTarget value BEFORE the onBeforeSort listener executes.
                  headerElement.addEventListener("click", (e) => {
                     lastSortEventTarget = e.target as HTMLElement;
                  });

                  // These go in the setTimeout to make sure that the children of the headerElement have time to render first.
                  setTimeout(() => {
                     const expandAllIcon = headerElement.querySelector(
                        ".gantt-expand-all-icon",
                     ) as HTMLElement;
                     const collapseAllIcon = headerElement.querySelector(
                        ".gantt-collapse-all-icon",
                     ) as HTMLElement;

                     expandAllIcon?.addEventListener("click", () => {
                        expandAllIcon.style.display = "none";
                        collapseAllIcon.style.display = "block";
                        column.grid.collapseAll();
                        localStorage.setItem("gantt-expanded", "false");
                     });
                     collapseAllIcon?.addEventListener("click", () => {
                        expandAllIcon.style.display = "block";
                        collapseAllIcon.style.display = "none";
                        column.grid.expandAll();
                        localStorage.setItem("gantt-expanded", "true");
                     });
                  }, 0);

                  const isExpanded = localStorage.getItem("gantt-expanded") === "true";

                  return `
                     ${renderToString(
                        <CaretsOutVertical
                           className="gantt-expand-all-icon"
                           style={{ display: isExpanded ? "block" : "none" }}
                        />,
                     )}
                     ${renderToString(
                        <CaretsInVertical
                           className="gantt-collapse-all-icon"
                           style={{ display: isExpanded ? "none" : "block" }}
                        />,
                     )}
                     <span>${column.text}</span>
                  `;
               },
               renderer: ({ record, cellElement }) => {
                  const taskType = record.getData("type");
                  const emptyClassName = "no-children";

                  if (
                     ["project", "category", "subcategory"].includes(taskType) &&
                     ((record as any).children == undefined ||
                        (record as any).children.length === 0)
                  ) {
                     cellElement.classList.add(emptyClassName);
                  }

                  const onEllipsisClick = (event: any) => {
                     cellElement.dispatchEvent(
                        new MouseEvent("contextmenu", {
                           bubbles: true,
                           cancelable: true,
                           view: window,
                           clientX: event.clientX, // Pass the same mouse position
                           clientY: event.clientY,
                        }),
                     );
                  };

                  cellElement.setAttribute("data-task-type", taskType);

                  if (["category", "subcategory"].includes(taskType)) {
                     cellElement.classList.add("gantt-grid-row-ellipsis__container");
                     return (
                        <div
                           style={{
                              width: "100%",
                              display: "grid",
                              gridTemplateColumns: "1fr auto",
                              justifyContent: "space-between",
                           }}
                        >
                           <span
                              style={{
                                 overflow: "hidden",
                                 textOverflow: "ellipsis",
                              }}
                           >
                              {record.getData("name")}
                           </span>
                           <EllipsisVertical
                              size="sm"
                              className="gantt-grid-row-ellipsis__resource"
                              onClick={onEllipsisClick}
                           ></EllipsisVertical>
                        </div>
                     );
                  } else if (["assignment", "request"].includes(taskType)) {
                     return record.getData("name");
                  }

                  return (
                     <div
                        style={{
                           width: "100%",
                           display: "grid",
                           gridTemplateColumns: "1fr auto",
                           justifyContent: "space-between",
                        }}
                     >
                        <div
                           style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                           }}
                        >
                           <Link
                              onClick={() => {
                                 const projectId = String(record.id);
                                 if (canViewProject)
                                    ganttTearsheetRef.current?.handleOpenTearsheet(projectId);
                              }}
                              className="gantt-project-name"
                              data-testid="gantt-project-name"
                              aria-disabled={!canViewProject}
                           >
                              {record.getData("name")}
                           </Link>
                        </div>
                        <EllipsisVertical
                           size="sm"
                           className="gantt-grid-row-ellipsis__project"
                           onClick={onEllipsisClick}
                        ></EllipsisVertical>
                     </div>
                  );
               },
            },
            {
               alwaysClearCell: false, // v6 defaults this to true, so we're explicitly setting back to false to maintain prior behavior for now
               type: "name",
               text: "Job Title",
               field: "jobTitleName",
               headerRenderer: ({ headerElement, column }) => {
                  // This updates the lastSortEventTarget value BEFORE the beforeSort listener executes.
                  headerElement.addEventListener("click", (e) => {
                     lastSortEventTarget = e.target as HTMLElement;
                  });

                  return `<span>${column.text}</span>`;
               },
            },
         ],
         //#endregion
         barMargin: 4,
         height: "calc(100vh - 250px)",
         //#region Features
         strips: {
            right: {
               type: "panel",
               dock: "right",
               header: false,
               collapsible: true,
               ui: "procore",
               cls: "b-sidebar",
               scrollable: { overflowY: true },
               collapsed: true,
               defaults: {
                  labelPosition: "above",
                  width: "15em",
               },
               items: [
                  {
                     tag: "span",
                     html: "Configure Gantt",
                  },
                  {
                     type: "slidetoggle",
                     label: "Project Information",
                     labelPosition: "after", // we have some custom css to support the 'after' styling
                     value: getGanttConfigurePanelValues().showProjectInformation,
                     onChange: ({ value }) => {
                        updateGanttConfigurePanelLocalStorage("showProjectInformation", value);
                        ganttRef!.current!.instance.element.classList.toggle(
                           "b-hide-project-information",
                           !value,
                        );
                     },
                     id: "project-information",
                  },
                  {
                     type: "slidetoggle",
                     label: "Allocation Information",
                     labelPosition: "after", // we have some custom css to support the 'after' styling
                     value: getGanttConfigurePanelValues().showAllocationInformation,
                     onChange: ({ value }) => {
                        updateGanttConfigurePanelLocalStorage("showAllocationInformation", value);
                        ganttRef!.current!.instance.element.classList.toggle(
                           "hide-allocation-information",
                           !value,
                        );
                     },
                     id: "allocation-information",
                  },
                  isTimeoffReasonAvailable && {
                     type: "slidetoggle",
                     label: "Time Off Information",
                     labelPosition: "after",
                     value: getGanttConfigurePanelValues().showTimeoff,
                     onChange: ({ value }) => {
                        updateGanttConfigurePanelLocalStorage("showTimeoff", value);
                        ganttRef!.current!.instance.element.classList.toggle(
                           "hide-time-off-reasons",
                           !value,
                        );
                     },
                     id: "time-off",
                  },
                  {
                     type: "slidetoggle",
                     label: "Hide weekends",
                     labelPosition: "after", // we have some custom css to support the 'after' styling
                     value: getGanttConfigurePanelValues().hideWeekends,
                     onChange: ({ value }) => {
                        updateGanttConfigurePanelLocalStorage("hideWeekends", value);
                        hideWeekends(ganttRef!.current!.instance!, value);
                     },
                     id: "hide-weekends",
                  },
                  {
                     ref: "rowHeightSlider",
                     type: "slider",
                     label: "Row height",
                     labelPosition: "above",
                     min: 25,
                     max: 45,
                     showValue: false,
                     showTooltip: true,
                     value: getGanttConfigurePanelValues().rowHeight,
                     onInput: ({ value }) => {
                        ganttRef!.current!.instance.rowHeight = value;
                        ganttRef!.current!.instance.element.style.setProperty(
                           "--non-working-time-padding-top",
                           `${(value - 25) / 2}px`,
                        );
                        ganttRef!.current!.instance.element.style.setProperty(
                           "--non-working-time-height",
                           `${value - 11}px`,
                        );
                     },
                     onChange: ({ value }) => {
                        updateGanttConfigurePanelLocalStorage("rowHeight", value);
                        // TODO: Whenever this bug-fix gets released by Bryntum, we should be able to remove the below here
                        // Bug ticket: https://github.com/bryntum/support/issues/9698
                        ganttRef!.current!.instance.features.taskNonWorkingTime.disabled = true;
                        ganttRef!.current!.instance.features.taskNonWorkingTime.disabled = false;
                     },
                     id: "row-height",
                  },
                  {
                     ref: "borderRadiusSlider",
                     type: "slider",
                     label: "Task border radius",
                     labelPosition: "above",
                     min: 0,
                     value: getGanttConfigurePanelValues().taskBorderRadius,
                     max: 20,
                     showValue: false,
                     showTooltip: true,
                     onInput: ({ value }) => {
                        ganttRef!.current!.instance.element.style.setProperty(
                           "--task-border-radius",
                           `${value}px`,
                        );
                     },
                     onChange: ({ value }) => {
                        updateGanttConfigurePanelLocalStorage("taskBorderRadius", value);
                     },
                     id: "border-radius",
                  },
               ],
            },
         },
         headerMenuFeature: false,
         // taskMenuFeature: true,
         taskMenuFeature: {
            items: {
               convertToMilestone: false,
               editTask: false,
               milestoneAction: false,
               cut: false,
               copy: false,
               paste: false,
               filterMenu: false,
               add: false,
               splitTask: false,
               indent: false,
               outdent: false,
               deleteTask: false,
               linkTasks: false,
               unlinkTasks: false,
               taskColor: false,
               // We are using the onTaskMenuBeforeShow event listener to programatically update this item
               // to have context-specific text and onItem behavior
               createAssignmentGrouping: {
                  icon: "b-fw-icon b-icon-add",
                  text: "New Category/Subcategory",
               },
               deleteAssignmentGrouping: {
                  icon: "b-fw-icon b-icon-remove",
                  text: "Delete Category/Subcategory",
               },
            },
         },
         timeAxisHeaderMenuFeature: false,
         sortFeature: "name",
         timeRangesFeature: {
            showCurrentTimeLine: {
               name: "Today",
               cls: "gantt-current-timeline",
            },
            tooltipTemplate: ({ timeRange }) => {
               const dateString = DateHelper.format(
                  new Date(timeRange.startDate),
                  defaultStore.getDateFormat(),
               );
               return dateString + " - " + new Date().toLocaleTimeString();
            },
         },
         taskNonWorkingTimeFeature: {
            mode: "both",
            tooltipTemplate({ name: timeOffReason, startDate, endDate, taskRecord }) {
               // don't show tooltip for users without permission to view time off
               if (!isTimeoffReasonAvailable) return;
               // don't show tooltip for weekends
               if (!timeOffReason) return;

               const dateFormat = timeFormat("%Y-%m-%d");
               const intervalId = `${dateFormat(startDate)}_${dateFormat(endDate)}`;
               const {
                  originalData: { name, jobTitleName, jobTitleColor, calendar: calendarId },
               } = taskRecord as any;
               const currentCalendar = calendars.find((calendar) => calendar.id === calendarId);
               // time-off interval ID includes a date part and a unique id, we need to separate and verify the date portion
               const currentTimeOffInterval = currentCalendar?.intervals.find(
                  (interval: any) => interval.id.split("__")[0] === intervalId,
               );
               const { startTime, endTime, repeat, isPaid, applyToSaturday, applyToSunday } =
                  currentTimeOffInterval;
               const startTimeFormatted = formatTime(parseTimeString(startTime));
               const endTimeFormatted = formatTime(parseTimeString(endTime));
               const banIcon = renderToString(<Ban size="sm" color="#232729" />);

               return StringHelper.xss`
               <div class='gantt-project-tooltip ganttDayOffTooltip'>
                  <div class='gantt-project-tooltip__header'>
                        <span class="gantt-project-tooltip-color-icon" style="color: ${jobTitleColor}"></span>
                        <b>${name}</b>
                        <span class='job-title dayOffTooltipSecondary'>${jobTitleName}</span>
                  </div>
                  <hr>
                  <div class='gantt-project-tooltip__footer'>
                        <div>
                           <b>Time Off</b><br>
                        </div>
                     <div class='flex' style="gap: 0">
                        <div style="min-width: 200px">
                           <b>Dates</b><br>
                           ${getDatesRangeString(startDate, endDate)}
                        </div>
                        <div style="min-width: 200px">
                           <b>Times</b><br>
                           ${startTimeFormatted} - ${endTimeFormatted}
                        </div>
                     </div>
                     <div class='flex' style="gap: 0">
                        <div style="min-width: 200px">
                           <b>Reason</b><br>
                           <span class="dayOffTooltipSecondary">${timeOffReason}</span>
                        </div>
                        <div style="min-width: 200px">
                           <b>Type</b><br>
                            <span class="dayOffTooltipSecondary">${
                               isPaid ? "Paid" : "Unpaid"
                            }</span>
                        </div>
                     </div>
                     <div class='flex' style="gap: 0">
                        <div style="min-width: 200px">
                           <b>Weekends</b><br>
                           <div style="display: flex; ${applyToSaturday ? "display: none" : ""}">
                           <img src="data:image/svg+xml;base64,${btoa(
                              banIcon,
                           )}" style="margin-right: 8px">
                           <span>Saturday</span>
                           </div>
                           <div style="display: flex; ${applyToSunday ? "display: none" : ""}">
                           <img src="data:image/svg+xml;base64,${btoa(
                              banIcon,
                           )}" style="margin-right: 8px">
                           <span>Sunday</span>
                           </div>
                        </div>
                        <div style="min-width: 200px">
                           <b>Repeat</b><br>
                           <span class="dayOffTooltipSecondary repeat">${repeat}</span>
                        </div>
                     </div>
                  </div>
               </div>
            `;
            },
         },
         taskTooltipFeature: {
            maxWidth: "unset",
            minWidth: "400px",
            width: "max-content",
            style: "border-radius: 6px;",
            template: ({ taskRecord }: any) => {
               const originalData: any = taskRecord.originalData;
               const { startDate, endDate, name } = taskRecord;

               if (originalData.type === TaskType.PROJECT) {
                  const startTime = formatTime(parseTimeString(originalData.dailyStartTime));
                  const endTime = formatTime(parseTimeString(originalData.dailyEndTime));

                  return StringHelper.xss`
                  <div class='gantt-project-tooltip'>
                     <div class='gantt-project-tooltip__header'>
                        <span class="gantt-project-tooltip-color-icon" style="color: ${
                           originalData.projectColor
                        }"></span>
                        <b>${name} ${
                     originalData.projectJobNumber ? `(${originalData.projectJobNumber})` : ""
                  }</b>
                     </div>
                     <hr>
                     <div class='gantt-project-tooltip__footer'>
                        <div class='flex'>
                           <div class='gantt-project-tooltip-date'>
                              <b>Dates</b><br>
                               ${getDatesRangeString(startDate, endDate)}
                           </div>
                           <div class='gantt-project-tooltip-daily'>
                              <b>Times</b><br>
                              ${startTime} - ${endTime}
                           </div>
                        </div>
                     </div>
                  </div>
               `;
               } else if (
                  originalData.type === TaskType.ASSIGNMENT ||
                  originalData.type === TaskType.REQUEST
               ) {
                  const { startTime, endTime } = originalData;
                  const isTaskWithTime = startTime && endTime;
                  const taskTimeAllocation = getTaskTimeAllocation(originalData);
                  const italicize = originalData.type === TaskType.REQUEST;

                  const workingDayClass = (day: number) =>
                     originalData.workDays?.[day] ? "bold" : "thin";

                  return StringHelper.xss`
                  <div class='gantt-project-tooltip'>
                     <div class='gantt-project-tooltip__header'>
                           <span class="gantt-project-tooltip-color-icon" style="color: ${
                              originalData.jobTitleColor
                           }"></span>
                           <b style='font-style: ${italicize ? "italic" : "normal"}'>${name}</b>
                           <span class='job-title'>${originalData.jobTitleName}</span>
                     </div>
                     <hr>
                     <div class='gantt-project-tooltip__footer'>
                        <div>
                           <b>${originalData.projectName} (${originalData.projectJobNumber})</b>
                        </div>
                        <div class='flex'>
                           <div class='gantt-project-tooltip-date'>
                              <b>Dates</b><br>
                              ${getDatesRangeString(startDate, endDate)}
                           </div>
                           <div class='gantt-project-tooltip-daily'>
                              <b>${startTime && endTime ? "Times" : "Assignment Allocation"}</b><br>
                              ${
                                 isTaskWithTime
                                    ? taskTimeAllocation
                                    : `${taskTimeAllocation}% (${
                                         (paidShiftHours * Number(taskTimeAllocation)) / 100
                                      } hours)`
                              }
                           </div>
                        </div>
                        <div>
                           <div class='gantt-project-tooltip-date'>
                              <b>Work Days</b><br>
                              <div class='work-days'>
                                 <div class='${workingDayClass(0)}'>Su</div>
                                 <div class='${workingDayClass(1)}'>M</div>
                                 <div class='${workingDayClass(2)}'>Tu</div>
                                 <div class='${workingDayClass(3)}'>W</div>
                                 <div class='${workingDayClass(4)}'>Th</div>
                                 <div class='${workingDayClass(5)}'>F</div>
                                 <div class='${workingDayClass(6)}'>Sa</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               `;
               } else if (originalData.type === "category" || originalData.type === "subcategory") {
                  return StringHelper.xss`
                  <div class='gantt-project-tooltip'>
                     <div class='gantt-project-tooltip__header'>
                        <span class="gantt-project-tooltip-color-icon" style="color: ${
                           originalData.projectColor
                        }"></span>
                        <b>${originalData.projectName} (${originalData.projectJobNumber})</b>
                     </div>
                     <hr>
                     <div class='gantt-project-tooltip__footer'>
                        <div>
                           <b>${originalData.categoryName ? originalData.categoryName + ", " : ""}${
                     taskRecord.name
                  }</b>
                        </div>
                        <div class='gantt-project-tooltip-date'>
                           <b>Dates</b><br>
                           ${getDatesRangeString(startDate, endDate)}
                        </div>
                     </div>
                  </div>
               `;
               } else {
                  return "";
               }
            },
         },
         treeFeature: true,
         taskDragFeature: {
            tooltipTemplate({ startDate, endDate, taskRecord }) {
               return tooltipTemplateCallback(taskRecord, startDate, endDate);
            },
            tip: {
               htmlCls: "taskDragTooltipWrapper",
               bodyCls: "taskDragTooltip",
            },
         },
         taskResizeFeature: {
            tooltipTemplate({ startDate, endDate, record }) {
               // For somereason the taskDragFeature respects the fact that our endDate is set to 11:59 PM of it's proper day, but this taskResizeFeature
               // is rounding up to midnight of the next day! To safely correct this, we're simply subtracting one minute from whatever the endDate
               // received here is. Now if Bryntum happens to fix this bug it will just decrement to 11:58 PM of the proper day and that's no big deal at all.
               // As long as it's at the end of the proper day, we're good!
               endDate.setMinutes(endDate.getMinutes() - 1);

               return tooltipTemplateCallback(record, startDate, endDate);
            },
            tip: {
               htmlCls: "taskDragTooltipWrapper resize",
               bodyCls: "taskDragTooltip",
            },
         },
         percentBarFeature: false,
         projectLinesFeature: false,
         dependenciesFeature: false,
         indicatorsFeature: false,
         progressLineFeature: false,
         baselinesFeature: false,
         //#endregion Features
         //#region Events
         onRenderRows: ({ source }) => {
            source.element.style.setProperty(
               "--task-border-radius",
               `${getGanttConfigurePanelValues().taskBorderRadius}px`,
            );
            source.element.style.setProperty(
               "--row-height",
               `${getGanttConfigurePanelValues().rowHeight}px`,
            );
            source.element.style.setProperty(
               "--non-working-time-padding-top",
               `${(getGanttConfigurePanelValues().rowHeight - 25) / 2}px`,
            );
            source.element.style.setProperty(
               "--non-working-time-height",
               `${getGanttConfigurePanelValues().rowHeight - 11}px`,
            );
            hideWeekends(source as Gantt, getGanttConfigurePanelValues().hideWeekends);

            // The purpose of this beforeSort listener is to prevent sorting when the column header is clicked,
            // and only allow sorting when the actual sorting icon is clicked in the header.
            ((source as Gantt).store as Store).on("beforeSort", () => {
               if (lastSortEventTarget?.nodeName == "path") {
                  lastSortEventTarget = lastSortEventTarget.parentElement as HTMLElement;
               }

               // If the target is an svg, then we would need to check the className.baseVal to get the string, otherwise className returns the string
               const targetClass =
                  (lastSortEventTarget?.className as any)?.baseVal ??
                  lastSortEventTarget?.className;
               if (
                  targetClass?.includes("b-sort-icon") == false &&
                  lastSortEventTarget?.tagName != "SPAN"
               ) {
                  return false;
               }
            });
         },
         onRenderRow: (event) => {
            const originalData = (event.record as any).originalData;

            // jobTitleColor is available as a custom property because we explicitly added it when we were building the assignments and requests data
            // for the tasksData object in our ProjectModel
            const jobTitleColor = originalData.jobTitleColor;

            // This query selector is using the meta ID value that associates all cells of a row together.
            // Since it is just querySelector and not querySelectorAll, it will only grab the first cell of the row, which is the one that
            // contains the i tag with class ".b-icon.b-tree-icon.b-icon-tree-leaf" that we want to style. So this chained selector should
            // look inside of the first element that has our row ID and then grab the icon element that's inside of it.
            const leafIcon = document.querySelector(
               `[data-id='${event.row.id}'] i.b-icon.b-tree-icon.b-icon-tree-leaf`,
            );

            if (jobTitleColor && leafIcon) {
               leafIcon.setAttribute("style", `color: ${jobTitleColor}`);
            }

            // Add horizontal rule below the projectName row cells. The way that Bryntum rows are rendered are a bit weird, so in order to work properly,
            // javascript needs to be paired with the CSS rules for "hr.display = 'none'" that are found in gantt.styl
            if (
               originalData.type === TaskType.PROJECT &&
               event.row.element.querySelector("hr") == null
            ) {
               const hr = document.createElement("hr");
               hr.setAttribute(
                  "style",
                  "width: 100%; opacity: 0.5; margin: 0; position: absolute; bottom: -1px;",
               );
               event.row.element.appendChild(hr);
            }

            // UNCOMMENT THIS FOR EXPERIMENTAL PILL STYLING ON CATEGORY/SUBCATEGORY NAME CELLS
            // const [baseColor, lightColor, lightestColor] = getColorShades(originalData.projectColor);
            // const namePill = event.row.element.querySelectorAll(".b-tree-cell-value")[0] as HTMLElement;
            // if (originalData.type === TaskType.CATEGORY) {
            //    namePill.style.setProperty("background-color", lightestColor);
            //    namePill.style.setProperty("border", `solid 1px ${baseColor}`);
            // } else if (originalData.type === TaskType.SUBCATEGORY) {
            //    namePill.style.setProperty("background-color", "white");
            //    namePill.style.setProperty("border", `solid 1px ${baseColor}`);
            // }
         },
         taskRenderer: ({ taskRecord, renderData }) => {
            const originalData = (taskRecord as TaskModel & { originalData: any }).originalData;
            const entityType = originalData.type;
            const projectColor = originalData.projectColor;
            const isProjectEntity = entityType === TaskType.PROJECT;
            const isCategoryEntity =
               entityType === TaskType.CATEGORY || entityType === TaskType.SUBCATEGORY;
            const isTaskEntity =
               entityType === TaskType.ASSIGNMENT || entityType === TaskType.REQUEST;
            const { startTime, endTime } = originalData;

            const [baseColor, lightColor, lightestColor] = getColorShades(projectColor);

            // Adjusts task bar colors based on type of task:
            //   - projects will be base
            //   - categories/subcategories will be lighter
            //   - assignments will be lightest
            //   - requests will be white
            if (isProjectEntity) {
               renderData.style += `background-color: ${baseColor};`;
            } else if (isCategoryEntity) {
               renderData.style += `background-color: ${lightColor};`;
            } else if (isTaskEntity) {
               renderData.style += `text-align: right; color: #667280;`;

               if (entityType === TaskType.ASSIGNMENT) {
                  renderData.style += `border-width: 2px; border-color: ${lightColor}; --event-background-color: ${lightestColor}; background-color: ${lightestColor};`;
               } else if (entityType === TaskType.REQUEST) {
                  renderData.style += `border-width: 2px; border-color: ${baseColor}; background-color: white; border-style: dashed;`;
               }
            }
            // Takes care of rendering the task info on the task bar
            let textContent = "";

            if (isCategoryEntity) {
               textContent = StringHelper.xss`${taskRecord.name} | ${dateFormat(
                  new Date(taskRecord.startDate),
               )} - ${dateFormat(new Date(taskRecord.endDate))}`;
            } else if (isProjectEntity) {
               const projectNumberStr = originalData.projectJobNumber
                  ? `(${originalData.projectJobNumber})`
                  : "";

               textContent =
                  StringHelper.xss`${taskRecord.name} | ${projectNumberStr} ${dateFormat(
                     new Date(taskRecord.startDate),
                  )} - ${dateFormat(new Date(taskRecord.endDate))}` +
                  requestsNumberPill(originalData.requestsNumber);
            } else if (isTaskEntity) {
               textContent = StringHelper.xss`<span class="task-allocation-information">${getTaskTimeAllocation(
                  originalData,
               )}${!startTime && !endTime ? "%" : ""}</span>`;
            }

            if (isProjectEntity || isCategoryEntity)
               if (getGanttConfigurePanelValues().showProjectInformation === false) {
                  ganttRef.current?.instance?.element.classList.add("b-hide-project-information");
               }

            if (getGanttConfigurePanelValues().showAllocationInformation === false) {
               ganttRef.current?.instance?.element.classList.add("hide-allocation-information");
            }

            if (getGanttConfigurePanelValues().showTimeoff === false) {
               ganttRef.current?.instance?.element.classList.add("hide-time-off-reasons");
            }

            return textContent;
         },
         // This event is not what's preventing us from drag-and-dropping tasks on their non-working days.
         // That functionality is enabled by us properly defining "calendars" and setting the config value
         // of "taskNonWorkingTime" to true
         onBeforeTaskDropFinalize: (event: any) => {
            const type = event.context.taskRecords[0].originalData.type;

            // Prevent dragging a project to a date before today
            if (type === "project") {
               const todayDate = new Date(new Date().setHours(0, 0, 0, 0));

               if (event.context.startDate.setHours(0, 0, 0, 0) < todayDate) {
                  event.context.startDate = todayDate;

                  return event.context.finalize(true);
               }
            }

            // Only assignments/requests can have working-days defined, so if this is a project/category/subcategory, go ahead and
            // return here and proceed with the onAfterTaskDrop event.
            if (
               type === TaskType.PROJECT ||
               type === TaskType.CATEGORY ||
               type === TaskType.SUBCATEGORY
            )
               return;

            const workingDays = event.context.taskRecords[0].originalData.workDays;
            const newStartDay = event.context.startDate.getDay();
            const newEndDay = event.context.endDate.getDay();

            if (!workingDays?.[newStartDay] || !workingDays?.[newEndDay]) {
               event.context.finalize(false); // causes `event.valid` to equal false in the onAfterTaskDrop event
               console.info("New dates invalid. New start day and end day must be working days.");
            }
         },
         onGridRowBeforeDropFinalize() {
            droppingGridRow = true;
         },
         onTaskDblClick: () => false,
         onCellDblClick: ({ record }) => {
            if (![TaskType.CATEGORY, TaskType.SUBCATEGORY].includes(record.getData("type")))
               return false;
         },
         onPresetChange: (preset) => {
            if (preset.from == undefined) return;
            localStorage.setItem("gantt-view-preset", String(preset.to.base));
         },
         onDateRangeChange: (e) => {
            setCurrentGanttRange({
               startDay: getDetachedDay(e.new.startDate),
               endDay: getDetachedDay(e.new.endDate),
            });
         },
         onTaskMenuBeforeShow: ({ taskRecord, items }) => {
            const type = taskRecord.getData("type");

            if (![TaskType.PROJECT, TaskType.CATEGORY, TaskType.SUBCATEGORY].includes(type))
               return false;

            const createMenuOption = (items as any).createAssignmentGrouping as MenuItemConfig;
            const deleteMenuOption = (items as any).deleteAssignmentGrouping as MenuItemConfig;

            // ganttRef.current?.instance.project.resumeAutoSync();
            // ganttRef.current?.instance.project.suspendAutoSync();

            if (type === TaskType.PROJECT) {
               deleteMenuOption.hidden = true;

               createMenuOption.text = "add category";
               createMenuOption.onItem = () => {
                  const newCategory = {
                     id: new Model().generateId(),
                     name: "New Category",
                     type: "category",
                     projectId: taskRecord?.getData("id"),
                     expanded: true,
                     sortPriority: sortPriorityLevels[TaskType.CATEGORY],
                  };

                  ganttRef.current!.instance.addSubtask(taskRecord, {
                     data: {
                        ...newCategory,
                     },
                  });
               };
            } else if (type === TaskType.CATEGORY) {
               createMenuOption.text = "add subcategory";
               createMenuOption.onItem = () => {
                  const projectId = taskRecord.getData("projectId");
                  const categoryId = taskRecord.getData("id");

                  const newSubcategory = {
                     id: new Model().generateId(),
                     name: "New Subcategory",
                     text: "New Subcategory",
                     type: "subcategory",
                     projectId,
                     categoryId,
                     expanded: true,
                     sortPriority: sortPriorityLevels[TaskType.SUBCATEGORY],
                  };

                  ganttRef.current!.instance.addSubtask(taskRecord, {
                     data: {
                        ...newSubcategory,
                     },
                  });
               };

               deleteMenuOption.text = "delete this category";
               deleteMenuOption.onItem = () => {
                  showGanttModal({
                     type: GanttModalType.DeleteConfirmation,
                     modalId: "gantt-confirmation-modal-container",
                     title: "Delete Category?",
                     body: (
                        <P>
                           <span>
                              Are you sure you want to permanently delete this category? Doing so
                              will remove all assignments and requests within the category.{" "}
                           </span>
                           <b style={{ fontWeight: "600" }}>This action cannot be undone.</b>
                        </P>
                     ),
                     action: () => {
                        ganttRef.current?.instance.taskStore.remove(taskRecord);
                     },
                  });
               };
            } else if (type === TaskType.SUBCATEGORY) {
               createMenuOption.hidden = true;

               deleteMenuOption.text = "delete this subcategory";
               deleteMenuOption.onItem = () => {
                  showGanttModal({
                     type: GanttModalType.DeleteConfirmation,
                     modalId: "gantt-confirmation-modal-container",
                     title: "View Export",
                     body: (
                        <P>
                           <span>
                              Are you sure you want to permanently delete this subcategory? Doing so
                              will remove all assignments and requests within the subcategory.{" "}
                           </span>
                           <b style={{ fontWeight: "600" }}>This action cannot be undone.</b>
                        </P>
                     ),
                     action: () => {
                        ganttRef.current?.instance.taskStore.remove(taskRecord);
                     },
                  });
               };
            }
         },
         //#endregion Events
      };
   }, [isInitialLoad]);

   const isExpanded = localStorage.getItem("gantt-expanded") !== "false";
   const tasksData = useMemo(() => {
      return (
         ganttData?.projects.map((project: GanttProject) => {
            const projectChildren = groupTasks(
               project,
               ganttData.people,
               ganttData.jobTitles,
               isExpanded,
            );
            const hasProjectStarted = new Date(project.start_date) < new Date();

            return {
               id: project.id,
               name: project.name,
               startDate: new Date(project.start_date),
               endDate: new Date(project.est_end_date),
               children: projectChildren,
               // Adding prefix of "project" to "projectColor" to make it consistent with assignments & requests
               projectColor: project.color,
               projectJobNumber: project.job_number,
               dailyStartTime: project.daily_start_time,
               dailyEndTime: project.daily_end_time,
               type: TaskType.PROJECT,
               expanded: isExpanded,
               // User cannot drag a project if it has already begun
               draggable: !hasProjectStarted,
               resizable: false,
               requestsNumber: project.requests?.length ?? 0,
               manuallyScheduled: false,
               constraintType: "startnoearlierthan",
               sortPriority: sortPriorityLevels[TaskType.PROJECT],
            } as Partial<TaskModelConfig>;
         }) ?? []
      );
   }, [ganttData]);

   // This is a new method, created to support this syncUrl feature. We typically use a store method to communicate between client and server,
   // but this syncUrl param only takes the URI and handles the request to the server internally. We could have just provided the raw
   // string, but I'm unsure if there's special logic in the store.core.ts request methods that change the basePath whenever we're in non-local
   // environments. To be safe, I opted to put the URI construction logic into it's own abstract method, this way we know we're always providing
   // the correct path to our lc-core-api server.
   const completeSyncUrl = AssignmentStore.getFullRequestUrl({
      path: "/api/v3/gantt/sync",
   });

   // We're extending the task model so we can specify that we want our "type" field to always write/appear in the task record data
   // whenever the tasks are added/updated/deleted and sent to the backend through our syncUrl.
   class MyTaskModel extends TaskModel {
      static get fields() {
         return [
            { name: "type", type: "string", alwaysWrite: true },
            { name: "projectId", type: "string", alwaysWrite: true },
            { name: "categoryId", type: "string", alwaysWrite: true },
            { name: "subcategoryId", type: "string", alwaysWrite: true },
         ];
      }
   }

   /**
    * Get calendars data and replace timeoff reasons with their translated values
    */
   const calendars = getCalendarsGanttData(ganttData, isTimeoffReasonAvailable).map((calendar) => {
      calendar.intervals = calendar.intervals.map((interval: any) => {
         if (interval.name) {
            const reasonKey = interval.name.split(" ").join("_");
            interval.name = I18n.t(
               `views.company.workforce_planning.time_off.reasons.${reasonKey}`,
            );
         }

         return interval;
      });

      return calendar;
   });

   const project = new ProjectModel({
      useRawData: false, // v6 defaults this to true, so we're explicitly setting back to false to maintain prior behavior for now
      tasksData,
      phantomIdField: "phantomId",
      onBeforeSend(event) {
         const { requestConfig }: { requestConfig: any } = event;
         const taskStore = ganttRef.current?.instance.taskStore.changes;
         const parsedBody = JSON.parse(requestConfig.body);

         if (parsedBody.tasks.removed) {
            parsedBody.tasks.removed = parsedBody.tasks.removed.map((rem1: any) => {
               const taskRecord = taskStore?.removed?.find(
                  (rem2) => rem2.getData("id") === rem1.id,
               );

               return {
                  id: taskRecord?.getData("id"),
                  projectId: taskRecord?.getData("projectId"),
                  type: taskRecord?.getData("type"),
                  categoryId: taskRecord?.getData("categoryId"),
                  subcategoryId: taskRecord?.getData("subcategoryId"),
               };
            });
         } else if (parsedBody.tasks.updated) {
            parsedBody.tasks.updated = parsedBody.tasks.updated.map((updatedTask: any) => {
               if (updatedTask.parentId) {
                  const newParent = ganttRef.current!.instance.taskStore.getById(
                     updatedTask.parentId,
                  );
                  if (newParent) {
                     const parentType = newParent.getData("type");
                     updatedTask.parentType = parentType;
                     updatedTask.parentProjectId =
                        parentType === TaskType.PROJECT
                           ? newParent.getData("id")
                           : newParent.getData("projectId");
                     updatedTask.parentCategoryId =
                        parentType === TaskType.CATEGORY
                           ? newParent.getData("id")
                           : newParent.getData("categoryId");
                     updatedTask.parentSubcategoryId =
                        parentType === TaskType.SUBCATEGORY
                           ? newParent.getData("id")
                           : newParent.getData("subcategoryId");
                  }
               }

               if (updatedTask.type === "category" && !updatedTask.categoryId) {
                  updatedTask.categoryId = updatedTask.id;
               } else if (updatedTask.type === "subcategory" && !updatedTask.subcategoryId) {
                  updatedTask.subcategoryId = updatedTask.id;
               }
               return updatedTask;
            });
         }

         requestConfig.body = JSON.stringify(parsedBody);
         return Promise.resolve();
      },
      onSync() {
         // (ganttRef.current?.instance.taskStore as any).added?.clear();
         // (ganttRef.current?.instance.taskStore as any).removed?.clear();
         // (ganttRef.current?.instance.taskStore as any).updated?.clear();
      },
      taskStore: {
         // reapplySortersOnAdd: true,
         onBeforeSort: ({ source }) => {
            if (
               lastSortEventTarget?.tagName === "svg" ||
               lastSortEventTarget?.tagName === "path" ||
               (lastSortEventTarget?.tagName === "DIV" &&
                  lastSortEventTarget?.className.includes("b-sort-icon") == false)
            ) {
               return false;
            }

            // Always add primary sort on custom field "sortPriority". This ensures that assignments -> requests -> categories/subcategories
            // always appear in that order, regardless of the secondary sort that is applied on top of it.
            // TODO: this conditional logic needs to be improved so that it consistently works as intended (ie. cat/subcats are always sorted by sequence
            // and you can also re-order them at any time)
            if (droppingGridRow) {
               droppingGridRow = false;
            } else {
               source.sort(
                  [{ field: "sortPriority" }, { field: "sequence" }, ...source.sorters],
                  true,
                  true,
                  true,
               );
            }
         },
      },
      taskModelClass: MyTaskModel,
      calendars,
      syncUrl: completeSyncUrl,
      autoSync: true,
   });

   // The BryntumGantt is a wrapper for the actually underlying gantt instance. If you want to,
   // you can use ganttRef.current.instance to access the actual underlying gantt instance.
   const ganttRef = useRef<BryntumGantt>(null);
   (window as any).ganttRef = ganttRef;
   // const ganttConfirmationModalRef = useRef<typeof GanttConfirmationModal>(null);

   // EXAMPLES of how you can access different internal stores of the ProjectModel (https://bryntum.com/products/gantt/docs/guide/Gantt/data/project_data#updating-the-project-data):
   // ganttRef.current?.instance.project.changes
   // ganttRef.current?.instance.project.taskStore.changes
   // ganttRef.current?.instance.project.timeRangeStore.changes
   // ganttRef.current?.instance.widgetMap.right.toggleCollapsed()
   // ganttRef.current?.instance.element.style.setProperty("--row-height", `${getGanttConfigurePanelValues().rowHeight}px`);

   return (
      <DetailPage width="block">
         <DetailPage.Main className="grandchildren-border-box">
            <DetailPage.Body>
               <DetailPage.Card style={{ display: "flex", overflow: "hidden" }}>
                  <GanttFilterPanel
                     ganttFilter={ganttFilter}
                     setGanttFilter={setGanttFilter}
                     onClose={() => {
                        document.body.querySelector(".filterPanel")?.classList.remove("visible");
                        document.body
                           .querySelector(".gantt-filter-toggle-button")
                           ?.classList.remove("controlPanelButtonActive");
                     }}
                     jobTitles={ganttData?.jobTitles ?? []}
                  />
                  <DetailPage.Section className="detailPageSection">
                     <Box>
                        <Spinner loading={isInitialLoad} data-testid="loading-spinner">
                           <GanttControlPanel
                              search={search}
                              setSearch={setSearch}
                              toggleFilterPanel={() => {
                                 document.body
                                    .querySelector(".filterPanel")!
                                    .classList.toggle("visible");
                                 document.body
                                    .querySelector(".gantt-filter-toggle-button")
                                    ?.classList.toggle("controlPanelButtonActive");
                              }}
                              toggleConfigPanel={() =>
                                 (
                                    ganttRef.current?.instance.widgetMap.right as Panel
                                 ).toggleCollapsed()
                              }
                              ganttFilter={ganttFilter}
                              setGanttFilter={setGanttFilter}
                              onZoomOut={() => ganttRef.current?.instance.zoomOut()}
                              onZoomIn={() => ganttRef.current?.instance.zoomIn()}
                           />
                           {ganttData && (
                              <BryntumGantt ref={ganttRef} {...ganttConfig} project={project} />
                           )}
                        </Spinner>
                     </Box>
                  </DetailPage.Section>
               </DetailPage.Card>
               <GantProjectTearsheet ref={ganttTearsheetRef} />
            </DetailPage.Body>
         </DetailPage.Main>
      </DetailPage>
   );
};

function groupTasks(project: any, people: any, jobTitles: any, isExpanded: boolean): GroupedTasks {
   const dateParse = timeParse("%Y-%m-%d");
   const categories: Category[] = project.cost_codes;
   const assignments: GroupableTask[] = project.assignments;
   const requests: GroupableTask[] = project.requests;

   // Step 1: Create a nested lookup table for tasks
   const taskLookup: TaskLookup = {};
   const noCategoryTasks: Task[] = [];
   const noSubcategoryTasks: noSubcategoryTasks = {};

   const group = (categoryId: string, subcategoryId: string, task: Task) => {
      if (categoryId === null) {
         noCategoryTasks.push(task);
      } else {
         if (!taskLookup[categoryId]) {
            taskLookup[categoryId] = {};
         }
         if (subcategoryId === null) {
            if (!noSubcategoryTasks[categoryId]) {
               noSubcategoryTasks[categoryId] = [];
            }
            noSubcategoryTasks[categoryId].push(task);
         } else {
            if (!taskLookup[categoryId][subcategoryId]) {
               taskLookup[categoryId][subcategoryId] = [];
            }
            taskLookup[categoryId][subcategoryId].push(task);
         }
      }
   };

   assignments.forEach((assignment: any) => {
      const person = people.find((person: any) => person.id == assignment.resource_id) as any;

      if (person == null) {
         console.error("person not found for assignment:", assignment);
      } else {
         const jobTitle = jobTitles.find((jobTitle: any) => jobTitle.id == person.job_title_id);
         const startDate = dateParse(assignment.start_day)!;
         const endDate = new Date(dateParse(assignment.end_day)!.setHours(23, 59, 59)); // assignment bar should end at the end of the day
         const assignmentTask: Task = {
            id: assignment.id,
            type: TaskType.ASSIGNMENT,
            startDate,
            endDate,
            startTime: assignment.start_time,
            endTime: assignment.end_time,
            name: `${person.first_name} ${person.last_name}`,
            projectId: project.id,
            projectColor: project.color,
            projectName: project.name,
            projectJobNumber: project.job_number,
            jobTitleColor: jobTitle?.color,
            jobTitleName: jobTitle?.name,
            workDays: assignment.work_days,
            calendar: getCalendarName(assignment, TaskType.ASSIGNMENT) as CalendarModel & string,
            manuallyScheduled: false,
            constraintType: "startnoearlierthan",
            sortPriority: sortPriorityLevels[TaskType.ASSIGNMENT],
         };

         group(assignment.cost_code_id, assignment.label_id, assignmentTask);
      }
   });

   requests.forEach((request: any) => {
      const jobTitle = jobTitles.find((jobTitle: any) => jobTitle.id == request.job_title_id);
      const startDate = dateParse(request.start_day)!;
      const endDate = new Date(dateParse(request.end_day)!.setHours(23, 59, 59)); // request bar should end at the end of the day

      const requestTask: Task = {
         id: request.id,
         type: TaskType.REQUEST,
         startDate,
         endDate,
         startTime: request.start_time,
         endTime: request.end_time,
         name: "unassigned",
         projectId: project.id,
         projectColor: project.color,
         projectName: project.name,
         projectJobNumber: project.job_number,
         jobTitleColor: jobTitle?.color,
         jobTitleName: jobTitle?.name,
         workDays: request.work_days,
         calendar: getCalendarName(request, TaskType.REQUEST) as CalendarModel & string,
         manuallyScheduled: false,
         constraintType: "startnoearlierthan",
         sortPriority: sortPriorityLevels[TaskType.REQUEST],
      };

      group(request.cost_code_id, request.label_id, requestTask);
   });

   // Step 2: Organize tasks into the desired structure
   const groupedCategories = categories
      .map((category) => {
         const groupedSubcategories = category.labels
            .map((subcategory) => {
               if (subcategory == null) {
                  console.error("subcateogry is null. supposed to belong to category:", category);
               }
               const children =
                  (taskLookup[category.id] && taskLookup[category.id][subcategory.id]) || [];
               return {
                  id: subcategory?.id,
                  archived: subcategory?.archived,
                  categoryId: category.id,
                  subcategoryId: subcategory?.id,
                  name: subcategory?.name ?? null,
                  categoryName: category.name,
                  projectId: project.id,
                  projectColor: project.color,
                  projectName: project.name,
                  projectJobNumber: project.job_number,
                  type: TaskType.SUBCATEGORY,
                  children: children,
                  expanded: isExpanded,
                  sequence: subcategory.sequence,
                  sortPriority: sortPriorityLevels[TaskType.SUBCATEGORY],
               };
            })
            .filter((subcategory) => subcategory.archived === false);

         const children = (noSubcategoryTasks[category.id] ?? []).concat(
            groupedSubcategories as any,
         );

         return {
            id: category.id,
            archived: category.archived,
            categoryId: category.id,
            name: category.name,
            projectId: project.id,
            projectColor: project.color,
            projectName: project.name,
            projectJobNumber: project.job_number,
            type: TaskType.CATEGORY,
            children,
            expanded: isExpanded,
            sequence: category.sequence,
            sortPriority: sortPriorityLevels[TaskType.CATEGORY],
         };
      })
      .filter((category) => category.archived === false);

   return noCategoryTasks.concat(groupedCategories as any);
}

/* istanbul ignore next */
function lightenHexColor(hex: string, amount: number) {
   if (!hex) return;

   if (hex.indexOf("#") !== 0) {
      hex = "#" + hex;
   }

   const num = parseInt(hex.slice(1), 16);
   let r = num >> 16;
   let g = (num >> 8) & 0x00ff;
   let b = num & 0x0000ff;

   // Scale each component by the percentage amount towards 255
   r = Math.floor(r + (255 - r) * amount);
   g = Math.floor(g + (255 - g) * amount);
   b = Math.floor(b + (255 - b) * amount);

   // Combine back into a hex string
   return "#" + ((r << 16) | (g << 8) | b).toString(16).padStart(6, "0");
}

function getColorShades(color: string) {
   const baseColor = color;
   const lightColor = lightenHexColor(baseColor, 0.4) ?? baseColor;
   const lightestColor = lightenHexColor(baseColor, 0.8) ?? baseColor;

   return [baseColor, lightColor, lightestColor];
}

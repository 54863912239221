import { Flex, Search, Button, useI18nContext, FlexList } from "@procore/core-react";
import type { Dispatch, SetStateAction } from "react";
import React, { useState } from "react";
import { Filter, Sliders, ZoomMinus, ZoomPlus } from "@procore/core-icons";
import type { ganttFilterType } from "./prop-types";
import { GanttControlPanelFilterPills } from "./gantt-control-panel-filter-pills";
import "./gantt-container.css";

type VoidFn = () => void;
export interface GanttControlPanelProps {
   search: string;
   ganttFilter: ganttFilterType;
   setSearch: (value: string) => void;
   toggleConfigPanel: VoidFn;
   toggleFilterPanel: VoidFn;
   setGanttFilter: Dispatch<SetStateAction<ganttFilterType>>;
   onZoomOut: VoidFn;
   onZoomIn: VoidFn;
}

export const GanttControlPanel = (props: GanttControlPanelProps) => {
   const I18n = useI18nContext();
   const { search, setSearch, toggleConfigPanel, toggleFilterPanel, ganttFilter, setGanttFilter } =
      props;

   // Instead of passing the isConfigPanelOpen state in as a prop, just initialize it here and let it always default to closed
   const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);

   return (
      <Flex direction="row" gap="sm" className="ganttControlPanelWrapper">
         <div style={{ display: "flex", alignItems: "center" }}>
            <div>
               <Search
                  value={search}
                  onSubmit={(value) => setSearch(value)}
                  onClear={() => setSearch("")}
                  placeholder={I18n.t("views.company.workforce_planning.gantt.search_placeholder")}
                  style={{ width: "300px" }}
                  data-testid="search-input"
               />
            </div>
            <Button
               variant="tertiary"
               icon={<Filter size="sm" />}
               onClick={() => {
                  toggleFilterPanel();
               }}
               style={{ margin: "0 4px 0 8px" }}
               className={"gantt-filter-toggle-btn"}
               data-testid="filter-panel-toggle-btn"
            />
            <GanttControlPanelFilterPills
               ganttFilter={ganttFilter}
               setGanttFilter={setGanttFilter}
            />
         </div>
         <FlexList space="xs">
            <Button
               icon={<ZoomMinus size="sm" />}
               onClick={props.onZoomOut}
               variant="tertiary"
               data-testid="zoom-out-btn"
            />
            <Button
               icon={<ZoomPlus size="sm" />}
               onClick={props.onZoomIn}
               variant="tertiary"
               data-testid="zoom-in-btn"
            />
            <Button
               variant="tertiary"
               icon={<Sliders size="sm" />}
               onClick={() => {
                  toggleConfigPanel();
                  setIsConfigPanelOpen(!isConfigPanelOpen);
               }}
               data-testid="config-panel-toggle-btn"
               className={isConfigPanelOpen ? "controlPanelButtonActive" : ""}
            />
         </FlexList>
      </Flex>
   );
};

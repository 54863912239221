import { GeneratedReportStore } from "@/stores/generated-report-store.core";
import {
   convertPeopleDataTableFilters,
   fieldTranslationKeyMap,
   legacyFieldNamesMap,
   sortFieldMaps,
} from "./helpers";
import type { PeopleListServerFilter } from "./people-list-prop-types";
import { findCustomField } from "@/react/shared/custom-field-utils";
import { FindPeopleSortBy } from "./people-list-enums";
import { ColumnEntityType } from "@laborchart-modules/common";
import type { CommonReportParams, ReportResponse } from "@/react/prop-types";
import { GenerateReportType, ReportType } from "@/react/prop-types";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";

export async function createPeopleListReport(params: CommonReportParams): Promise<ReportResponse> {
   const { configKey, customFields, groupId, formValues, I18n, isLastNameFirst, search } = params;
   const localStorageTableConfig = JSON.parse(localStorage.getItem(configKey) ?? "");
   const column_headers = localStorageTableConfig.columnState
      .filter((col: any) => !col.hidden && !["assignments", "type"].includes(col.field))
      .map((col: any, i: number) => {
         const customField = findCustomField(customFields!, col.field);

         let meta;

         const key = customField ? "custom_fields" : legacyFieldNamesMap[col.field];

         const name = customField ? col.field : I18n.t(fieldTranslationKeyMap[col.field]);

         const base_name = customField ? customField.integration_name : null;

         // Add meta object when customField exists
         if (customField) {
            meta = {
               field_id: customField.id,
               field_property: customField.integration_name,
               field_type: customField.type,
               field_entity: ColumnEntityType.PEOPLE,
            };
         }

         return {
            key,
            name,
            width: Math.round(col.width),
            sequence: i,
            base_name,
            sortable: true,
            sub_properties: [],
            ...(customField && { meta }), // Add meta field only if customField exists
         };
      });
   const sortField = localStorageTableConfig.columnState.find((col: any) => col.sort !== null);
   let sortBy: FindPeopleSortBy;
   if (sortField) {
      sortBy = sortFieldMaps[sortField.field];
   } else {
      sortBy = isLastNameFirst
         ? FindPeopleSortBy.PERSON_NAME_LAST
         : FindPeopleSortBy.PERSON_NAME_FIRST;
   }

   const payload: any = {
      sort_by: sortBy,
      sort_direction: sortField?.sort == "desc" ? Order.DESCENDING : Order.ASCENDING,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // This is how legacy report generation gets TZ
      report_type: ReportType.PeopleList,
      group_id: groupId,
      limit: 40,
      filters: convertPeopleDataTableFilters(
         localStorageTableConfig.serverFilters as PeopleListServerFilter[],
      ),
      page_size: {
         height:
            formValues.orientation.id == "portrait"
               ? formValues.page_size.value.height
               : formValues.page_size.value.width,
         width:
            formValues.orientation.id == "portrait"
               ? formValues.page_size.value.width
               : formValues.page_size.value.height,
      },
      display_last_names_first: isLastNameFirst,
      file_type: formValues.file_type.value,
      column_headers,
      ...(search && { search }), // add search if it exists
   };
   const report = await GeneratedReportStore.create(GenerateReportType.PeopleList, payload).payload;

   return report;
}

import { timeOptions } from "@/lib/utils/timezones";
import { type FormikHelpers, type FormikProps } from "formik";
import type { CreateAssignmentTearsheetProps } from "@/react/prop-types";
import { ActionMode } from "@/react/prop-types";
import {
   Page,
   H1,
   Box,
   Flex,
   Required,
   FlexList,
   Button,
   Form,
   Card,
   Title,
   useI18nContext,
   Spinner,
   H2,
} from "@procore/core-react";
import type { FC } from "react";
import React, { useEffect } from "react";

import { assignmentSchema } from "./validation-schemas";
import { useGroupContext } from "@/react/providers/group-context-provider";
import { useGetAssignmentCreationSupportDataQuery } from "./queries";
import { CustomFieldEntity } from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields";
import { useGetCustomFields } from "../../common/queries/queries";
import type { WorkdayOptions } from "../../labor-plans/prop-types";
import {
   AuthAction,
   usePermissionContext,
   useViewPreferenceContext,
} from "@/react/providers/permission-context-provider";
import { formatOptions } from "@/react/shared/helper";
import { type Assignment, type AssignmentFormValues } from "./types";
import { Assignment2Store } from "@/stores/assignment-2-store.core";
import { useToastAlertContext } from "@procore/toast-alert";
import { DEFAULT_END_TIME, DEFAULT_START_TIME, workDays } from "@/react/shared/constants";
import { processCustomFieldsFormValues } from "@/react/shared/custom-field-utils";
import { formInitialValues } from "./constants";
import type { View } from "@procore/core-react/dist/Form/Form.types";
import type { GetAssignmentDetailReponse } from "@laborchart-modules/lc-core-api/dist/api/assignments";
import { DateUtils } from "@/lib/utils/date";
import type { ProjectOptions } from "../types";
import { DropDownType } from "../types";
import { useDropDownSelectionHandler } from "@/react/shared/hooks/useDropDownSelectionHandler";
import { getCommonFieldsForRequestAndAssignment, getTimeOptionsFromProject } from "../helper";
import { ScheduleCard } from "../shared/schedule-card";
import CustomFieldsCard from "../shared/custom-fields-card";

export const AssignmentTearsheetContent: FC<CreateAssignmentTearsheetProps> = ({
   onClose,
   showProjectDetail, // its an optional property so it can be undefined will be defined when create Assignment button is clicked from project detail page
   projectId, // its an optional property so it can be undefined will be defined when create Assignment button is clicked from project detail page
   assignmentId, // its an optional property so it can be undefined will be defined in the edit Assignment flow
}) => {
   const { groupId } = useGroupContext();
   const { showToast } = useToastAlertContext();
   const I18n = useI18nContext();
   const { checkAuthAction } = usePermissionContext();
   const { data: assignmentCreationSupportData, isLoading: supportDataLoading } =
      useGetAssignmentCreationSupportDataQuery(groupId);
   const { data: customFields } = useGetCustomFields(CustomFieldEntity.ASSIGNMENT);

   const {
      categoryOptions,
      subCategoryOptions,
      categoryPlaceHolder,
      subCategoryPlaceHolder,
      currentAssignments,
      handleSelection,
      resetSubCategory,
   } = useDropDownSelectionHandler(assignmentCreationSupportData);

   const [selectedWorkDays, setSelectedWorkDays] = React.useState<WorkdayOptions>(workDays);

   const [assignmentByTime, setAssignmentByTime] = React.useState(true);
   const [assignmentByAllocation, setAssignmentByAllocation] = React.useState(false);
   const [canViewAllStatuses, setCanViewAllStatuses] = React.useState<boolean>(false);
   const [loading, setLoading] = React.useState<boolean>(false);
   const [filterIntegrationOnly, setFilterIntegrationOnly] = React.useState<boolean>(true);
   const [formViewMode, setFormViewMode] = React.useState<ActionMode>(ActionMode.CREATE);
   const [initialValues, setInitialValues] = React.useState<AssignmentFormValues>(
      formInitialValues(projectId),
   );

   const { getViewPreference } = useViewPreferenceContext();

   const isLastNameFirst = getViewPreference()?.displayLastNamesFirst() ?? false;

   const getResourceName = (personName: { first?: string; last?: string }): string => {
      const { first = "", last = "" } = personName;
      return isLastNameFirst ? `${last}, ${first}`.trim() : `${first} ${last}`.trim();
   };

   // Helper function to map data to initial values for the assignment form, this will be triggered in edit mode
   const mapDataToInitialValues = (assignmentDetails: GetAssignmentDetailReponse) => {
      const { data } = assignmentDetails;
      // Extract initial values
      const baseFormValues = {
         resource: {
            id: data.person?.id,
            label: getResourceName(data.person.name),
         },
         project: {
            id: data.project?.id,
            label: data.project?.name,
         },
         status: data.status
            ? {
                 id: data.status.id,
                 label: data.status.name,
              }
            : null,
         category: data.category
            ? {
                 id: data.category.id,
                 label: data.category.name,
              }
            : null,
         subcategory: data.subcategory
            ? {
                 id: data.subcategory.id,
                 label: data.subcategory.name,
              }
            : null,
         start_date: DateUtils.getAttachedDate(data.start_day),
         end_date: DateUtils.getAttachedDate(data.end_day),
         start_time: {
            id: data.start_time ?? DEFAULT_START_TIME,
            label: data.start_time
               ? timeOptions.find(({ id }) => id === data.start_time)!.label
               : timeOptions.find(({ id }) => id === DEFAULT_START_TIME)!.label,
         },
         end_time: {
            id: data.end_time ?? DEFAULT_END_TIME,
            label: data.end_time
               ? timeOptions.find(({ id }) => id === data.end_time)!.label
               : timeOptions.find(({ id }) => id === DEFAULT_END_TIME)!.label,
         },
         assignment_by_time: Boolean(data.start_time && data.end_time),
         assignment_by_allocation: Boolean(data.percent_allocated),
         work_days: data.work_days,
         percent_allocated: data.percent_allocated ?? 100,
      };
      // Initialize custom fields in form values
      const customFieldValues = processCustomFieldsFormValues(data.custom_fields);
      // setting local states
      setAssignmentByAllocation(baseFormValues.assignment_by_allocation);
      setAssignmentByTime(baseFormValues.assignment_by_time);
      setSelectedWorkDays(baseFormValues.work_days);
      setFormViewMode(ActionMode.READ);
      setFilterIntegrationOnly(false);
      return { ...baseFormValues, ...customFieldValues };
   };

   useEffect(() => {
      const fetchAssignmentDetails = async () => {
         if (assignmentId) {
            try {
               const assignmentDetails: GetAssignmentDetailReponse =
                  await Assignment2Store.getAssignmentDetails(assignmentId).payload;
               setInitialValues(mapDataToInitialValues(assignmentDetails));
            } catch (error) {
               showToast.error(I18n.t("views.company.workforce_planning.error"));
            }
         }
      };
      fetchAssignmentDetails();
   }, [assignmentId]);

   // this is needed to toggle the category and subcategory options/placeholders based on the project/category selection
   useEffect(() => {
      if (initialValues.project) {
         handleSelection(initialValues.project, DropDownType.PROJECT);
      }

      if (initialValues.category) {
         handleSelection(initialValues.category, DropDownType.CATEGORY);
      }
   }, [initialValues]);

   const handleClose = () => {
      // this will navigate to project details page on cancel click
      if (projectId && showProjectDetail) {
         showProjectDetail(projectId);
      }
      // this will simply close the tearsheet
      else {
         onClose();
      }
   };

   useEffect(() => {
      const canViewAllStatuses = checkAuthAction(AuthAction.CAN_VIEW_ALL_STATUSES);
      setCanViewAllStatuses(canViewAllStatuses);
   }, [checkAuthAction]);

   const validationSchema = assignmentSchema(
      I18n,
      !canViewAllStatuses,
      selectedWorkDays,
      assignmentByAllocation,
      assignmentByTime,
   );

   async function handleSubmit(
      values: AssignmentFormValues,
      { resetForm }: FormikHelpers<AssignmentFormValues>,
   ) {
      const commonFields = getCommonFieldsForRequestAndAssignment(values, customFields);
      const assignment: Assignment = {
         resource_id: values.resource!.id,
         ...commonFields,
      };
      try {
         setLoading(true);
         if (assignmentId) {
            const updatedAssignment = await Assignment2Store.updateSingleAssignment({
               assignmentId,
               update: {
                  ...assignment,
               },
            }).payload;

            if (updatedAssignment.data) {
               setFormViewMode(ActionMode.READ);
            }
         } else {
            await Assignment2Store.createAssignment(assignment).payload;
         }
      } catch (error) {
         if (assignmentId) {
            showToast.error(I18n.t("views.company.workforce_planning.assignment.edit_error"));
            resetForm();
         } else {
            showToast.error(I18n.t("views.company.workforce_planning.assignment.create_error"));
         }
      } finally {
         setLoading(false);
         // made this conditional because in edit flow we don't want to close the tearsheet we simply make it read only or reset the form in case of error
         if (!assignmentId) {
            handleClose();
         }
      }
   }
   return (
      <Spinner loading={(supportDataLoading || loading) && assignmentCreationSupportData === null}>
         <Page>
            <Page.Main
               style={{
                  width: "950px",
                  display: "flex",
                  flexDirection: "column",
               }}
            >
               <Page.Header>
                  <Page.Title>
                     <H1>
                        {assignmentId
                           ? I18n.t("views.company.workforce_planning.assignment.edit_title")
                           : I18n.t("views.company.workforce_planning.assignment.create_title")}
                     </H1>
                  </Page.Title>
               </Page.Header>
               <Form
                  view={formViewMode as View}
                  onSubmit={handleSubmit}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  enableReinitialize
               >
                  {/* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */}
                  {({
                     submitForm,
                     isSubmitting,
                     setFieldValue,
                  }: FormikProps<AssignmentFormValues>) => (
                     <React.Fragment>
                        <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                           <Card style={{ marginBottom: "10px" }}>
                              <Box padding="md">
                                 <FlexList justifyContent="space-between" marginBottom="sm">
                                    <Title>
                                       <Title.Text>
                                          <H2>
                                             {I18n.t(
                                                "views.company.workforce_planning.specifications",
                                             )}
                                          </H2>
                                       </Title.Text>
                                    </Title>
                                    {formViewMode === ActionMode.READ && (
                                       <Button
                                          variant="secondary"
                                          onClick={() => setFormViewMode(ActionMode.UPDATE)}
                                          data-testid="edit-assignment"
                                       >
                                          {I18n.t("views.company.workforce_planning.edit")}
                                       </Button>
                                    )}
                                 </FlexList>
                                 <Form.Form>
                                    <Form.Row>
                                       <Form.Select
                                          colStart={1}
                                          colWidth={6}
                                          name="resource"
                                          label={I18n.t(
                                             "views.company.workforce_planning.resource",
                                          )}
                                          required
                                          options={formatOptions(
                                             assignmentCreationSupportData?.data.resource_options ??
                                                [],
                                          )}
                                          placeholder={I18n.t(
                                             "views.company.workforce_planning.select_option",
                                          )}
                                          onClear={false}
                                          onSelect={({ item }) => {
                                             handleSelection(item, DropDownType.RESOURCE);
                                          }}
                                       />
                                       <Form.Select
                                          colStart={7}
                                          colWidth={6}
                                          name="project"
                                          label={I18n.t("views.company.workforce_planning.project")}
                                          required
                                          options={formatOptions(
                                             assignmentCreationSupportData?.data.project_options ??
                                                [],
                                          )}
                                          onSelect={({ item }) => {
                                             const selectedItem =
                                                assignmentCreationSupportData?.data.project_options.find(
                                                   (project) => project.id === item.id,
                                                ) as ProjectOptions;
                                             handleSelection(item, DropDownType.PROJECT);
                                             setFieldValue("category", null);
                                             setFieldValue("subcategory", null);
                                             setFieldValue(
                                                "start_time",
                                                getTimeOptionsFromProject(
                                                   selectedItem,
                                                   DEFAULT_START_TIME,
                                                   "daily_start_time",
                                                ),
                                             );
                                             setFieldValue(
                                                "end_time",
                                                getTimeOptionsFromProject(
                                                   selectedItem,
                                                   DEFAULT_END_TIME,
                                                   "daily_end_time",
                                                ),
                                             );
                                          }}
                                          placeholder={I18n.t(
                                             "views.company.workforce_planning.select_option",
                                          )}
                                          onClear={false}
                                       />
                                    </Form.Row>
                                    <Form.Row>
                                       <Form.Select
                                          colStart={1}
                                          colWidth={6}
                                          name="category"
                                          label={I18n.t(
                                             "views.company.workforce_planning.category",
                                          )}
                                          options={categoryOptions}
                                          onSelect={({ item }) => {
                                             handleSelection(item, DropDownType.CATEGORY);
                                             setFieldValue("subcategory", null);
                                          }}
                                          placeholder={I18n.t(categoryPlaceHolder)}
                                          disabled={categoryOptions.length === 0}
                                          onClear={() => {
                                             resetSubCategory();
                                             setFieldValue("subcategory", null);
                                          }}
                                       />
                                       <Form.Select
                                          colStart={7}
                                          colWidth={6}
                                          name="subcategory"
                                          label={I18n.t(
                                             "views.company.workforce_planning.subcategory",
                                          )}
                                          options={subCategoryOptions}
                                          placeholder={I18n.t(subCategoryPlaceHolder)}
                                          disabled={subCategoryOptions.length === 0}
                                       />
                                    </Form.Row>
                                 </Form.Form>
                              </Box>
                           </Card>
                           {/** form for the schedule card */}
                           <ScheduleCard
                              I18n={I18n}
                              setFieldValue={setFieldValue}
                              assignmentCreationSupportData={assignmentCreationSupportData}
                              selectedWorkDays={selectedWorkDays}
                              setSelectedWorkDays={setSelectedWorkDays}
                              assignmentByTime={assignmentByTime}
                              setAssignmentByTime={setAssignmentByTime}
                              assignmentByAllocation={assignmentByAllocation}
                              setAssignmentByAllocation={setAssignmentByAllocation}
                              canViewAllStatuses={canViewAllStatuses}
                              currentAssignments={currentAssignments}
                           />
                           {/** form for the additional information/custom field card */}
                           <CustomFieldsCard
                              customFields={customFields}
                              I18n={I18n}
                              filterIntegrationOnly={filterIntegrationOnly}
                           />
                        </Page.Body>
                        {formViewMode !== ActionMode.READ && (
                           <Page.Footer>
                              <Box padding="md">
                                 <Flex justifyContent="space-between" alignItems="center">
                                    <Required showLabel />
                                    <FlexList space="sm">
                                       <Button
                                          variant="tertiary"
                                          disabled={isSubmitting}
                                          onClick={handleClose}
                                       >
                                          {I18n.t("views.company.workforce_planning.cancel")}
                                       </Button>
                                       <Button
                                          type="submit"
                                          disabled={isSubmitting}
                                          onClick={submitForm}
                                       >
                                          {assignmentId
                                             ? I18n.t("views.company.workforce_planning.publish")
                                             : I18n.t("views.company.workforce_planning.create")}
                                       </Button>
                                    </FlexList>
                                 </Flex>
                              </Box>
                           </Page.Footer>
                        )}
                     </React.Fragment>
                  )}
               </Form>
            </Page.Main>
         </Page>
      </Spinner>
   );
};

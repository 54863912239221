import React, { useEffect, useState, useCallback } from "react";
import {
   Avatar,
   Box,
   Button,
   Card,
   EmptyState,
   FlexList,
   Grid,
   H2,
   Select,
   Spinner,
   Title,
   Typography,
   useI18nContext,
} from "@procore/core-react";
import type {
   ActivitiesResponseProps,
   ListActivitiesProps,
   ProjectDetailsActivitiesProps,
   ProjectSpecificOptionType,
} from "../types";
import { IntegratedEntity } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import { dateSortOptions, projectSpecificDateSortOptions } from "@/react/shared/constants";
import {
   formatCurrency,
   formatDate,
   formatFullDateTime,
   getInitials,
   prepareActivitiesPayload,
} from "../helpers";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import { ActivityStore } from "@/stores/activity-store.core";

import styles from "./project-details.scss";
import classnames from "classnames";
import { AuthAction, usePermissionContext } from "@/react/providers/permission-context-provider";
import { ProjectActiviesInfoCard } from "./project-activies-info-card";
import { useFindActivityPaginatedQuery } from "@/react/components/common/queries/queries";
const cx = classnames.bind(styles);

const EmptyActivities: React.FC = () => {
   const I18n = useI18nContext();

   return (
      <React.Fragment>
         <Box padding="md">
            <FlexList justifyContent="space-between">
               <H2>
                  {I18n.t("views.company.workforce_planning.project_details_activities.title")}
               </H2>
            </FlexList>
         </Box>
         <Box padding="md">
            <EmptyState compact>
               <EmptyState.NoItemsWithoutCreatePermissions />
               <EmptyState.Title>
                  {I18n.t(
                     "views.company.workforce_planning.project_details_activities.activities_empty_state_title",
                  )}
               </EmptyState.Title>
               <EmptyState.Description className={cx("empty-state-description")}>
                  {I18n.t(
                     "views.company.workforce_planning.project_details_activities.activities_empty_state_description",
                  )}
               </EmptyState.Description>
            </EmptyState>
         </Box>
      </React.Fragment>
   );
};

const ListActivities: React.FC<ListActivitiesProps> = ({
   activitiesState,
   setActivitiesState,
   projectId,
   entityType,
   includedCategories,
   nextStartingAfter,
   setNextStartingAfter,
   limitActivities,
   projectSpecificOption,
   setLimitActivities,
}) => {
   const I18n = useI18nContext();
   const [sortedActivities, setSortedActivities] = useState(activitiesState);
   const [sortOption, setSortOption] = useState(dateSortOptions[1]);
   const [sortProjectSpecificOption, setSortProjectSpecificOption] =
      useState<ProjectSpecificOptionType>(projectSpecificOption[0]);

   useEffect(() => {
      setSortedActivities(activitiesState);
   }, [activitiesState]);

   useEffect(() => {
      loadSpecificActivities();
   }, [sortProjectSpecificOption]);

   useEffect(() => {
      const sorted = [...activitiesState].sort((a, b) => {
         const dateA = new Date(a.created_at).getTime();
         const dateB = new Date(b.created_at).getTime();
         return sortOption.value === Order.ASCENDING ? dateA - dateB : dateB - dateA;
      });
      setSortedActivities(sorted);
   }, [sortOption, activitiesState]);

   const loadMoreActivities = useCallback(async () => {
      const payload = prepareActivitiesPayload(
         projectId,
         entityType,
         sortProjectSpecificOption.id === "0"
            ? includedCategories
            : [sortProjectSpecificOption.value],
         12,
         nextStartingAfter,
      );
      const loadMoreActivitiesData = await ActivityStore.findActivityPaginated(payload, {}).payload;
      if (loadMoreActivitiesData?.data && loadMoreActivitiesData?.data.length > 0) {
         setNextStartingAfter(loadMoreActivitiesData?.pagination?.next_starting_after || "");
         setActivitiesState((prevState) => [...prevState, ...loadMoreActivitiesData?.data]);
      }
   }, [
      projectId,
      entityType,
      sortProjectSpecificOption,
      includedCategories,
      nextStartingAfter,
      setActivitiesState,
      setNextStartingAfter,
   ]);

   const loadSpecificActivities = useCallback(async () => {
      const payload = prepareActivitiesPayload(
         projectId,
         entityType,
         sortProjectSpecificOption.id === "0"
            ? includedCategories
            : [sortProjectSpecificOption.value],
         12,
      );
      const loadSpecificActivitiesData = await ActivityStore.findActivityPaginated(payload, {})
         .payload;
      setNextStartingAfter(loadSpecificActivitiesData?.pagination?.next_starting_after || "");
      setActivitiesState(loadSpecificActivitiesData?.data);
      setLimitActivities(loadSpecificActivitiesData?.pagination?.total_possible);
   }, [
      projectId,
      entityType,
      sortProjectSpecificOption,
      includedCategories,
      setActivitiesState,
      setNextStartingAfter,
      setLimitActivities,
   ]);

   const renderActivityContent = (activity: ActivitiesResponseProps) => {
      switch (activity.category) {
         case projectSpecificDateSortOptions[1].value:
            return (
               <Box display="flex" style={{ marginTop: "10px" }}>
                  <Typography intent="body" color="gray50">
                     <span className={cx("light-text")}>{`${
                        activity?.action_type === "create" ? "Added " : "Removed "
                     }`}</span>
                     <span
                        className={cx("dark-highlighted-text")}
                     >{`${activity?.subject_2_name} `}</span>
                     <span className={cx("light-text")}>{`${
                        activity?.action_type === "create" ? "to " : "attachment from "
                     }`}</span>
                     <span
                        className={cx("dark-highlighted-text")}
                     >{`${activity?.subject_1_name}`}</span>
                  </Typography>
               </Box>
            );
         case projectSpecificDateSortOptions[2].value:
            return (
               <Box display="flex" style={{ marginTop: "10px" }}>
                  <Typography intent="body" color="gray50">
                     <span className={cx("light-text")}>{`${
                        activity?.action_type === "create" ? "Added " : "Removed "
                     }`}</span>
                     <span
                        className={cx("dark-highlighted-text")}
                     >{`${activity?.subject_2_name} `}</span>
                     <span className={cx("light-text")}>{`${
                        activity?.action_type === "create" ? "to " : "a"
                     }`}</span>
                     <span
                        className={cx("dark-highlighted-text")}
                     >{`${activity?.subject_1_name}`}</span>
                  </Typography>
               </Box>
            );
         case projectSpecificDateSortOptions[3].value:
            return (
               <Box display="flex" style={{ marginTop: "10px" }}>
                  <Typography intent="body" color="gray50">
                     <span className={cx("light-text")}>{`${
                        activity?.action_type === "create"
                           ? `Added Request (status: ${activity?.meta?.status_name}) from `
                           : "Deleted Request from "
                     }`}</span>
                     <span className={cx("dark-highlighted-text")}>{`${formatDate(
                        activity?.meta?.start_day,
                     )} `}</span>
                     <span className={cx("light-text")}>{"to "}</span>
                     <span className={cx("dark-highlighted-text")}>
                        {formatDate(activity?.meta?.end_day)}
                     </span>
                  </Typography>
               </Box>
            );
         case projectSpecificDateSortOptions[5].value:
            return (
               <Box display="flex" style={{ marginTop: "10px" }}>
                  <Typography intent="body" color="gray50">
                     <span className={cx("light-text")}>{`${
                        activity?.action_type === "create"
                           ? "Added a Note to "
                           : "Removed a Note from "
                     }`}</span>
                     <span
                        className={cx("dark-highlighted-text")}
                     >{`${activity?.subject_1_name}`}</span>
                  </Typography>
               </Box>
            );
         case projectSpecificDateSortOptions[6].value:
            return (
               <Box display="flex" style={{ marginTop: "10px" }}>
                  <Typography intent="body" color="gray50">
                     <span className={cx("light-text")}>{`${
                        activity?.action_type === "create" ? "Added " : "Removed "
                     }`}</span>
                     <span className={cx("dark-highlighted-text")}>{`${
                        typeof activity?.subject_3_name === "string"
                           ? activity.subject_3_name
                           : activity.subject_3_name?.first
                     } ${
                        typeof activity?.subject_3_name === "string"
                           ? activity.subject_3_name
                           : activity.subject_3_name?.last
                     } `}</span>
                     <span className={cx("light-text")}>{`${
                        activity?.action_type === "create"
                           ? "to the project role "
                           : "from the project role "
                     }`}</span>
                     <span
                        className={cx("dark-highlighted-text")}
                     >{`${activity?.subject_2_name}`}</span>
                  </Typography>
               </Box>
            );
         case projectSpecificDateSortOptions[7].value:
            return (
               <Box display="flex" style={{ marginTop: "10px" }}>
                  <Typography intent="body" color="gray50">
                     <span className={cx("light-text")}>{`${
                        activity?.action_type === "create" ? "Added " : "Removed "
                     }`}</span>
                     <span
                        className={cx("dark-highlighted-text")}
                     >{`${activity?.subject_2_name} `}</span>
                     <span className={cx("light-text")}>{`${
                        activity?.action_type === "create" ? "to " : "from "
                     }`}</span>
                     <span
                        className={cx("dark-highlighted-text")}
                     >{`${activity?.subject_1_name}`}</span>
                  </Typography>
               </Box>
            );
         case projectSpecificDateSortOptions[8].value:
            return (
               <Box display="flex" style={{ marginTop: "10px" }}>
                  <Typography intent="body" color="gray50">
                     <span className={cx("light-text")}>{`${
                        activity?.action_type === "create"
                           ? "Added a "
                           : "Removed Wage Override of "
                     }`}</span>
                     <span className={cx("dark-highlighted-text")}>{`${formatCurrency(
                        activity?.meta?.rate,
                        I18n,
                     )} `}</span>
                     <span>{`${
                        activity?.action_type === "create" ? "Wage Override for the " : "from the "
                     }`}</span>
                     <span
                        className={cx("dark-highlighted-text")}
                     >{`${activity?.subject_2_name} `}</span>
                     <span className={cx("light-text")}>position</span>
                  </Typography>
               </Box>
            );
         case projectSpecificDateSortOptions[4].value:
            return <ProjectActiviesInfoCard activity={activity} />;
         default:
            return null;
      }
   };

   return (
      <React.Fragment>
         <Box padding="lg">
            <FlexList justifyContent="space-between">
               <Title>
                  <Title.Text>
                     <H2>
                        {I18n.t(
                           "views.company.workforce_planning.project_details_activities.title",
                        )}
                     </H2>
                  </Title.Text>
                  <Title.Subtext>
                     {I18n.t(
                        "views.company.workforce_planning.project_details_activities.activities_sub_text",
                     )}
                  </Title.Subtext>
               </Title>
            </FlexList>
         </Box>
         <Box display="flex" alignItems="center" padding="md">
            <Select
               label={sortProjectSpecificOption.label}
               onSelect={(option) => setSortProjectSpecificOption(option.item)}
               style={{ marginRight: "10px" }}
               placeholder="Type: Request"
            >
               {projectSpecificOption.map(
                  (option) =>
                     option.show && (
                        <Select.Option
                           key={option.id}
                           value={option}
                           selected={option.id === sortProjectSpecificOption.id}
                        >
                           {option.label}
                        </Select.Option>
                     ),
               )}
            </Select>
            <Typography intent="label" style={{ marginRight: "10px" }}>
               {I18n.t("views.company.workforce_planning.projects.sort_by")}
            </Typography>
            <Select label={sortOption.label} onSelect={(option) => setSortOption(option.item)}>
               {dateSortOptions.map((option) => (
                  <Select.Option
                     key={option.id}
                     value={option}
                     selected={option.id === sortOption.id}
                  >
                     {option.label}
                  </Select.Option>
               ))}
            </Select>
         </Box>
         <Box>
            {sortedActivities.map((activity: ActivitiesResponseProps) => (
               <Box key={activity.id} padding="md" marginBottom="sm" className={cx("custom-card")}>
                  <Grid>
                     <Grid.Row>
                        <Grid.Col style={{ display: "flex", alignItems: "center" }}>
                           <Avatar style={{ marginRight: "8px" }}>
                              <Avatar.Label>
                                 {getInitials(
                                    `${activity?.author_name?.first} ${activity.author_name?.last}`,
                                 )}
                              </Avatar.Label>
                           </Avatar>
                           <div style={{ display: "flex", alignItems: "center" }}>
                              <Typography intent="h3" style={{ marginRight: "8px" }}>
                                 {`${activity?.author_name?.first} ${activity.author_name?.last}`}
                              </Typography>
                              <Typography
                                 intent="small"
                                 color="gray50"
                                 style={{ fontWeight: 300, marginTop: "3px" }}
                              >
                                 {`${formatFullDateTime(activity?.created_at)}`}
                              </Typography>
                           </div>
                        </Grid.Col>
                     </Grid.Row>
                     <Grid.Row>
                        <Grid.Col>{renderActivityContent(activity)}</Grid.Col>
                     </Grid.Row>
                  </Grid>
               </Box>
            ))}
         </Box>
         <Box display="flex" alignItems="center" justifyContent="center" padding="md">
            {sortedActivities.length < limitActivities && (
               <Button variant="secondary" onClick={loadMoreActivities}>
                  {"Load More"}
               </Button>
            )}
         </Box>
      </React.Fragment>
   );
};

export const ProjectDetailsActivities: React.FC<ProjectDetailsActivitiesProps> = ({
   projectId,
}) => {
   const { checkAuthAction } = usePermissionContext();
   const [activitiesState, setActivitiesState] = useState<ActivitiesResponseProps[]>([]);
   const [nextStartingAfter, setNextStartingAfter] = useState<string>("");
   const [limitActivities, setLimitActivities] = useState<number>(12);
   const [projectSpecificOption, setProjectSpecificOption] = useState<ProjectSpecificOptionType[]>(
      projectSpecificDateSortOptions,
   );

   const includedCategories: string[] = [];

   const entityType = IntegratedEntity.PROJECTS;

   useEffect(() => {
      const actionMapping = [
         {
            id: "1",
            action: AuthAction.VIEW_PROJECT_ATTACHMENTS,
            value: projectSpecificDateSortOptions[1].value,
         },
         {
            id: "5",
            action: AuthAction.VIEW_PROJECT_NOTES,
            value: projectSpecificDateSortOptions[5].value,
         },
         {
            id: "6",
            action: AuthAction.VIEW_PROJECT_ROLES,
            value: projectSpecificDateSortOptions[6].value,
         },
         {
            id: "7",
            action: AuthAction.VIEW_PROJECT_TAGS,
            value: projectSpecificDateSortOptions[7].value,
         },
         {
            id: "8",
            action: AuthAction.VIEW_PROJECT_WAGE_OVERRIDES,
            value: projectSpecificDateSortOptions[8].value,
         },
      ];

      actionMapping.forEach(({ id, action, value }) => {
         if (checkAuthAction(action)) {
            includedCategories.push(value);
         } else {
            setProjectSpecificOption((prevItems) =>
               prevItems.map((item) => (item.id === id ? { ...item, show: false } : item)),
            );
         }
      });

      includedCategories.push(projectSpecificDateSortOptions[2].value);
      includedCategories.push(projectSpecificDateSortOptions[3].value);
   }, [checkAuthAction]);

   const { data: activitiesData, isLoading: activitiesLoading } = useFindActivityPaginatedQuery(
      projectId,
      entityType,
      includedCategories,
      12,
   );

   useEffect(() => {
      if (activitiesData?.data && activitiesData?.data.length > 0) {
         setNextStartingAfter(activitiesData?.pagination?.next_starting_after || "");
         setActivitiesState(activitiesData?.data);
         setLimitActivities(activitiesData?.pagination?.total_possible);
      }
   }, [activitiesData]);

   return (
      <Card style={{ marginBottom: "5px" }}>
         <Spinner loading={activitiesLoading}>
            {activitiesData && activitiesData.data && activitiesData?.data.length === 0 && (
               <EmptyActivities />
            )}
            {activitiesData && activitiesData.data && activitiesData?.data.length > 0 && (
               <ListActivities
                  activitiesState={activitiesState}
                  setActivitiesState={setActivitiesState}
                  projectId={projectId}
                  entityType={entityType}
                  includedCategories={includedCategories}
                  nextStartingAfter={nextStartingAfter}
                  setNextStartingAfter={setNextStartingAfter}
                  limitActivities={limitActivities}
                  projectSpecificOption={projectSpecificOption}
                  setLimitActivities={setLimitActivities}
               />
            )}
         </Spinner>
      </Card>
   );
};

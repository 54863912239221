import "./alerts-filter-pane.styl"
import template from "./alerts-filter-pane.pug"
import * as ko from "knockout"

### Auth, Real-Time & Stores ###
import { CompanyStore } from "@/stores/company-store.core"
import { groupStore } from "@/stores/group-store"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { PopupPane } from "@/lib/components/popup/popup-pane"

### Models ###
import { Company } from "@/models/company"

## UI Assets ##
import { MultiDropDownItem } from "@/lib/components/drop-downs/multi-drop-down"

export class AlertsFilterPane extends PopupPane
   constructor: (options) ->
      assertArgs(arguments, Object)
      assertOfType(options.showReplyBasedOptions, optional(Function))
      assertOfType(options.hasReplyOne, Function)
      assertOfType(options.hasReplyTwo, Function)
      assertOfType(options.hasReplyThree, Function)
      assertOfType(options.recievedAllReplies, Function)
      assertOfType(options.partiallyReplied, Function)
      assertOfType(options.noReplies, Function)
      assertOfType(options.selectedProjectIds, Function)
      super(template(), "Filters")
      # Popup Properties
      @enableOverflow = ko.observable(true)

      # Used to allow filter to still make sense for Drafts and Scheduled views.
      if options.showReplyBasedOptions?
         @showReplyBasedOptions = options.showReplyBasedOptions
      else
         @showReplyBasedOptions = true

      @alertConfig = ko.observable()

      @hasReplyOne = options.hasReplyOne
      @hasReplyTwo = options.hasReplyTwo
      @hasReplyThree = options.hasReplyThree

      @recievedAllReplies = options.recievedAllReplies
      @partiallyReplied = options.partiallyReplied
      @noReplies = options.noReplies

      @selectedProjects = ko.observableArray()
      @selectedProjectIds = options.selectedProjectIds
      @selectedProjects.subscribe (newVal) =>
         @selectedProjectIds newVal.map (i) -> return i.value()
      @projectOptions = ko.observableArray()

      @leftActionBtn = ko.observable({
         callback: @clearAll
         text: "Clear All"
      })

      @rightActionBtn = ko.observable({
         callback: @applyChanges
         text: "Apply"
      })

      @loadData()

   applyChanges: =>
      @dismissHandler(Popup.ExitStatus.AFFIRMATIVE_ACTION)

   clearAll: =>
      @hasReplyOne(false)
      @hasReplyTwo(false)
      @hasReplyThree(false)
      @recievedAllReplies(false)
      @partiallyReplied(false)
      @noReplies(false)
      @selectedProjects([])
      @dismissHandler(Popup.ExitStatus.NEGATING_ACTION)

   getCompanyAlertConfig: (callback) ->
      try
         result = await CompanyStore.getAlertConfig().payload
         if result.data?
            callback(null, new Company.AlertConfig(result.data))
         else
            callback(null, null)
      catch err
         callback(err)

   loadData: ->
      @getCompanyAlertConfig (err, config) =>
         return console.log "Error: ", err if err
         @alertConfig(config)

      # my-groups is used here to match the criteria the alerts are bing access controlled by.
      # TODO: change to use core CompanyStore.getCompanyEntityOptions with group filter
      groupStore.getGroupEntities "my-groups", ['projects'], (err, data) =>
         return console.log "Error: ", err if err
         selectedOptions = []
         @projectOptions data.projectOptions.map (item) =>
            selected = @selectedProjectIds().indexOf(item.id) != -1
            option = new MultiDropDownItem(item.name(), item.id, selected, item.color(), item.baggage())
            selectedOptions.push(option) if selected
            return option
         @selectedProjects(selectedOptions)

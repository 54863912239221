import { Pill } from "@procore/core-react";
import React from "react";
import "./dynamic-token-styles.css";

type DynamicTokenProps = {
   label: string;
   onClear?: () => void;
   onClick?: () => void;
};
export const DynamicToken = (props: DynamicTokenProps) => {
   return (
      <Pill
         onClear={props.onClear}
         onClick={props.onClick}
         key={props.label}
         className="dynamic-token-pill"
      >
         {`{ ${props.label} }`}
      </Pill>
   );
};

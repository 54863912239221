import template from "./projects-filter-pane.pug"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { groupStore } from "@/stores/group-store"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { PopupPane } from "@/lib/components/popup/popup-pane"

## UI Assets ##
import { MultiDropDownItem } from "@/lib/components/drop-downs/multi-drop-down"

import ko from "knockout"

export class ProjectsFilterPane extends PopupPane
   constructor: (selectedFilters) ->
      assertArgs(arguments, Object)
      super(template(), "Filters")
      
      @tagCategoriesEnabled = authManager.companyModules()?.tagCategories

      # Popup Properties
      @enableOverflow = ko.observable(true)

      @selectedTagIds = selectedFilters.selectedTagIds
      @editingTags = ko.observableArray()
      @tagOptions = ko.observableArray()
      @categorizedTags = ko.observable()

      @bidRateLow = selectedFilters.bidRateLow
      @editingBidRateLow = ko.observable(@bidRateLow())

      @bidRateHigh = selectedFilters.bidRateHigh
      @editingBidRateHigh = ko.observable(@bidRateHigh())

      @startDateLow = selectedFilters.startDateLow
      @editingStartDateLow = ko.observable(@startDateLow())

      @startDateHigh = selectedFilters.startDateHigh
      @editingStartDateHigh = ko.observable(@startDateHigh())

      @endDateLow = selectedFilters.endDateLow
      @editingEndDateLow = ko.observable(@endDateLow())

      @endDateHigh = selectedFilters.endDateHigh
      @editingEndDateHigh = ko.observable(@endDateHigh())

      @leftActionBtn = ko.observable({
         callback: @clearAll
         text: "Clear All"
         })

      @rightActionBtn = ko.observable({
         callback: @applyChanges
         text: "Apply"
         })

      @loadData()

   applyChanges: =>
      @selectedTagIds(@editingTags().map (item) -> return item.value())
      @bidRateLow(@editingBidRateLow())
      @bidRateHigh(@editingBidRateHigh())
      @startDateLow(@editingStartDateLow())
      @startDateHigh(@editingStartDateHigh())
      @endDateLow(@editingEndDateLow())
      @endDateHigh(@editingEndDateHigh())
      @dismissHandler(Popup.ExitStatus.AFFIRMATIVE_ACTION)

   clearAll: =>
      @selectedTagIds([])
      @editingTags([])
      @bidRateLow(null)
      @editingBidRateLow(null)
      @bidRateHigh(null)
      @editingBidRateHigh(null)
      @startDateLow(null)
      @editingStartDateLow(null)
      @startDateHigh(null)
      @editingStartDateHigh(null)
      @endDateLow(null)
      @editingEndDateLow(null)
      @endDateHigh(null)
      @editingEndDateHigh(null)
      @dismissHandler(Popup.ExitStatus.NEGATING_ACTION)

   loadData: ->
      entities = []
      if @tagCategoriesEnabled
         entities.push('categorized-tags')
      else
         entities.push('tags')

      # TODO: change to use core CompanyStore.getCompanyEntityOptions with group filter
      groupStore.getGroupEntities authManager.selectedGroupId(), entities, (err, data) =>
         return console.log "Error: ", err if err

         if data.tagOptions? and data.tagOptions.length > 0
            @tagOptions data.tagOptions.map (item) =>
               selected = @selectedTagIds().indexOf(item.value()) != -1
               option = new MultiDropDownItem(item.name(), item.value(), selected)
               @editingTags.push(option) if selected
               return option
         else if data.categorizedTagOptions?
            for key, val of data.categorizedTagOptions
               for item in val
                  selected = @selectedTagIds().indexOf(item.value()) != -1
                  if selected
                     item.baggage({group: key})
                     item.selected(selected)
                     @editingTags.push(item) 
            @categorizedTags(data.categorizedTagOptions)


import React from "react";
import { Box, Button, Select, Typography, useI18nContext } from "@procore/core-react";
import { Plus } from "@procore/core-icons";
import type { NotesToolbarProps } from "@/react/prop-types";

export const NotesToolbar: React.FC<NotesToolbarProps> = ({
   sortOptions,
   selectedSortOption,
   onSortOptionSelect = () => {}, // Default to a no-op function
   onAddNoteClick = () => {}, // Default to a no-op function
   isAddNoteDisabled,
   canEditNotes,
}) => {
   const I18n = useI18nContext();
   return (
      <Box display="flex" justifyContent="space-between" alignItems="center" padding="sm">
         <Box display="flex" alignItems="center">
            <Typography intent="label" style={{ marginRight: "10px" }}>
               {I18n.t("views.company.workforce_planning.projects.sort_by")}
            </Typography>
            <Select
               label={selectedSortOption.label}
               onSelect={(option) => onSortOptionSelect(option.item)}
               disabled={isAddNoteDisabled}
            >
               {sortOptions.map((option) => (
                  <Select.Option
                     key={option.id}
                     value={option}
                     selected={option.id === selectedSortOption.id}
                  >
                     {option.label}
                  </Select.Option>
               ))}
            </Select>
         </Box>
         {canEditNotes && (
            <Button
               icon={<Plus />}
               variant="secondary"
               onClick={onAddNoteClick}
               disabled={isAddNoteDisabled}
            >
               {I18n.t("views.company.workforce_planning.project_details_notes.add_note")}
            </Button>
         )}
      </Box>
   );
};

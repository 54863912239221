import React, { useState } from "react";
import type {
   CustomField,
   TagOption,
   StreamResponseConversion,
   JobTitle,
} from "@/react/prop-types";
import { TagStore } from "@/stores/tag-store.core";
import { CustomFieldStore } from "@/stores/custom-field-store.core";
import type { CustomFieldEntity } from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields";
import { CompanyStore } from "@/stores/company-store.core";
import type { GetIntegratedFieldsResponse } from "@laborchart-modules/lc-core-api/dist/api/company/get-integrated-fields";
import { PersonStore } from "@/stores/person-store.core";
import { PersonStatus } from "@laborchart-modules/common/dist/rethink/schemas/enums/people";
import { SavedViewStore } from "@/stores/saved-view-store.core";
import type { GetSavedViewResponse } from "@laborchart-modules/lc-core-api/dist/api/saved-views/get-saved-view";
import type { GetCompanyResponse } from "@laborchart-modules/lc-core-api/dist/api/company/get-company";
import { PositionStore } from "@/stores/position-store.core";
import { PermissionStore } from "@/stores/permission-store.core";
import { TwilioStoreCore } from "../../../../stores/twilio-store.core";
import type { ValidatePhoneNumberResponse } from "@laborchart-modules/lc-core-api/dist/api/twilio/validate-phone-number";
import { ActivityStore } from "@/stores/activity-store.core";
import type { FindActivityPaginatedResponse } from "@laborchart-modules/lc-core-api/dist/api/activity/find-activity";
import type { ActivityEntityType } from "@laborchart-modules/common/dist/reql-builder/procedures/find-activity";
import type { ActivityCategory } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import { TimeOffStore } from "@/stores/time-off-store.core";
import {
   FindTimeOffFilter,
   FindTimeOffSortBy,
} from "@laborchart-modules/common/dist/reql-builder/procedures/enums/find-time-off";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import {
   FilterClassifier,
   FilterFieldType,
} from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import type {
   FindTimeOffStreamQueryParams,
   SerializedFindTimeOff,
} from "@laborchart-modules/lc-core-api/dist/api/time-off/find-time-off";
import { filterNameMaps } from "../../time-off-list/helpers";
import { getDetachedDay } from "@laborchart-modules/common/dist/datetime";

export function useGetTagOptionsQuery() {
   const [data, setData] = useState<TagOption[] | null>(null);
   const [isLoading, setIsLoading] = useState(true);

   React.useEffect(() => {
      async function getTags() {
         setIsLoading(true);
         setData(null);

         const tags = [];
         const stream = await TagStore.findTagsStream({}).stream;
         if (stream) {
            for await (const {
               id,
               abbreviation,
               name,
               color,
               categories,
               group_ids,
               globally_accessible,
            } of stream) {
               tags.push({
                  id,
                  label: abbreviation,
                  name,
                  color,
                  categories,
                  group_ids,
                  globally_accessible,
               });
            }

            setData(tags);
            setIsLoading(false);
         }
      }
      getTags();
   }, []);

   return { data, isLoading };
}

export function useGetCustomFields(entityType: CustomFieldEntity) {
   const [data, setData] = React.useState<CustomField[] | null>(null);
   const [isLoading, setIsLoading] = React.useState(false);

   React.useEffect(() => {
      async function getCustomFields() {
         setIsLoading(true);
         setData(null);

         const customField: CustomField[] = [];
         const stream = await CustomFieldStore.findCustomFieldsStream({
            is_on_entities: [entityType],
         }).stream;
         for await (const field of stream) {
            customField.push(field);
         }
         setData(customField);
         setIsLoading(false);
      }
      getCustomFields();
   }, []);

   return { data, isLoading };
}

export function useGetIntegratedFields() {
   const [data, setData] = React.useState<GetIntegratedFieldsResponse | null>(null);
   const [isLoading, setIsLoading] = React.useState(true);

   React.useEffect(() => {
      async function getIntegratedFields() {
         setIsLoading(true);
         setData(null);

         const data = await CompanyStore.getIntegratedFields().payload;

         setData(data);
         setIsLoading(false);
      }
      getIntegratedFields();
   }, []);

   return { data, isLoading };
}
export function useGetPeopleStream(group_id?: string) {
   const [data, setData] = React.useState<StreamResponseConversion | null>(null);
   const [isLoading, setIsLoading] = React.useState(true);
   const params = { group_id, status: PersonStatus.ACTIVE };

   React.useEffect(() => {
      async function getPeopleStream() {
         setIsLoading(true);
         setData(null);

         const stream = await PersonStore.findPeopleStream(params).stream;

         const data = [];

         for await (const item of stream) {
            data.push({
               id: item.id,
               label: `${item.name.first} ${item.name.last}`,
            });
         }
         setData(data);
         setIsLoading(false);
      }
      getPeopleStream();
   }, []);

   return { data, isLoading };
}

export function useGetSavedView(viewId: string | null) {
   const [data, setData] = React.useState<GetSavedViewResponse | null>(null);
   const [isLoading, setIsLoading] = React.useState(true);

   React.useEffect(() => {
      async function getGetSavedView() {
         if (!viewId) {
            setIsLoading(false);
            return;
         }
         setIsLoading(true);
         setData(null);

         const data = await SavedViewStore.getSavedView(viewId).payload;

         setData(data);
         setIsLoading(false);
      }
      getGetSavedView();
   }, [viewId]);
   return { data, isLoading };
}
export function useGetCompany() {
   const [data, setData] = React.useState<GetCompanyResponse | null>(null);
   const [isLoading, setIsLoading] = React.useState(true);

   React.useEffect(() => {
      async function getCompany() {
         setIsLoading(true);
         setData(null);

         const data = await CompanyStore.getCompany().payload;

         setData(data);
         setIsLoading(false);
      }
      getCompany();
   }, []);
   return { data, isLoading };
}

export function useGetJobTitles(groupId?: string) {
   const params: { group_id?: string } = {};
   if (groupId != "my-groups") {
      params.group_id = groupId;
   }
   const [data, setData] = React.useState<JobTitle[]>([]);
   const [isLoading, setIsLoading] = React.useState(true);

   React.useEffect(() => {
      async function getPositions() {
         setIsLoading(true);
         setData([]);

         const stream = await PositionStore.findPositionsStream(params).stream;

         const jobTitles: JobTitle[] = [];

         for await (const jobTitle of stream) {
            jobTitles.push({
               id: jobTitle.id,
               label: jobTitle.name,
            });
         }
         setData(jobTitles);
         setIsLoading(false);
      }
      getPositions();
   }, []);

   return { data, isLoading };
}

export function useGetPermissionLevelOptions() {
   const [data, setData] = React.useState<Array<{ id: string; label: string; is_admin: boolean }>>(
      [],
   );
   const [isLoading, setIsLoading] = React.useState(true);

   React.useEffect(() => {
      async function streamPermissionLevels() {
         const permissions: Array<{ id: string; label: string; is_admin: boolean }> = [];
         const stream = await PermissionStore.findPermissionLevelsStream({}).stream;
         for await (const permissionLevel of stream) {
            permissions.push({
               id: permissionLevel.id,
               label: permissionLevel.name,
               is_admin: permissionLevel.is_admin,
            });
         }
         setData(permissions);
         setIsLoading(false);
      }
      streamPermissionLevels();
   }, []);

   return { data, isLoading };
}

export async function streamPeopleOptions(
   groupId: string,
): Promise<Array<{ id: string; label: string }>> {
   const params = {
      group_id: groupId !== "my-groups" ? groupId : undefined,
      status: PersonStatus.ACTIVE,
   };

   const stream = await PersonStore.findPeopleStream(params).stream;

   const data = [];

   for await (const item of stream) {
      data.push({
         id: item.id,
         label: `${item.name.first} ${item.name.last}`,
      });
   }

   return data;
}

export async function streamTagOptions(): Promise<any> {
   const tags = [];
   const stream = await TagStore.findTagsStream({}).stream;
   if (stream) {
      for await (const {
         id,
         abbreviation,
         name,
         color,
         categories,
         group_ids,
         globally_accessible,
      } of stream) {
         tags.push({
            id,
            label: abbreviation,
            name,
            color,
            categories,
            group_ids,
            globally_accessible,
         });
      }
   }
   return tags;
}

export async function validatePhoneNumber(number: string): Promise<ValidatePhoneNumberResponse> {
   return await TwilioStoreCore.validateNumber(number).payload;
}

export function useFindActivityPaginatedQuery(
   entityId: string,
   entityType?: string,
   includedCategories?: string[],
   limit: number = 12,
   starting_after?: string,
) {
   const [data, setData] = React.useState<FindActivityPaginatedResponse | null>(null);
   const [isLoading, setIsLoading] = React.useState(true);

   const fetchActivities = React.useCallback(async () => {
      setIsLoading(true);
      setData(null);
      const data = await ActivityStore.findActivityPaginated(
         {
            entity_id: entityId,
            entity_type: entityType as ActivityEntityType,
            included_categories: includedCategories as ActivityCategory[],
            limit: limit,
            starting_after: starting_after,
         },
         {},
      ).payload;
      setData(data);
      setIsLoading(false);
   }, [entityId, entityType, limit]);

   React.useEffect(() => {
      if (!entityId || !entityType) return;
      fetchActivities();
   }, [entityId, entityType, fetchActivities]);

   return { data, isLoading };
}

export function useGetUpcomingTimeOff(personId: string) {
   const [scheduledTimeOff, setScheduledTimeOff] = React.useState<SerializedFindTimeOff[] | null>(
      null,
   );
   const [isLoading, setIsLoading] = React.useState(true);

   const fetchScheduledTimeOff = async () => {
      setIsLoading(true);
      setScheduledTimeOff(null);

      const query: FindTimeOffStreamQueryParams = {
         filters: [
            {
               name: FindTimeOffFilter.PERSON_ID,
               type: FilterFieldType.TEXT,
               property: FindTimeOffFilter.PERSON_ID,
               value_sets: [{ negation: false, value: personId }],
            },
            {
               name: filterNameMaps.start_date,
               type: FilterFieldType.DATE,
               property: FindTimeOffFilter.START_DATE,
               value_sets: [
                  {
                     negation: false,
                     value: getDetachedDay(new Date()),
                     classifier: FilterClassifier.GREATER_THAN_OR_EQUAL,
                  },
               ],
            },
         ],
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
         sort_by: FindTimeOffSortBy.START_DATE,
         sort_direction: Order.ASCENDING,
      };
      const data = await TimeOffStore.findTimeOffStream(query).stream;

      const timeOffOptions: SerializedFindTimeOff[] = []; // Update the type of timeOffOptions

      for await (const timeOff of data) {
         timeOffOptions.push(timeOff);
      }
      setScheduledTimeOff(timeOffOptions);
      setIsLoading(false);
   };

   React.useEffect(() => {
      fetchScheduledTimeOff();
   }, [personId]);

   return { scheduledTimeOff, isLoading, fetchScheduledTimeOff };
}

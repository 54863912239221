import * as React from "react";
import { GroupStore } from "@/stores/group-store.core";
import { ProjectStore } from "@/stores/project-store.core";
import { TotalsStore } from "@/stores/totals-store.core";
import type { ExtendedGetProjectDetailResponse } from "./types";
import type { StreamResponseConversion } from "@/react/prop-types";

export function useGetProjectQuery(id: string) {
   const [data, setData] = React.useState<ExtendedGetProjectDetailResponse | null>(null);
   const [isLoading, setIsLoading] = React.useState(true);

   const fetchProjectDetails = React.useCallback(async () => {
      setIsLoading(true);
      setData(null);
      const data = await ProjectStore.getProjectDetails(id).payload;
      setData(data);
      setIsLoading(false);
   }, [id]);

   React.useEffect(() => {
      if (!id) return;
      fetchProjectDetails();
   }, [id, fetchProjectDetails]);

   const refetch = React.useCallback(() => {
      fetchProjectDetails();
   }, [fetchProjectDetails]);

   return { data, isLoading, refetch };
}

export function useGetGroupOptionsQuery() {
   const [data, setData] = React.useState<StreamResponseConversion | null>(null);
   const [isLoading, setIsLoading] = React.useState(true);

   React.useEffect(() => {
      async function getGroups() {
         setIsLoading(true);
         setData(null);

         const groups = [];
         const stream = await GroupStore.findGroupsStream().stream;
         for await (const { id, name } of stream) {
            groups.push({ id, label: name });
         }

         setData(groups);
         setIsLoading(false);
      }
      getGroups();
   }, []);

   return { data, isLoading };
}

export function useGetProjectTotalsQuery(id: string) {
   const [data, setData] = React.useState<number | null>(null);
   const [isLoading, setIsLoading] = React.useState(true);

   React.useEffect(() => {
      async function getTotals() {
         setIsLoading(true);
         setData(null);

         const data = (
            await TotalsStore.getProjectTotals(id, {
               getAssignments: "true",
               getRequests: "true",
               totalsUnit: "hours",
               viewBy: "project",
            }).payload
         ).data;

         let total_hours = 0;
         // Sum up the totals response and set state
         const assignment_totals = data.rollup_data.daily_ass_totals;
         const request_totals = data.rollup_data.daily_request_totals;
         Object.keys(assignment_totals).forEach((key: string) => {
            total_hours += Number(assignment_totals[key]);
         });

         Object.keys(request_totals).forEach((key: string) => {
            total_hours += Number(request_totals[key]);
         });

         setData(total_hours);
         setIsLoading(false);
      }

      getTotals();
   }, []);

   return { data, isLoading };
}

export type RawProjectTotals = {
   breakdown_data: [
      {
         id: string;
         name: string;
         daily_request_totals: {
            [key: string]: {
               count: number;
            };
         };
         daily_ass_totals: {
            [key: string]: {
               count: number;
            };
         };
      },
   ];
};

export function useGetRawProjectTotalsQuery(id: string) {
   const [data, setData] = React.useState<RawProjectTotals | null>(null);
   const [isLoading, setIsLoading] = React.useState(true);

   React.useEffect(() => {
      async function getTotals() {
         setIsLoading(true);
         setData(null);

         const data = (
            await TotalsStore.getProjectTotals(id, {
               getAssignments: "true",
               getRequests: "true",
               totalsUnit: "people",
               viewBy: "job-title",
            }).payload
         ).data;

         setData(data as unknown as RawProjectTotals);
         setIsLoading(false);
      }

      getTotals();
   }, [id]);

   return { data, isLoading };
}

export async function streamGroupOptions(): Promise<Array<{ id: string; label: string }>> {
   const groups = [];
   const stream = await GroupStore.findGroupsStream().stream;
   for await (const { id, name } of stream) {
      groups.push({ id, label: name });
   }

   return groups;
}

import { Button, Modal, Form, Spinner, Typography, useI18nContext } from "@procore/core-react";
import React, { useState, useCallback } from "react";
import { ErrorBanner } from "../../error-banner";
import type { FormikProps } from "formik";
import * as yup from "yup";
import type { CreateSavedViewPayload } from "@laborchart-modules/lc-core-api/dist/api/saved-views/create-saved-view";
import type { SelectValue, CustomField } from "@/react/prop-types";
import { SavedViewStore } from "@/stores/saved-view-store.core";
import { useToastAlertContext } from "@procore/toast-alert";
import type { TableApi, DataTableConfig } from "@procore/data-table";
import type { SavedView } from "@laborchart-modules/common";
import { streamPeopleOptions } from "../../common/queries/queries";
import { streamGroupOptions } from "../../tearsheets/project/queries";

export type SavedViewModalProps = {
   tableApi?: TableApi;
   conversionFunction: (
      config: DataTableConfig,
      columnHeadersMap: { [key: string]: string },
      filterNameMaps: { [key: string]: string },
      filterFieldMap: { [key: string]: string },
      filterRendererMap: { [key: string]: string },
      customFields?: CustomField[],
   ) => Partial<SavedView>;
   page: string;
   customFields?: CustomField[];
   columnHeadersMap: { [key: string]: string };
   filterNameMaps: { [key: string]: string };
   filterFieldMap: { [key: string]: string };
   filterRendererMap: { [key: string]: string };
   configKey: string;
   searchString?: string;
   groupId: string;
   groupOptions?: Array<{ id: string; label: string }>;
};
export const CreateSavedViewModal = (props: SavedViewModalProps) => {
   const {
      tableApi,
      conversionFunction,
      page,
      columnHeadersMap,
      filterNameMaps,
      filterFieldMap,
      filterRendererMap,
      searchString,
      customFields,
      groupId,
      groupOptions,
      configKey,
   } = props;
   const I18n = useI18nContext();
   const { showToast } = useToastAlertContext();
   const [loading, setLoading] = useState<boolean>(true);
   const [people, setPeople] = useState<any>([]);
   const [groups, setGroups] = useState<any>([]);
   const [open, setOpen] = useState(false);
   const [error, setError] = useState(false);

   const handleOpen = useCallback(async () => {
      setOpen(true);
      const [people, groups] = await Promise.all([
         await streamPeopleOptions(groupId),
         groupOptions ?? streamGroupOptions(),
      ]);
      setPeople(people);
      setGroups(groups);
      setLoading(false);
   }, [tableApi, groupId]);

   const handleSubmit = async (formValues: any) => {
      try {
         const localStorageConfig = JSON.parse(localStorage.getItem(configKey) ?? "");
         const saved_view = {
            ...conversionFunction(
               localStorageConfig,
               columnHeadersMap,
               filterNameMaps,
               filterFieldMap,
               filterRendererMap,
               customFields,
            ),
            page: page,
         };
         const shared_with_ids = formValues.shared_with.map((value: SelectValue<string>) => {
            return value.id;
         });
         const payload = {
            ...saved_view,
            share_type: formValues.share_type.value,
            name: formValues.name,
            group_ids: formValues.share_type?.value == "groups" ? shared_with_ids : [],
            shared_with_people_ids: formValues.share_type?.value == "people" ? shared_with_ids : [],
            search: searchString,
         };
         await SavedViewStore.createSavedView(payload as CreateSavedViewPayload).payload;
         setOpen(false);
         showToast.success(I18n.t("views.company.workforce_planning.saved_views.success_toast"));
      } catch (err) {
         setError(true);
      }
   };

   return (
      <>
         <Button variant="tertiary" onClick={handleOpen}>
            {I18n.t("views.company.workforce_planning.saved_views.save_view")}
         </Button>
         <Modal open={open} onClose={() => setOpen(false)} width="md">
            <Spinner loading={loading}>
               <Form
                  view="create"
                  onSubmit={handleSubmit}
                  validationSchema={yup.object().shape({
                     name: yup
                        .string()
                        .required(
                           I18n.t("views.company.workforce_planning.validations.required_field"),
                        ),
                     share_type: yup
                        .object()
                        .required(
                           I18n.t("views.company.workforce_planning.validations.required_field"),
                        ),
                  })}
               >
                  {
                     /* istanbul ignore next */
                     /* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */
                     (formikProps: FormikProps<any>) => {
                        const { share_type } = formikProps.values;
                        return (
                           <Form.Form>
                              <Modal.Header onClose={() => setOpen(false)}>
                                 {I18n.t("views.company.workforce_planning.saved_views.save_view")}
                              </Modal.Header>
                              <Modal.Body>
                                 {error && (
                                    <ErrorBanner
                                       title={I18n.t("views.company.workforce_planning.error")}
                                       content={I18n.t(
                                          "views.company.workforce_planning.save_view.modals.create_error",
                                       )}
                                    />
                                 )}
                                 <Modal.Section>
                                    <Typography>
                                       {I18n.t(
                                          "views.company.workforce_planning.saved_views.info_text",
                                       )}
                                    </Typography>
                                    <Form.Row>
                                       <Form.Field
                                          name="name"
                                          label={I18n.t(
                                             "views.company.workforce_planning.saved_views.view_name",
                                          )}
                                          required
                                          colWidth={12}
                                       />
                                    </Form.Row>
                                    <Form.Row>
                                       <Form.RadioButtons
                                          name="share_type"
                                          required
                                          label={I18n.t(
                                             "views.company.workforce_planning.saved_views.share_type",
                                          )}
                                          options={[
                                             {
                                                id: 0,
                                                value: "private",
                                                label: I18n.t(
                                                   "views.company.workforce_planning.saved_views.private",
                                                ),
                                             },
                                             {
                                                id: 1,
                                                value: "people",
                                                label: I18n.t(
                                                   "views.company.workforce_planning.saved_views.individuals",
                                                ),
                                             },
                                             {
                                                id: 2,
                                                value: "groups",
                                                label: I18n.t(
                                                   "views.company.workforce_planning.saved_views.groups",
                                                ),
                                             },
                                          ]}
                                          /* @ts-expect-error Core React Form.RadioButtons needs to add this as a valid prop */
                                          onChange={() =>
                                             formikProps.setFieldValue("shared_with", [])
                                          }
                                       />
                                       <Form.MultiSelect
                                          colStart={7}
                                          name="shared_with"
                                          label={I18n.t(
                                             "views.company.workforce_planning.saved_views.share_with",
                                          )}
                                          options={
                                             share_type?.value == "people"
                                                ? (people as any)
                                                : (groups as any)
                                          }
                                          disabled={share_type?.value == "private"}
                                       />
                                    </Form.Row>
                                 </Modal.Section>
                              </Modal.Body>
                              <Modal.Footer>
                                 <Modal.FooterButtons>
                                    <Button variant="tertiary" onClick={() => setOpen(false)}>
                                       {I18n.t("views.company.workforce_planning.cancel")}
                                    </Button>
                                    <Button variant="primary" type="submit">
                                       {I18n.t("views.company.workforce_planning.save")}
                                    </Button>
                                 </Modal.FooterButtons>
                              </Modal.Footer>
                           </Form.Form>
                        );
                     }
                  }
               </Form>
            </Spinner>
         </Modal>
      </>
   );
};

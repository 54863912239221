import { Button, Modal } from "@procore/core-react";
import type { ReactNode } from "react";
import React from "react";
import { Fragment, useState } from "react";
import type { Root } from "react-dom/client";
import { createRoot } from "react-dom/client";

interface ModalInput {
   title: string;
   body?: string | ReactNode;
   action: (modalState?: any) => void;
}

export enum GanttModalType {
   DeleteConfirmation,
   ExportView,
}

export const showGanttModal = ({
   title,
   body,
   action,
   type,
   modalId,
}: ModalInput & { type: GanttModalType; modalId: string }) => {
   let modalContainer: (Element & { _reactRoot?: Root }) | null = document.querySelector(
      `#${modalId}`,
   );

   if (!modalContainer) {
      modalContainer = document.createElement("div");
      modalContainer.setAttribute("id", modalId);
      document.body.appendChild(modalContainer);
   }

   modalContainer._reactRoot?.unmount();
   modalContainer._reactRoot = createRoot(modalContainer);

   if (type === GanttModalType.DeleteConfirmation) {
      modalContainer._reactRoot.render(
         <DeleteConfirmationModal title={title} body={body} action={action} />,
      );
   } else if (type === GanttModalType.ExportView) {
      modalContainer._reactRoot.render(
         <ViewExportModal title={title} body={body} action={action} />,
      );
   }
};

const DeleteConfirmationModal = ({ title, body, action }: ModalInput) => {
   const [open, setOpen] = useState(true);

   return (
      <Fragment>
         <Modal open={open} onClickOverlay={() => setOpen(false)} onClose={() => setOpen(false)}>
            <Modal.Header onClose={() => setOpen(false)}>{title ?? ""}</Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
               <Modal.FooterButtons>
                  <Button variant="tertiary" onClick={() => setOpen(false)}>
                     Cancel
                  </Button>
                  <Button
                     variant="primary"
                     onClick={() => {
                        setOpen(false);
                        if (action) action();
                     }}
                  >
                     Delete
                  </Button>
               </Modal.FooterButtons>
            </Modal.Footer>
         </Modal>
      </Fragment>
   );
};

// EXAMPLE OF HOW YOU MIGHT INVOKE THIS  ViewExportModal from the gantt-container.tsx file

// showGanttModal({
//    type: GanttModalType.ExportView,
//    title: "Export View",
//    modalId: "gantt-export-view-modal-container",
//    action: (modalState) => {
//       ganttRef.current?.instance.features.pdfExport.export(modalState);
//       ganttRef.current?.instance.features.csvExport.export(modalState);
//    },
// })
const ViewExportModal = ({ title, action }: ModalInput) => {
   const [open, setOpen] = useState(true);
   // const [exportType, setExportType] = useState<GanttModalType>();

   return (
      <Fragment>
         <Modal open={open} onClickOverlay={() => setOpen(false)} onClose={() => setOpen(false)}>
            <Modal.Header onClose={() => setOpen(false)}>{title}</Modal.Header>
            <Modal.Body>
               {/* You can pass in the modal content through "body" prop of the showGanttModal function, or leave that prop undefined and just make your
               own modal body here so that it can use local state from its own component */}
            </Modal.Body>
            <Modal.Footer>
               <Modal.FooterButtons>
                  <Button variant="tertiary" onClick={() => setOpen(false)}>
                     Cancel
                  </Button>
                  <Button
                     variant="primary"
                     onClick={() => {
                        setOpen(false);
                        if (action)
                           action({
                              // whatever is passed in to this action is the config for the pdfExport.export method
                           });
                     }}
                  >
                     Export
                  </Button>
               </Modal.FooterButtons>
            </Modal.Footer>
         </Modal>
      </Fragment>
   );
};

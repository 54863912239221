import type { FormSelectOption } from "@/react/prop-types";
import type {
   CommonFormValuesForRequestAndAssignment,
   CommonPayloadForRequestAndAssignment,
} from "../types";

export type AssignmentFormValues = CommonFormValuesForRequestAndAssignment & {
   resource: FormSelectOption<string> | null;
};

export type Assignment = CommonPayloadForRequestAndAssignment & {
   resource_id: string;
};

export enum AssignmentFields {
   ALLOCATION = "assignment_by_allocation",
   TIME = "assignment_by_time",
}

export type AssignmentFormattedTypes = { assignmentId: string; formattedAssignmentDetails: string };

import {
   decrementDate,
   getAttachedDate,
   getDetachedDay,
   incrementDate,
} from "@laborchart-modules/common/dist/datetime";
import type {
   WorkDay,
   WorkDaySelection,
} from "../components/modals/editors/work-day-editor-element/work-day-editor-element";

/*
   This function converts postgres time format of "HH:MM:SS" or "HH:MM" into a float representation.
   For example, 1:30 PM would be converted to 13.5
*/
export function timeStringToFloat(timeVal: string) {
   // eslint-disable-next-line prefer-const
   let [hours, minutes] = String(timeVal).split(":");
   if (String(minutes).length == 1) minutes = minutes + "0";
   const minutesBase10 = Math.round((Number(minutes) * 100) / 60);
   return parseFloat(`${hours}.${minutesBase10}`);
}

/*
   Function to round minutes to the nearest 15
*/
export function roundMinutes(date: Date) {
   const minutes = date.getMinutes();
   const roundedMinutes = Math.round(minutes / 15) * 15;
   date.setMinutes(roundedMinutes);
   date.setSeconds(0); // Set seconds to 0 as per your format requirement
   return date;
}

/*
   This function converts a postgres date value of the format "YYYY-MM-DD" into a javascript date object.
   If you have a postgres timestamp or some other datatype that includes a time, then you should be able
   to convert that postgres value into a javascript date object by simply passing it into the Date constructor.
   But if you're actually working with a postgres type of "date" and it looks like "YYYY-MM-DD" then you can't
   pass it directly into the Date constructor. You have to use this function to convert it first.
*/
export function postgresDateToJavascriptDate(date: string) {
   const [year, month, day] = date.split("-");
   return new Date(Number(year), Number(month) - 1, Number(day));
}

export function getNextFirstWorkDay({
   fromDetachedDay,
   workDays,
}: {
   fromDetachedDay: number;
   workDays: WorkDaySelection;
}): number | null {
   const date = getAttachedDate(fromDetachedDay);
   const nextWorkDate = getNextFirstWorkDate({
      fromDetachedDate: date,
      workDays,
   });
   return nextWorkDate != null ? getDetachedDay(nextWorkDate) : null;
}

export function getNextFirstWorkDate({
   fromDetachedDate,
   workDays,
}: {
   fromDetachedDate: Date;
   workDays: WorkDaySelection;
}): Date | null {
   const workDaysArray = [0, 1, 2, 3, 4, 5, 6];
   const firstWorkDay = workDaysArray.find((dayIndex) => workDays[dayIndex as WorkDay] === true);
   if (firstWorkDay == null) return null;

   const fromDayOfWeek = fromDetachedDate.getDay();
   for (const day of workDaysArray) {
      if ((fromDayOfWeek + day + 1) % 7 == firstWorkDay) {
         return incrementDate(fromDetachedDate, day + 1);
      }
   }
   return null;
}

export function getPreviousLastWorkDay({
   fromDetachedDay,
   workDays,
}: {
   fromDetachedDay: number;
   workDays: WorkDaySelection;
}): number | null {
   const date = getAttachedDate(fromDetachedDay);
   const nextWorkDate = getPreviousLastWorkDate({
      fromDetachedDate: date,
      workDays,
   });
   return nextWorkDate != null ? getDetachedDay(nextWorkDate) : null;
}

export function getPreviousLastWorkDate({
   fromDetachedDate,
   workDays,
}: {
   fromDetachedDate: Date;
   workDays: WorkDaySelection;
}): Date | null {
   const workDaysArray = [6, 5, 4, 3, 2, 1, 0];
   const firstWorkDay = workDaysArray.find((dayIndex) => workDays[dayIndex as WorkDay] === true);
   if (firstWorkDay == null) return null;

   const fromDayOfWeek = fromDetachedDate.getDay();
   for (const day of workDaysArray.reverse()) {
      if (((fromDayOfWeek - (day + 1)) % 7) + 7 == firstWorkDay) {
         return decrementDate(fromDetachedDate, day + 1);
      }
   }
   return null;
}

export function getLastWorkDate({
   fromDate,
   workDays,
}: {
   fromDate: Date;
   workDays: WorkDaySelection;
}): Date | null {
   const workDaysArray = [0, 1, 2, 3, 4, 5, 6];
   for (const workDay of workDaysArray) {
      const decrementedDate = decrementDate(fromDate, workDay + 1);
      if (workDays[decrementedDate.getDay() as WorkDay] === true) {
         return decrementedDate;
      }
   }
   return null;
}

export function getNextWorkDate({
   fromDate,
   workDays,
}: {
   fromDate: Date;
   workDays: WorkDaySelection;
}): Date | null {
   const workDaysArray = [0, 1, 2, 3, 4, 5, 6];
   for (const workDay of workDaysArray) {
      const incrementedDate = incrementDate(fromDate, workDay + 1);
      if (workDays[incrementedDate.getDay() as WorkDay] === true) {
         return incrementedDate;
      }
   }
   return null;
}

/**
 * Business Logic for End Day in N Weeks:
 * Pick the day that is exactly plus N weeks from today.
 * The calculated end day should be the LAST valid work day
 * of the calendar week BEFORE that day.
 */
export function getLastWorkDateInNumberOfWeeks({
   numberOfWeeks,
   startDate,
   workDays,
}: {
   numberOfWeeks: number;
   startDate: Date;
   workDays: WorkDaySelection;
}): Date {
   // Get the last work day of any given week.
   const lastWorkDayEntry =
      Object.entries(workDays)
         .reverse()
         .find(([_, isWorkDay]) => isWorkDay) ?? null;
   const lastWorkDay = lastWorkDayEntry ? Number(lastWorkDayEntry[0]) : 6;

   const calculatedWeekDay = incrementDate(startDate, 7 * numberOfWeeks).getDay();

   // Get the offset needed to subtract from the calculatedWeekDay to get the lastWorkDay.
   const offset =
      [0, 1, 2, 3, 4, 5, 6].find((offset) => (calculatedWeekDay - offset + 7) % 7 == lastWorkDay) ??
      0;
   return incrementDate(startDate, 7 * numberOfWeeks - offset);
}

/** Mutates a date to account for timezone. */
export function mutateForTimezone(date: Date) {
   const timezoneOffset = date.getTimezoneOffset();
   // If the user's timezone offset is negative, don't shift the date back. This solves some issues for UK customers.
   if (timezoneOffset > 0) {
      date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
   }
}
/**
 * Converts a 24-hour time format to a 12-hour format with an AM or PM suffix.
 * @param {string} time - Time in "HH:mm:ss" format (24-hour format).
 * @returns {string | null} - The converted time in 12-hour format with AM/PM suffix, or `null` if the input is invalid.
 */
export const convertTo12HourFormat = (time: string): string | null => {
   // Check if the input is a string
   if (typeof time !== "string") {
      return null;
   }

   // Split the time string into hours, minutes, and seconds, and convert them to numbers
   const [hours, minutes, seconds] = time.split(":").map(Number);

   // Validate if the split values are valid numbers
   if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      return null;
   }

   // Determine if the time is AM or PM based on the hour
   const suffix = hours >= 12 ? "PM" : "AM";

   // Convert the hour to 12-hour format (handle 0 or 12 edge cases)
   const hours12 = hours % 12 || 12;

   // Return the formatted time in 12-hour format with leading zero for minutes
   return `${hours12}:${minutes.toString().padStart(2, "0")} ${suffix}`;
};

export const ProjectDetailAlertTokens = [
   {
      name: "Assignee's Name",
      subject_1_key: "resource_id",
      subject_1_type: "assignments",
      subject_2_key: "name",
      subject_2_type: "people",
   },
   {
      name: "Assignee's Email",
      subject_1_key: "resource_id",
      subject_1_type: "assignments",
      subject_2_key: "email",
      subject_2_type: "people",
   },
   {
      name: "Assignee's Phone",
      subject_1_key: "resource_id",
      subject_1_type: "assignments",
      subject_2_key: "phone",
      subject_2_type: "people",
   },
   {
      name: "Assignee's Job Title",
      subject_1_key: "resource_id",
      subject_1_type: "assignments",
      subject_2_key: "position_id",
      subject_2_type: "people",
      subject_3_key: "name",
      subject_3_type: "positions",
   },
   {
      name: "Assignment Start Date",
      subject_1_key: "start_day",
      subject_1_type: "assignments",
   },
   {
      name: "Assignment End Date",
      subject_1_key: "end_day",
      subject_1_type: "assignments",
   },
   {
      name: "Assignment Start Time",
      subject_1_key: "start_time",
      subject_1_type: "assignments",
   },
   {
      name: "Assignment End Time",
      subject_1_key: "end_time",
      subject_1_type: "assignments",
   },
   {
      name: "Project Name",
      subject_1_key: "name",
      subject_1_type: "projects",
   },
   {
      name: "Project Address",
      subject_1_key: "address_1",
      subject_1_type: "projects",
   },
   {
      name: "Project Address 2",
      subject_1_key: "address_2",
      subject_1_type: "projects",
   },
   {
      name: "Project City",
      subject_1_key: "city_town",
      subject_1_type: "projects",
   },
   {
      name: "Project State",
      subject_1_key: "state_province",
      subject_1_type: "projects",
   },
   {
      name: "Project Postal Code",
      subject_1_key: "zipcode",
      subject_1_type: "projects",
   },
   {
      name: "Project Country",
      subject_1_key: "country",
      subject_1_type: "projects",
   },
   {
      name: "Project Number",
      subject_1_key: "job_number",
      subject_1_type: "projects",
   },
];

export const scheduleAlertHourOptions = [
   {
      id: 1,
      label: "1",
      value: 1,
   },
   {
      id: 2,
      label: "2",
      value: 2,
   },
   {
      id: 3,
      label: "3",
      value: 3,
   },
   {
      id: 4,
      label: "4",
      value: 4,
   },
   {
      id: 5,
      label: "5",
      value: 5,
   },
   {
      id: 6,
      label: "6",
      value: 6,
   },
   {
      id: 7,
      label: "7",
      value: 7,
   },
   {
      id: 8,
      label: "8",
      value: 8,
   },
   {
      id: 9,
      label: "9",
      value: 9,
   },
   {
      id: 10,
      label: "10",
      value: 10,
   },
   {
      id: 11,
      label: "11",
      value: 11,
   },
   {
      id: 12,
      label: "12",
      value: 12,
   },
];

export const scheduleAlertMinuteOptions = [
   {
      id: 0,
      label: "00",
      value: 0,
   },
   {
      id: 15,
      label: "15",
      value: 0.25,
   },
   {
      id: 30,
      label: "30",
      value: 0.5,
   },
   {
      id: 45,
      label: "45",
      value: 0.75,
   },
];

import React from "react";
import { Card, Box, FlexList, Title, H2, Form } from "@procore/core-react";
import { formatOptions } from "@/react/shared/helper";
import { AssignmentSwitch } from "../assignment/assignment-switch";
import { WorkDaysCheckboxes } from "../../labor-plans/work-days-checkboxes";
import type { ScheduleCardProps } from "../types";
import { timeOptions } from "@/lib/utils/timezones";

export const ScheduleCard: React.FC<ScheduleCardProps> = ({
   I18n,
   setFieldValue,
   assignmentCreationSupportData,
   selectedWorkDays,
   setSelectedWorkDays,
   assignmentByTime,
   setAssignmentByTime,
   assignmentByAllocation,
   setAssignmentByAllocation,
   canViewAllStatuses,
   currentAssignments = null,
}) => (
   <Card style={{ marginBottom: "10px" }}>
      <Box padding="md">
         {currentAssignments}
         <FlexList justifyContent="space-between" marginBottom="sm">
            <Title>
               <Title.Text>
                  <H2>{I18n.t("views.company.workforce_planning.schedule")}</H2>
               </Title.Text>
            </Title>
         </FlexList>
         <Form.Form>
            <Form.Row>
               <Form.DateSelect
                  colStart={1}
                  colWidth={3}
                  name="start_date"
                  label={I18n.t("views.company.workforce_planning.assignment.start_date")}
                  required
               />
               <Form.DateSelect
                  colStart={4}
                  colWidth={3}
                  name="end_date"
                  label={I18n.t("views.company.workforce_planning.assignment.end_date")}
                  required
               />
               {assignmentByTime && (
                  <>
                     <Form.Select
                        colStart={7}
                        colWidth={3}
                        name="start_time"
                        label={I18n.t("views.company.workforce_planning.assignment.start_time")}
                        options={timeOptions}
                        onSearch={false}
                        required
                        placeholder={I18n.t("views.company.workforce_planning.select_option")}
                     />
                     <Form.Select
                        colStart={10}
                        colWidth={3}
                        name="end_time"
                        label={I18n.t("views.company.workforce_planning.assignment.end_time")}
                        options={timeOptions}
                        onSearch={false}
                        required
                        placeholder={I18n.t("views.company.workforce_planning.select_option")}
                     />
                  </>
               )}
               {assignmentByAllocation && (
                  <Form.Number
                     colStart={7}
                     colWidth={3}
                     name="percent_allocated"
                     label={I18n.t("views.company.workforce_planning.assignment.percent_allocated")}
                     required
                     placeholder={I18n.t("views.company.workforce_planning.enter_value")}
                  />
               )}
            </Form.Row>
            <Form.Row>
               <Form.Field
                  name="assignment_by_time"
                  as={AssignmentSwitch}
                  colStart={1}
                  colWidth={6}
                  inlineLabel={I18n.t(
                     "views.company.workforce_planning.assignment.assignment_by_time",
                  )}
                  setValues={setFieldValue}
                  value={assignmentByTime}
                  toggleAssignmentByAllocation={setAssignmentByAllocation}
                  toggleAssignmentByTime={setAssignmentByTime}
               />
               <Form.Field
                  name="assignment_by_allocation"
                  as={AssignmentSwitch}
                  colStart={7}
                  colWidth={6}
                  inlineLabel={I18n.t(
                     "views.company.workforce_planning.assignment.assignment_by_allocation",
                  )}
                  setValues={setFieldValue}
                  value={assignmentByAllocation}
                  toggleAssignmentByAllocation={setAssignmentByAllocation}
                  toggleAssignmentByTime={setAssignmentByTime}
               />
            </Form.Row>
            <Form.Row>
               <Form.Field
                  colStart={1}
                  colWidth={12}
                  as={WorkDaysCheckboxes}
                  label={I18n.t("views.company.workforce_planning.work_days")}
                  required
                  name="work_days"
                  setSelectedWorkDays={setSelectedWorkDays}
                  value={selectedWorkDays}
                  setValues={setFieldValue}
               />
            </Form.Row>
            {!canViewAllStatuses && (
               <Form.Row>
                  <Form.Select
                     colStart={1}
                     colWidth={6}
                     name="status"
                     label={I18n.t("views.company.workforce_planning.projects.status")}
                     placeholder={I18n.t("views.company.workforce_planning.select_option")}
                     options={formatOptions(
                        assignmentCreationSupportData?.data.status_options ?? [],
                     )}
                     required
                  />
               </Form.Row>
            )}
         </Form.Form>
      </Box>
   </Card>
);

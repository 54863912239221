import React from "react";
import { useGetProjectQrProfile } from "./queries";
import {
   Avatar,
   Box,
   Card,
   Grid,
   H2,
   Link,
   Pill,
   Spinner,
   Typography,
   useI18nContext,
} from "@procore/core-react";
import { ColorSwab } from "../data-table/ColorSelectComponent/ColorSelectColumn";

import styles from "./styles.scss";
import classnames from "classnames";
import { getInitials } from "../tearsheets/project/helpers";
import {
   findIndexStatus,
   formatCustomFieldValue,
   projectStatusFilterOptions,
   formatCurrency,
} from "./helpers";
import type { ProjectQrRolesProps } from "../tearsheets/project/types";
import { formatDateTime } from "@procore/globalization-toolkit";
import { convertTo12HourFormat } from "@/lib/utils/date-2";
import type { CustomField } from "@laborchart-modules/common";

const cx = classnames.bind(styles);

type ProjectQrProfileProps = {
   projectQrId: string;
   companyQrId: string;
};

export type ProjectQrCustomField = CustomField & { value: string | number | boolean };

export const ProjectQrProfile: React.FC<ProjectQrProfileProps> = ({ projectQrId, companyQrId }) => {
   const I18n = useI18nContext();
   const { data, isLoading } = useGetProjectQrProfile(projectQrId, companyQrId);
   const { name, roles, tags } = data?.data || {};
   const {
      job_number,
      status,
      color,
      group_names,
      address_1,
      city_town,
      state_province,
      zipcode,
      country,
      percent_complete,
      start_date,
      est_end_date,
      daily_start_time,
      daily_end_time,
      bid_rate,
      custom_fields,
   } = data?.data?.fields || {};
   const dateTimeOptions = { locale: "en-US", timeZone: "UTC" };

   return (
      <React.Fragment>
         <Spinner loading={isLoading}>
            <Box padding="md" className={cx("container-qr-profiles")}>
               <Card style={{ marginBottom: "5px" }}>
                  <Box padding="md" display="flex" alignItems="center">
                     <ColorSwab
                        color={color}
                        shape="circle"
                        label={name}
                        height="24px"
                        width="24px"
                     />{" "}
                     <H2>{name}</H2>
                  </Box>
               </Card>
               <Card style={{ marginBottom: "15px" }}>
                  <Box padding="md">
                     <Grid>
                        <Grid.Row>
                           <Typography intent="h3">
                              {I18n.t("views.company.workforce_planning.projects.job_number")}
                           </Typography>
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: "5px" }}>
                           <Typography intent="body">{job_number}</Typography>
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: "10px" }}>
                           <Typography intent="h3">
                              {I18n.t("views.company.workforce_planning.projects.status")}
                           </Typography>
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: "5px" }}>
                           {status && (
                              <Pill
                                 color={projectStatusFilterOptions[findIndexStatus(status)].color}
                              >
                                 {I18n.t(
                                    `views.company.workforce_planning.${
                                       projectStatusFilterOptions[findIndexStatus(status)].label
                                    }`,
                                 )}
                              </Pill>
                           )}
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: "10px" }}>
                           <Typography intent="h3">
                              {I18n.t("views.company.workforce_planning.projects.groups")}
                           </Typography>
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: "5px" }}>
                           {group_names?.map((group: string, index: number) => (
                              <Typography key={`${group}-${index}`} intent="body">
                                 {group}
                                 {index < group_names.length - 1 && <>{", "}&nbsp;</>}
                              </Typography>
                           ))}
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: "10px" }}>
                           <Typography intent="h3">
                              {I18n.t("views.company.workforce_planning.dates")}
                           </Typography>
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: "5px" }}>
                           <Typography intent="body">{`${
                              start_date
                                 ? formatDateTime(new Date(start_date), dateTimeOptions)
                                 : ""
                           } - ${
                              est_end_date
                                 ? formatDateTime(new Date(est_end_date), dateTimeOptions)
                                 : ""
                           }`}</Typography>
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: "10px" }}>
                           <Typography intent="h3">
                              {I18n.t("views.company.workforce_planning.daily_start_end_times")}
                           </Typography>
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: "5px" }}>
                           <Typography intent="body">{`${convertTo12HourFormat(
                              daily_start_time,
                           )} - ${convertTo12HourFormat(daily_end_time)}`}</Typography>
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: "10px" }}>
                           <Typography intent="h3">
                              {I18n.t("views.company.workforce_planning.projects.address")}
                           </Typography>
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: "5px" }}>
                           <Typography intent="body">{`${address_1}`}</Typography>
                           <Typography intent="body">{`${city_town}, ${state_province} ${zipcode}, ${country}`}</Typography>
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: "10px" }}>
                           <Typography intent="h3">
                              {I18n.t("views.company.workforce_planning.estimated_average_rate")}
                           </Typography>
                        </Grid.Row>
                        {bid_rate && (
                           <Grid.Row style={{ marginTop: "5px" }}>
                              <Typography intent="body">
                                 {formatCurrency(bid_rate, I18n)}
                              </Typography>
                           </Grid.Row>
                        )}
                        <Grid.Row style={{ marginTop: "10px" }}>
                           <Typography intent="h3">
                              {I18n.t("views.company.workforce_planning.projects.percent_complete")}
                           </Typography>
                        </Grid.Row>
                        {percent_complete && (
                           <Grid.Row style={{ marginTop: "5px" }}>
                              <Typography intent="body">{`${percent_complete}%`}</Typography>
                           </Grid.Row>
                        )}
                        <Grid.Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                           <Typography intent="h3">
                              {I18n.t("views.company.workforce_planning.projects.tags")}
                           </Typography>
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: "5px" }}>
                           {tags?.map(
                              (tag: { id: string; color: string; abbreviation: string }) => (
                                 <ColorSwab
                                    key={tag.id}
                                    color={tag.color}
                                    shape="pill"
                                    label={tag.abbreviation}
                                 />
                              ),
                           )}
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                           <Typography intent="h3">
                              {I18n.t(
                                 "views.company.workforce_planning.project_details_roles.title",
                              )}
                           </Typography>
                        </Grid.Row>
                        {roles?.map((role: ProjectQrRolesProps) => (
                           <Grid.Row key={role?.id} style={{ marginBottom: "10px" }}>
                              <Box
                                 style={{ display: "flex", marginRight: "10px", marginTop: "5px" }}
                              >
                                 <Avatar>
                                    <Avatar.Label>
                                       {getInitials(
                                          `${role?.assignee.name.first} ${role.assignee.name.last}`,
                                       )}
                                    </Avatar.Label>
                                 </Avatar>
                              </Box>
                              <Box
                                 style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "start",
                                 }}
                              >
                                 <Typography intent="h3">
                                    {`${role?.assignee.name.first} ${role.assignee.name.last}`}
                                 </Typography>
                                 <Typography color="gray45" intent="body">
                                    {role?.position.name}
                                 </Typography>
                                 <Link style={{ fontSize: "14px" }}>{role?.assignee.email}</Link>
                                 <Link style={{ fontSize: "14px" }}>{role?.assignee.phone}</Link>
                              </Box>
                           </Grid.Row>
                        ))}
                        {custom_fields?.map((fields: ProjectQrCustomField) => (
                           <Grid.Row key={fields?.id} style={{ marginBottom: "10px" }}>
                              <Box
                                 style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "start",
                                 }}
                              >
                                 <Typography intent="h3">{`${fields?.name}`}</Typography>
                                 <Typography intent="body">
                                    {formatCustomFieldValue(fields, I18n)}
                                 </Typography>
                              </Box>
                           </Grid.Row>
                        ))}
                     </Grid>
                  </Box>
               </Card>
            </Box>
         </Spinner>
      </React.Fragment>
   );
};

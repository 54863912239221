import type { PillColor } from "@procore/core-react";
import type { ProjectQrCustomField } from "./project-qr-profile";
import { getAttachedDate } from "@laborchart-modules/common/dist/datetime";
import { CurrencyFormatter, DateTimeFormatter } from "@procore/globalization-toolkit";
import { CustomFieldType } from "@laborchart-modules/common";

export const projectStatusFilterOptions: Array<{
   id: number;
   value: string;
   label: string;
   color: PillColor;
}> = [
   {
      id: 1,
      value: "active",
      label: "active",
      color: "green",
   },
   {
      id: 2,
      value: "inactive",
      label: "inactive",
      color: "gray",
   },
   {
      id: 3,
      value: "pending",
      label: "pending",
      color: "yellow",
   },
];

export const findIndexStatus = (value?: string): number => {
   if (!value) {
      console.error("The value is not valid");
      return -1;
   }
   const index = projectStatusFilterOptions.findIndex((option) => option.value === value);
   return index;
};

export function formatCurrency(value: number, I18n: any): string {
   const currencyOptions = { locale: I18n.currentLocale(), currencyIsoCode: "USD" };
   const currencyFormatter = new CurrencyFormatter(currencyOptions);
   return currencyFormatter.formatCurrency(value);
}

export function formatCustomFieldValue(field: ProjectQrCustomField, I18n: any): any {
   const type = field.type;
   const dateTimeOptions = {
      locale: I18n.currentLocale,
      timeZone: "UTC",
      dateStyle: "short",
   };
   const dateFormatter = new DateTimeFormatter(dateTimeOptions as any);

   switch (type) {
      case CustomFieldType.NUMBER:
         return field.value.toString();
      case CustomFieldType.DATE: {
         const date = getAttachedDate(field.value as number);
         return dateFormatter.formatDateTime(date);
      }
      case CustomFieldType.BOOL:
         return field.value
            ? I18n.t("views.company.workforce_planning.boolean_values.yes_value")
            : I18n.t("views.company.workforce_planning.boolean_values.no_value");
      case CustomFieldType.CURRENCY:
         return formatCurrency(field.value as number, I18n);
      case CustomFieldType.SELECT:
         return field.value;
      default:
         return field.value;
   }
}

import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import type { SavedView } from "@laborchart-modules/common";
import type { IServerFilter, DataTableConfig, ColumnDefinition } from "@procore/data-table";
import { MultiSelectFilterRenderer } from "@procore/data-table";
import type { ChipFilter } from "@laborchart-modules/common/dist/postgres/schemas/common/custom-json";
import { getDetachedDay, getAttachedDate } from "@laborchart-modules/common/dist/datetime";
import { findCustomField } from "@/react/shared/custom-field-utils";
import { CustomFieldType } from "@laborchart-modules/common/dist/rethink/schemas/common/custom-field-type";

import type {
   ColumnMap,
   CommonStyleProps,
   CustomField,
   FormattedOption,
   PersonName,
   RawOption,
} from "../prop-types";

import { requestContext } from "@/stores/common/request-context";
import type { IntegratedField, QRCodeDownloadURLProps } from "@/react/prop-types";
import MultiSelectPillFilter from "../components/data-table/custom-filters/multiselect-pill-filter";
import { StatusFilter } from "../components/data-table/custom-filters/status-filter";
import { DateFilter } from "../components/data-table/custom-filters/date-filter";
import { NumericValueFilter } from "../components/data-table/custom-filters/numeric-value-filter";
import { HidePastTimeOffFilter } from "../components/time-off-list/custom-filters/hide-past-time-off-filter";
import { TextFilter } from "../components/data-table/custom-filters/text-filter";
import { BooleanFilter } from "../components/data-table/custom-filters/bool-filter";
import type { ProjectDetailsFields } from "../components/tearsheets/project/types";
import type { AppliedRoleOption } from "@laborchart-modules/lc-core-api/dist/api/projects/get-applied-role-options";
import { generateProjectRoleColumnDefinitions } from "../components/project-list/helpers";
import { checkFilterIsRoles } from "../components/data-table/helpers";
//function to truncate text
export const truncateText = (text: string, maxLength: number) => {
   if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
   }
   return text;
};

// Function to sort an array by a given key in the specified order (default is ascending)
// can be extended to handle more complex sorting logic
export const sortArray = (arrayToSort: any[], key: string, order: Order = Order.ASCENDING) => {
   return [...arrayToSort].sort((a, b) => {
      const valueA = a[key];
      const valueB = b[key];

      if (order === Order.DESCENDING) {
         return valueB - valueA;
      } else {
         return valueA - valueB;
      }
   });
};
export function convertSavedViewToDataTableConfig(
   savedView: SavedView,
   columnHeadersMap: ColumnMap,
   defaultListTableConfig: DataTableConfig,
   filterFieldMap: ColumnMap,
   filterRendererMap: ColumnMap,
   groupId: string,
   customFields?: CustomField[],
   roleOptions?: AppliedRoleOption[],
) {
   const dtSortBy = columnHeadersMap[savedView.view_config.sort_by!];
   const columnHeadersMapWithCustomFields = {
      ...columnHeadersMap,
      ...customFields?.reduce((acc: any, field: any) => {
         acc[field.name] = field.integration_name;
         return acc;
      }, {}),
   };
   const columnState =
      savedView.view_config.column_headers?.map((x: any) => {
         let dtColumnName = "";

         if (x.key === "custom_fields") {
            dtColumnName = columnHeadersMapWithCustomFields[x.name];
         } else if (x.key === "positions") {
            dtColumnName = `positions_${x.key_id}`;
         } else {
            dtColumnName = columnHeadersMapWithCustomFields[x.key];
         }
         let sort = null;

         if (dtColumnName == dtSortBy) {
            sort = savedView.view_config.sort_direction == Order.ASCENDING ? "asc" : "desc";
         }

         return {
            field: dtColumnName,
            hidden: false,
            width: x.width,
            rowGroup: false,
            flex: x.sequence,
            pinned: x.pinned ?? null,
            sort: sort,
            sortIndex: null,
         };
      }) ?? [];

   // If the column isnt present in the saved view, it should be added to the column state as hidden
   defaultListTableConfig.columnState.forEach((x: any) => {
      const isPresentInSavedView = columnState.find((y: any) => x.field == y.field);
      if (!isPresentInSavedView) {
         columnState.push({
            ...x,
            hidden: true,
         });
      }
   });

   // Generate the project role columns and if they are note present in the saved view,
   // Add them as hidden
   generateProjectRoleColumnDefinitions(groupId, roleOptions).forEach((cd: ColumnDefinition) => {
      const keyToMatch = cd.field.split("_")[0];
      const keyIdToMatch = cd.field.split("_")[1];
      const isPresentInSavedView = savedView.view_config.column_headers?.find((sv: any) => {
         if (sv.key === keyToMatch && sv.key_id === keyIdToMatch) {
            return sv;
         }
      });

      if (!isPresentInSavedView) {
         columnState.push({
            field: cd.field,
            hidden: true,
            width: 140,
            sort: null,
            sortIndex: null,
            rowGroup: false,
            pinned: false,
            flex: null,
         });
      }
   });

   const serverFilters: IServerFilter[] = [];
   const filtersData = savedView.chip_filters?.reduce(
      (result, item) => {
         if (item.property === "custom_fields") {
            result.customFiltersData.push(item);
         } else if (item.property === "project_roles") {
            result.rolesFilterData.push(item);
         } else {
            result.noncustomFiltersData.push(item);
         }
         return result;
      },
      { customFiltersData: [] as any, noncustomFiltersData: [] as any, rolesFilterData: [] as any },
   );

   if (filtersData?.noncustomFiltersData.length > 0) {
      filtersData?.noncustomFiltersData.forEach((filter: ChipFilter) => {
         const dtFilter = convertToIServerFilter(filter, filterFieldMap, filterRendererMap);
         if (dtFilter) {
            const maybeExisitngFilter = serverFilters.find((x) => x.field == dtFilter.field);
            // If a filter has already been added for the field, only update the value and selected attributes
            if (maybeExisitngFilter) {
               maybeExisitngFilter.value.push(...dtFilter.value);
               maybeExisitngFilter.selected.push(...dtFilter.selected);
            }
            // Otherwise, add the entire filter
            else {
               serverFilters.push(dtFilter);
            }
         }
      });
   }
   if (filtersData?.customFiltersData.length > 0) {
      const dtFilter = transformData(filtersData?.customFiltersData, customFields);
      serverFilters.push(...dtFilter);
   }

   if (filtersData?.rolesFilterData.length > 0) {
      const dtFilters = convertRolesFilterToIServerFilters(filtersData?.rolesFilterData);
      serverFilters.push(...dtFilters);
   }

   const viewConfig = {
      ...defaultListTableConfig,
      columnState: columnState,
      serverFilters: serverFilters,
      search: savedView.search,
   };

   return viewConfig;
}

function convertRolesFilterToIServerFilters(chipFilters: ChipFilter[]): IServerFilter[] {
   return chipFilters.map((cf: ChipFilter) => ({
      value: [
         {
            value: cf.value,
            label: cf.value_name,
         } as any,
      ],
      field: `positions_${cf.classifier}`,
      filterRenderer: MultiSelectFilterRenderer as any,
      selected: [
         {
            value: cf.value,
            label: cf.value_name,
         },
      ],
   }));
}

function convertToIServerFilter(
   chipFilter: ChipFilter,
   filterFieldMap: ColumnMap,
   filterRendererMap: ColumnMap,
): IServerFilter | undefined {
   const { property, value_name, classifier, classifier_label } = chipFilter;
   const value = chipFilter.value as any;
   const field = filterFieldMap[property];
   const filterRenderer = filterRendererMap[property];

   switch (filterRenderer) {
      case MultiSelectPillFilter:
         return {
            value: value.map((x: any) => x.id),
            field: field,
            filterRenderer: filterRenderer,
            selected: value,
         };
      case StatusFilter:
      case MultiSelectFilterRenderer:
         return {
            value: [{ value: value, label: value_name } as any],
            field: field,
            filterRenderer: filterRenderer,
            selected: [
               {
                  value: value,
                  label: value_name,
               },
            ],
         };
      case NumericValueFilter:
         return {
            value: [
               {
                  value: value,
                  stringValue: value_name,
                  classifier: {
                     id: 1,
                     label: classifier_label,
                     value: classifier,
                  },
               } as any,
            ],
            field: field,
            filterRenderer: filterRenderer,
            selected: [
               {
                  value: value,
                  stringValue: value_name,
                  classifier: {
                     id: 1,
                     label: classifier_label,
                     value: classifier,
                  },
               } as any,
            ],
         };
      case DateFilter:
         // case "customDateFilter":
         return convertDateChipFilter(field, filterRenderer, classifier, value, classifier_label);

      case HidePastTimeOffFilter:
         return {
            value: [{ value: value } as any],
            field: field,
            filterRenderer: filterRenderer,
            selected: [
               {
                  value: value,
               },
            ],
         };
      default:
         return {
            value: [value],
            field: field,
            filterRenderer: filterRenderer,
            selected: [{ value: value, label: value_name }],
         };
   }
}

function transformData(data: any[], customFields?: CustomField[]): any[] {
   // Define transformation mappings based on type
   const transformationMap: Record<string, (items: any[], field: string) => any> = {
      [CustomFieldType.BOOL]: (items, field) => {
         const transformedBoolData = items.map((item) => ({
            field: field,
            selected: [{ value: item.value, label: item.value ? "Yes" : "No" }],
            value: [{ value: item.value, label: item.value ? "Yes" : "No" }],
         }));

         // Merge all transformed bool data into a single object
         return {
            field: field, // Example field name for consolidated bool data
            filterRenderer: "customBoolFilterRenderer",
            selected: transformedBoolData.map((item) => item.selected[0]),
            value: transformedBoolData.map((item) => item.value[0]),
         };
      },
      [CustomFieldType.DATE]: (items, field) =>
         items.map((item) => ({
            field: field,
            filterRenderer: "customDateFilterRenderer",
            selected: [
               {
                  classifier: {
                     label: item.classifier_label,
                     value: item.classifier,
                  },
                  date: getAttachedDate(item.value).toISOString(),
               },
            ],
            value: [
               {
                  classifier: {
                     label: item.classifier_label,
                     value: item.classifier,
                  },
                  date: getAttachedDate(item.value).toISOString(),
               },
            ],
         })),
      [CustomFieldType.TEXT]: (items, field) =>
         items.map((item) => ({
            field: field,
            filterRenderer: "customTextFilterRenderer",
            selected: [{ value: item.value, label: item.value }],
            value: [{ value: item.value, label: item.value }],
         })),
      [CustomFieldType.CURRENCY]: (items, field) =>
         items.map((item) => ({
            field: field,
            filterRenderer: "customNumericValueFilter",
            selected: [
               {
                  classifier: {
                     label: item.classifier_label,
                     value: item.classifier,
                  },
                  value: item.value,
                  stringValue: item.value.toString(),
               },
            ],
            value: [
               {
                  classifier: {
                     label: item.classifier_label,
                     value: item.classifier,
                  },
                  value: item.value,
                  stringValue: item.value.toString(),
               },
            ],
         })),
      [CustomFieldType.NUMBER]: (items, field) =>
         items.map((item) => ({
            field: field,
            filterRenderer: "customNumericValueFilter",
            selected: [
               {
                  classifier: {
                     label: item.classifier_label,
                     value: item.classifier,
                  },
                  value: item.value,
                  stringValue: item.value.toString(),
               },
            ],
            value: [
               {
                  classifier: {
                     label: item.classifier_label,
                     value: item.classifier,
                  },
                  value: item.value,
                  stringValue: item.value.toString(),
               },
            ],
         })),
      [CustomFieldType.MULTI_SELECT]: (items, field) =>
         items.map((item) => ({
            field: field,
            filterRenderer: "multiSelectFilterRenderer",
            selected: item.value.map((x: any) => x.name),
            value: item.value.map((x: any) => x.name),
         })),
      [CustomFieldType.SELECT]: (items, field) =>
         items.map((item) => ({
            field: field,
            filterRenderer: "multiSelectFilterRenderer",
            selected:
               typeof item.value === "string" ? [item.value] : item.value.map((x: any) => x.name),
            value:
               typeof item.value === "string" ? [item.value] : item.value.map((x: any) => x.name),
         })),
   };

   // Transform data based on type using reduce
   return data.reduce((acc: any[], item: any) => {
      const field =
         customFields?.find((x) => x.id === item.custom_field_id)?.integration_name ??
         item.filter_name;
      const transformFn = transformationMap[item.type];
      if (transformFn) {
         if (
            item.type === CustomFieldType.BOOL &&
            acc.length > 0 &&
            acc[acc.length - 1].filterRenderer === "customBoolFilterRenderer"
         ) {
            acc[acc.length - 1] = {
               ...acc[acc.length - 1],
               selected: acc[acc.length - 1].selected.concat(transformFn([item], field).selected),
               value: acc[acc.length - 1].value.concat(transformFn([item], field).value),
            };
         } else {
            const transformedData = transformFn([item], field);
            if (Array.isArray(transformedData)) {
               const maybeExistingFilter = acc.find((x) => x.field === transformedData[0].field);
               if (maybeExistingFilter) {
                  maybeExistingFilter.selected = maybeExistingFilter.selected.concat(
                     transformedData[0].selected,
                  );
                  maybeExistingFilter.value = maybeExistingFilter.value.concat(
                     transformedData[0].value,
                  );
               } else {
                  acc.push(...transformedData);
               }
            } else {
               acc.push(transformedData);
            }
         }
      }
      return acc;
   }, []);
}

function convertDateChipFilter(
   field: string,
   filterRenderer: any,
   classifier: string | null,
   value: any,
   classifier_label: string | null,
): IServerFilter | undefined {
   // We don't support With x days/weeks/months in the new page yet.
   // When we encounter one of those filters in a saved view, simply return undefined.
   if (classifier == "<=x<") {
      return;
   }
   const dateString = getAttachedDate(value).toISOString();
   return {
      value: [
         { date: dateString, classifier: { value: classifier, label: classifier_label } } as any,
      ],
      field: field,
      filterRenderer: filterRenderer,
      selected: [
         { date: dateString, classifier: { value: classifier, label: classifier_label } } as any,
      ],
   };
}

export function convertDataTableConfigToSavedView(
   viewConfig: DataTableConfig,
   columnHeadersMap: ColumnMap,
   filterNameMaps: ColumnMap,
   filterFieldMap: ColumnMap,
   filterRendererMap: ColumnMap,
   customFields?: CustomField[],
): Partial<SavedView> {
   let sort_by;
   let sort_direction;
   const columnHeadersMapWithCustomFields = {
      ...columnHeadersMap,
      ...(customFields?.reduce((acc: any, field: any) => {
         acc[field.name] = field.integration_name;
         return acc;
      }, {}) ?? {}),
   };

   const column_headers = viewConfig.columnState
      .filter((x: any) => !x.hidden)
      .map((col: any, i: number) => {
         if (col.sort !== null) {
            sort_by = Object.keys(columnHeadersMapWithCustomFields).find(
               (key) => columnHeadersMapWithCustomFields[key] == col.field,
            );
            sort_direction = col.sort == "asc" ? Order.ASCENDING : Order.DESCENDING;
         }
         let key;
         let key_id;
         if (checkFilterIsRoles(col)) {
            key = col.field.split("_")[0];
            key_id = col.field.split("_")[1];
         } else {
            key =
               Object.keys(columnHeadersMapWithCustomFields).find(
                  (key) => columnHeadersMapWithCustomFields[key] == col.field,
               ) ?? "";
         }

         return {
            key: key,
            key_id: key_id,
            width: Math.round(col.width),
            name: "",
            sequence: i,
            sortable: true,
            sub_properties: [],
            pinned: col.pinned,
         };
      });

   let chip_filters: ChipFilter[] = [];
   viewConfig.serverFilters?.forEach((filter: IServerFilter) => {
      let name;
      let property;
      let classifier;
      if (checkFilterIsRoles(filter)) {
         name = "Project Roles";
         property = "project_roles";
         classifier = filter.field.split("_")[1];
      } else {
         name = filterNameMaps[filter.field];
         property = Object.keys(filterFieldMap).find(
            (key: string) => filterFieldMap[key] == filter.field,
         )!;
      }

      // If the filter is a custom field, find the custom field object
      if (!name && !property) {
         const associatedCustomField = customFields
            ? findCustomField(customFields, filter.field)
            : undefined;
         if (associatedCustomField) {
            const cFilters = convertCustomFieldToChipFilter(
               filter,
               associatedCustomField,
            ) as ChipFilter[];
            chip_filters = chip_filters.concat(cFilters);
         }
      } else {
         const cFilters = convertToChipFilter(
            filter,
            filterRendererMap,
            name,
            property,
            classifier,
         );
         chip_filters = chip_filters.concat(cFilters);
      }
   });

   return {
      chip_filters: chip_filters,
      view_config: {
         column_headers: column_headers,
         sort_by: sort_by,
         sort_direction: sort_direction,
      },
   };
}
function convertToChipFilter(
   filter: IServerFilter,
   filterRendererMap: ColumnMap,
   name: string,
   property: string,
   classifier?: string,
): ChipFilter[] {
   const filterRenderer = filterRendererMap[filter.field];

   switch (filterRenderer) {
      case MultiSelectPillFilter:
         return filter.selected.map((val: any) => {
            return {
               filter_name: name,
               negation: false,
               property: property,
               value: [val],
               value_name: val.label,
               classifier: null,
               classifier_label: null,
               custom_field_id: null,
            };
         });
      case StatusFilter:
         return [
            {
               filter_name: name,
               negation: false,
               property: property,
               value: filter.selected.map((x: any) => x.value).toString(),
               value_name: filter.selected.map((x: any) => x.label).toString(),
               classifier: null,
               classifier_label: null,
               custom_field_id: null,
            },
         ];

      case DateFilter: {
         const value: any = filter.value[0];
         const date = new Date(value.date);
         const detachedDay = getDetachedDay(date);
         return [
            {
               filter_name: name,
               negation: false,
               property: property,
               value: detachedDay,
               value_name: `${value.classifier.label} ${date.toDateString()}`,
               classifier: value.classifier.value,
               classifier_label: value.classifier.label,
               custom_field_id: null,
            },
         ];
      }
      case NumericValueFilter:
         return filter.selected.map((val: any) => {
            const cFValue = val.value ? val.value : val.id;
            return {
               filter_name: name,
               negation: false,
               property: property,
               value: cFValue,
               classifier: val.classifier.value,
               value_name: cFValue.toString(),
               classifier_label: val.classifier.label,
               custom_field_id: val.custom_field_id,
            };
         });

      case HidePastTimeOffFilter:
         return [
            {
               filter_name: name,
               negation: false,
               property: property,
               value: true,
               value_name: "Hide",
               classifier: null,
               classifier_label: null,
               custom_field_id: null,
            },
         ];

      default:
         return filter.selected.map((val: any) => {
            const cFValue = val.value ? val.value : val.id;
            return {
               filter_name: name,
               negation: false,
               property: property,
               value: cFValue,
               classifier: classifier ?? null,
               value_name: val.label,
               classifier_label: null,
               custom_field_id: null,
            };
         });
   }
}
function convertCustomFieldToChipFilter(filter: IServerFilter, customField: CustomField) {
   const name = customField.name;
   const filterRenderer = CustomFieldFilterRendererMap[customField.type!];
   const cFValue = {
      filter_name: name,
      negation: false,
      property: "custom_fields",
      custom_field_id: customField.id,
      type: customField.type,
      timestamp: false,
   };
   const customFilters = filter.selected.map((val: any) => {
      switch (filterRenderer) {
         case BooleanFilter:
            return {
               ...cFValue,
               value: val.value,
               value_name: val.value.toString().toUpperCase(),
            };
         case NumericValueFilter:
            return {
               ...cFValue,
               value: val.value,
               value_name: val.value.toString(),
               classifier: val.classifier.value,
               classifier_label: val.classifier.label,
            };
         case DateFilter: {
            const date = new Date(val.date);
            const detachedDay = getDetachedDay(date);
            return {
               ...cFValue,
               value: detachedDay,
               value_name: `${val.classifier.label} ${date.toDateString()}`,
               classifier: val.classifier.value,
               classifier_label: val.classifier.label,
            };
         }
         case TextFilter:
            return { ...cFValue, value: val.value, value_name: val.value };
         default:
            break;
      }
   });
   if (filterRenderer === MultiSelectFilterRenderer) {
      const customFilters = {
         ...cFValue,
         value: filter.selected.map((x: any) => {
            return { value: x, name: x, selected: false };
         }),
         value_name: filter.selected.join(" & "),
      };

      return customFilters;
   }

   return customFilters;
}

export const getDownloadUrl = ({
   companyQrId,
   entityQrId,
   entityTitle,
   entitySubtitle,
   selectedSize,
   includeInfo,
}: QRCodeDownloadURLProps) => {
   let baseUrl = `${requestContext.baseUrl}/download/qrcode?`;
   baseUrl += `cqi=${companyQrId}&eqi=${entityQrId}&epa=pr&`;

   if (entityTitle) {
      baseUrl += `title=${entityTitle}&`;
   }
   if (entitySubtitle) {
      baseUrl += `subtitle=${entitySubtitle}&`;
   }

   baseUrl += `width=${selectedSize}&info=${includeInfo}`;

   return baseUrl;
};

/*Function to check if the field is an integrated field and is locked in case of custom fields property matches with id
   and for common fields it matches with name*/
export function isIntegratedField(field: string, integratedFields: IntegratedField[]) {
   return integratedFields.some(
      (integratedField: IntegratedField) =>
         integratedField.property === field && integratedField.locked,
   );
}

export function isSensitiveField(
   field: string,
   sensitiveFields: any,
   canViewSensitiveFields: boolean,
   config: any,
   integration_name?: string,
) {
   // Create a map of column state by field name from the config coming from the local storage
   const columnStateMap = new Map(config.columnState.map((column: any) => [column.field, column]));

   const shouldHide = sensitiveFields.includes(field) || sensitiveFields.includes(integration_name);

   const shouldLockVisible = !shouldHide && canViewSensitiveFields;
   const propertiesDetails = { hidden: true, lockVisible: false };

   const existingConfig: any = columnStateMap.get(field);

   if (existingConfig) {
      if (shouldHide && !canViewSensitiveFields) {
         return { hidden: true, lockVisible: true };
      } else {
         return { hidden: existingConfig.hidden, lockVisible: false };
      }
   } else if (shouldLockVisible) {
      return { hidden: true, lockVisible: false };
   } else if (shouldHide && !canViewSensitiveFields) {
      return { hidden: true, lockVisible: true };
   }

   return propertiesDetails;
}

export function isFieldEditable(
   field: string,
   projectsSensitiveFields: any,
   canEditProjectSensitiveFields: boolean,
) {
   const isFieldSensitive = projectsSensitiveFields.includes(field);
   // Logic to determine if the field should be disabled
   if (
      (isFieldSensitive && canEditProjectSensitiveFields) ||
      (!isFieldSensitive && canEditProjectSensitiveFields) ||
      (!isFieldSensitive && !canEditProjectSensitiveFields)
   ) {
      return false; // field is editable
   } else {
      return true; // field is not editable
   }
}
export const formatOptions = (options: RawOption[]): FormattedOption[] => {
   if (!options.length) {
      return [];
   }
   return options
      .map((option: any) => ({
         id: option.id || option.value, // Use option.id if available, otherwise option.value
         label: option.name,
      }))
      .sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label)); // Sort by label
};

export const generateQuantityOptions = (min: number, max: number) => {
   return Array.from({ length: max - min + 1 }, (_, index) => {
      const value = (min + index).toString(); // Generate value from min to max
      return { id: value, label: value };
   });
};

// Filter out sensitive fields if user does not have permission to view them OR type is currency and user does not have permission to view financials
export function getFilteredFields(
   fields: ProjectDetailsFields[],
   projectSensitiveFields: any,
   canViewProjectSensitiveFields: boolean,
   canViewProjectFinancials: boolean,
) {
   return fields.filter((field: ProjectDetailsFields) => {
      const isSensitiveField = projectSensitiveFields.includes(field.name);
      const isFinancialField = field.type === CustomFieldType.CURRENCY;

      if (isFinancialField) {
         return canViewProjectFinancials;
      } else {
         if (isSensitiveField && canViewProjectSensitiveFields) {
            return true;
         }
         if (!isSensitiveField) {
            return true;
         }
      }

      return false;
   });
}

export function commonButtonStyle({
   selectedId,
   currentId,
}: CommonStyleProps): React.CSSProperties {
   return {
      cursor: "pointer",
      pointerEvents: selectedId && selectedId !== currentId ? "none" : "auto",
      color: selectedId && selectedId !== currentId ? "hsl(200, 8%, 70%)" : "black",
   };
}

export function getReorderedColumnDefinition(
   columnDefinitions: ColumnDefinition[],
   tableConfigColumnState: ColumnDefinition[],
) {
   const orderMappingOfTableConfig = new Map(
      tableConfigColumnState.map((item: ColumnDefinition, index: any) => [item.field, index]),
   );

   const reorderedColumnDefinitions = columnDefinitions.slice().sort((a, b) => {
      const orderA = orderMappingOfTableConfig.get(a.field) as number;
      const orderB = orderMappingOfTableConfig.get(b.field) as number;
      return (
         (orderA !== undefined ? orderA : Infinity) - (orderB !== undefined ? orderB : Infinity)
      );
   });

   return reorderedColumnDefinitions;
}

const CustomFieldFilterRendererMap: ColumnMap = {
   select: MultiSelectFilterRenderer,
   "multi-select": MultiSelectFilterRenderer,
   bool: BooleanFilter,
   number: NumericValueFilter,
   text: TextFilter,
   date: DateFilter,
   currency: NumericValueFilter,
};

export const getUserName = (personName: PersonName, isLastNameFirst: boolean): string => {
   const { first = "", last = "" } = personName ?? {};
   return isLastNameFirst ? `${last} ${first}`.trim() : `${first} ${last}`.trim();
};

import { renderCustomFields } from "@/react/shared/custom-field-utils";
import { Card, Box, FlexList, Title, H2 } from "@procore/core-react";
import React from "react";
import type { CustomFieldsCardProps } from "../types";

const CustomFieldsCard: React.FC<CustomFieldsCardProps> = ({
   customFields,
   I18n,
   filterIntegrationOnly,
}) => {
   if (!customFields || customFields.length === 0) {
      return null;
   }

   return (
      <Card style={{ marginBottom: "10px" }}>
         <Box padding="md">
            <FlexList justifyContent="space-between" marginBottom="sm">
               <Title>
                  <Title.Text>
                     <H2>{I18n.t("views.company.workforce_planning.additional_information")}</H2>
                  </Title.Text>
               </Title>
            </FlexList>
            {renderCustomFields(customFields, I18n, filterIntegrationOnly)}
         </Box>
      </Card>
   );
};

export default CustomFieldsCard;

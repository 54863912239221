import * as React from "react";
import { type FormikHelpers, type FormikProps } from "formik";
import {
   Box,
   Button,
   Card,
   Flex,
   FlexList,
   Form,
   H1,
   H2,
   Page,
   Required,
   Spinner,
   useI18nContext,
} from "@procore/core-react";
import {
   CustomFieldType,
   ProjectStatus,
} from "@laborchart-modules/common/dist/postgres/schemas/common/enums";

import type { UpdateSingleProjectResponse } from "@laborchart-modules/lc-core-api/dist/api/projects/update-projects";
import { ProjectStore } from "@/stores/project-store.core";
import type { TimeZonesMapValues } from "@/lib/utils/timezones";
import {
   TIME_ZONES_MAP,
   TIME_ZONES_MAP_REVERSED,
   timeOptions,
   timeZoneOptions,
   timeZoneSearchComparator,
} from "@/lib/utils/timezones";
import { useGetGroupOptionsQuery, useGetProjectQuery } from "./queries";
import { projectDetailSchema, projectSpecificationsSchema } from "./validation-schemas";
import type { ProjectSpecificationsFormValues } from "./types";
import {
   type ExtendedGetProjectDetailResponse,
   type ProjectDetailFormValues,
   type ProjectDetailTearsheetProps,
   type ProjectDetailsFields,
   ProjectDetailTearsheetTabs,
} from "./types";
import { LaborGraphCard } from "./labor-graph-card";
import { ColorPicker } from "@/react/components/common/components/color-picker";
import type {
   CustomField,
   IntegratedField,
   StreamResponseConversion,
   ExtendedFieldType,
   Tab,
} from "@/react/prop-types";
import { FieldType } from "@/react/prop-types";
import { Lock } from "@procore/core-icons";
import { useEffect, useState } from "react";
import { getProcoreCompanyIdFromURL } from "@/lib/utils/url";
import { LinkToProcoreEntity } from "../../link-to-procore-entity/link-to-procore-entity";
import { CustomFieldEntity } from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields";
import { CustomTabs } from "@/react/shared/components/custom-tabs";
import { ProjectDetailsRolesCard } from "./project-detail-cards/project-details-roles-card";
import { ProjectDetailsTagsCard } from "./project-detail-cards/project-details-tags-card";
import { ProjectDetailsWageOverridesCard } from "./project-detail-cards/project-details-wage-overrides-card";
import { ProjectDetailsAttachmentsCard } from "./project-detail-cards/project-details-attachments-card";
import { authManager } from "@/lib/managers/auth-manager";
import { QRCodeCard } from "@/react/shared/components/qr-code-card";
import { useGetCompany } from "../../common/queries/queries";
import { usePermissionContext, AuthAction } from "@/react/providers/permission-context-provider";
import { PhotoUploader } from "./project-photo";
import { ProjectDetailsNotesCard } from "./project-detail-cards/project-details-notes-card";
import { ProjectDetailsActivities } from "./project-detail-cards/project-details-activities-card";
import { getFilteredFields, isFieldEditable } from "@/react/shared/helper";
import { PROJECT_STATUS_OPTIONS } from "../../project-list/project-list-prop-types";
import { ProjectCustomAssignmentAlerts } from "./project-detail-cards/project-details-custom-assignment-alerts";
import {
   processCustomFieldsFormValues,
   processCustomFieldValue,
} from "@/react/shared/custom-field-utils";
import { FieldMapping } from "../../project-list/project-list-enums";
import {
   projectDetailFieldsSection1,
   projectDetailFieldsSection2,
   projectDetailFieldsSection3,
} from "./constants";

//adding more objects to this array will automatically start rendering other tabs, just maintain unique ID and labels.
const PROJECT_DETAILS_TABS: Tab[] = [
   { id: 0, label: ProjectDetailTearsheetTabs.OVERVIEW, visible: true },
   { id: 1, label: ProjectDetailTearsheetTabs.SPECIFICATIONS, visible: true },
   { id: 2, label: ProjectDetailTearsheetTabs.NOTES, visible: true },
   { id: 3, label: ProjectDetailTearsheetTabs.ACTIVITY, visible: true },
];

export const ProjectDetailTearsheet: React.FC<ProjectDetailTearsheetProps> = ({
   projectId,
   handleCreateLaborPlanClick,
   onUpdateProject,
   integratedFields,
   customFields,
   showActiveTab,
}) => {
   const I18n = useI18nContext();
   const [formViewMode, setFormViewMode] = React.useState<"read" | "update">("read");
   const {
      data: projectData,
      isLoading: projectDataLoading,
      refetch,
   } = useGetProjectQuery(projectId);
   const { data: groupOptions, isLoading: groupOptionsLoading } = useGetGroupOptionsQuery();
   const { data: companyData } = useGetCompany();
   const validationSchema = projectDetailSchema(I18n, customFields);
   const validationSchemaSpecifications = projectSpecificationsSchema();
   const dataIsLoading = projectDataLoading || groupOptionsLoading;
   const [projectIntegratedFields, setProjectIntegratedFields] = useState<IntegratedField[]>([]);
   let initialValues: ProjectDetailFormValues | undefined;
   const procoreCompanyId: string | undefined = getProcoreCompanyIdFromURL();

   const activeTabId =
      (showActiveTab && PROJECT_DETAILS_TABS.find((tab) => tab.label === showActiveTab)?.id) ?? 0;
   const [activeTab, setActiveTab] = useState(activeTabId || 0);
   const [canViewProjectWageOverrides, setCanViewProjectWageOverrides] =
      React.useState<boolean>(false);
   const [canEditProjectDetails, setCanEditProjectDetails] = React.useState<boolean>(false);
   const { checkAuthAction } = usePermissionContext();
   const [qrCodeEnabled, setQrCodeEnabled] = React.useState<boolean | undefined>(false);
   const [projectQrCodeEnabled, setProjectQRCodeEnabled] = React.useState<boolean | undefined>(
      false,
   );
   const [canViewProjectTags, setCanViewProjectTags] = useState<boolean>(false);
   const [canViewProjectAttachments, setCanViewProjectAttachments] = useState<boolean>(false);
   const [projectDetailTabsState, setProjectDetailTabsState] = useState(PROJECT_DETAILS_TABS);

   const [canViewProjectSensitiveFields, setCanViewProjectSensitiveFields] = useState(false);
   const [canEditProjectSensitiveFields, setCanEditProjectSensitiveFields] = useState(false);
   const [projectSensitiveFields, setProjectSensitiveFields] = useState<string[]>([]);
   const [canViewProjectFinancials, setCanViewProjectFinancials] = useState(false);
   const [canViewAssignments, setCanViewAssignments] = useState(false);

   useEffect(() => {
      checkUserCanViewTab();
      const canViewProjectWageOverrides = checkAuthAction(AuthAction.VIEW_PROJECT_WAGE_OVERRIDES);
      const canEditProjectDetails = checkAuthAction(AuthAction.EDIT_PROJECT_DETAILS);
      const canViewProjectTags = checkAuthAction(AuthAction.VIEW_PROJECT_TAGS);
      const canViewProjectAttachments = checkAuthAction(AuthAction.VIEW_PROJECT_ATTACHMENTS);
      const canViewAssignments = checkAuthAction(AuthAction.VIEW_ASSIGNMENTS);
      setCanViewProjectWageOverrides(canViewProjectWageOverrides);
      setCanEditProjectDetails(canEditProjectDetails);
      setCanViewProjectTags(canViewProjectTags);
      setCanViewProjectAttachments(canViewProjectAttachments);
      setQrCodeEnabled(authManager.companyModules()?.qrCodes);
      setProjectQRCodeEnabled(authManager.companyModules()?.projectsQrCodes);
      setCanViewAssignments(canViewAssignments);

      const canViewProjectSensitiveFields = checkAuthAction(AuthAction.VIEW_PROJECT_SENSITIVE);
      setCanViewProjectSensitiveFields(canViewProjectSensitiveFields);

      const canEditProjectSensitiveFields = checkAuthAction(AuthAction.EDIT_PROJECT_SENSITIVE);
      setCanEditProjectSensitiveFields(canEditProjectSensitiveFields);

      setProjectSensitiveFields(authManager.projectsSensitiveFields());

      const canViewProjectFinancials = checkAuthAction(AuthAction.VIEW_PROJECT_FINANCIALS);
      setCanViewProjectFinancials(canViewProjectFinancials);
   }, []);

   useEffect(() => {
      if (integratedFields) {
         if (Array.isArray(integratedFields.data.projects_integrated_fields)) {
            setProjectIntegratedFields(integratedFields.data.projects_integrated_fields);
         }
      }
   }, [integratedFields]);

   const checkUserCanViewTab = () => {
      const newProjectDetailTabsState = [...projectDetailTabsState];
      newProjectDetailTabsState[2].visible = checkAuthAction(AuthAction.VIEW_PROJECT_NOTES);
      newProjectDetailTabsState[3].visible = checkAuthAction(AuthAction.VIEW_PROJECT_ACTIVITY);
      setProjectDetailTabsState(newProjectDetailTabsState);
   };

   const PROJECT_STATUS_MAP = {
      pending: {
         id: ProjectStatus.PENDING,
         label: I18n.t("views.company.workforce_planning.pending"),
         color: "yellow",
      },
      active: {
         id: ProjectStatus.ACTIVE,
         label: I18n.t("views.company.workforce_planning.active"),
         color: "green",
      },
      inactive: {
         id: ProjectStatus.INACTIVE,
         label: I18n.t("views.company.workforce_planning.inactive"),
         color: "gray",
      },
   } as const;

   // Function to generate custom label for fields
   function generateCustomLabel(field: string, translationString: string): JSX.Element {
      return (
         <div style={{ display: "inline-flex", alignItems: "center" }}>
            <span>{I18n.t(translationString)}</span>
            {isIntegratedField(field) && <Lock size="sm" style={{ marginLeft: "3px" }} />}
         </div>
      );
   }

   function getFormValues(
      data: ExtendedGetProjectDetailResponse["data"] | UpdateSingleProjectResponse["data"],
      groupOptions: StreamResponseConversion,
   ) {
      const baseFormValues = {
         name: data.name,
         job_number: data.job_number,
         status: PROJECT_STATUS_MAP[data.status],
         group_ids: data.group_ids
            .map((groupId) => groupOptions?.find(({ id }) => id === groupId))
            .filter((group) => group !== undefined),
         start_date: data.start_date ? new Date(data.start_date) : null,
         est_end_date: data.est_end_date ? new Date(data.est_end_date) : null,
         daily_start_time: {
            id: data.daily_start_time,
            label: timeOptions.find(({ id }) => id === data.daily_start_time)!.label,
         },
         daily_end_time: {
            id: data.daily_end_time,
            label: timeOptions.find(({ id }) => id === data.daily_end_time)!.label,
         },
         timezone: timeZoneOptions.find(
            ({ id }) => id === TIME_ZONES_MAP_REVERSED[(data.timezone ?? "") as TimeZonesMapValues],
         ),
         address_1: data.address_1 || null,
         address_2: data.address_2 || null,
         city_town: data.city_town || null,
         state_province: data.state_province || null,
         zipcode: data.zipcode || null,
         country: data.country || null,
         bid_rate: data.bid_rate || null,
         color: data.color,
         customer_name: data.customer_name || null,
         percent_complete: data.percent_complete || null,
         project_type: data.project_type || null,
      };

      // Initialize custom fields in form values
      const customFieldValues = processCustomFieldsFormValues(data.custom_fields);
      return { ...baseFormValues, ...customFieldValues };
   }

   /*Function to check if the field is an integrated field and is locked in case of custom fields property matches with id
   and for common fields it matches with name*/
   function isIntegratedField(field: string) {
      return projectIntegratedFields.some(
         (integratedField: IntegratedField) =>
            integratedField.property === field && integratedField.locked,
      );
   }

   // Function to render form fields
   function renderFormFields(fields: ProjectDetailsFields[], fieldSource?: string) {
      const rows = [];

      // Filter out sensitive fields if user does not have permission to view them OR type is currency and user does not have permission to view financials
      const filteredFields = getFilteredFields(
         fields,
         projectSensitiveFields,
         canViewProjectSensitiveFields,
         canViewProjectFinancials,
      );

      for (let i = 0; i < filteredFields.length; i += 3) {
         const rowFields = filteredFields.slice(i, i + 3);

         const validComponents = rowFields
            .map((field: ProjectDetailsFields, index: number) => {
               let FormComponent = null;
               let additionalProps = {};
               let colStartBase: 1 | 5 | 9;
               //logic for handling the column start base in the form field
               switch (index) {
                  case 0:
                     colStartBase = 1;
                     break;
                  case 1:
                     colStartBase = 5;
                     break;
                  default:
                     colStartBase = 9;
                     break;
               }
               if (fieldSource === "project") {
                  if (field.name === FieldMapping.groups) field.values = groupOptions;
                  if (field.name === FieldMapping.status) field.values = PROJECT_STATUS_OPTIONS;
                  if (
                     field.name === FieldMapping.daily_start_time ||
                     field.name === FieldMapping.daily_end_time
                  )
                     field.values = timeOptions;
                  if (field.name === FieldMapping.timezone) field.values = timeZoneOptions;
               }

               switch (field.type as ExtendedFieldType) {
                  case CustomFieldType.DATE:
                     FormComponent = Form.DateSelect;
                     break;
                  case CustomFieldType.NUMBER:
                     FormComponent = Form.Number;
                     break;
                  case CustomFieldType.TEXT:
                     FormComponent = Form.Text;
                     break;
                  case CustomFieldType.SELECT:
                     FormComponent = Form.Select;

                     additionalProps = {
                        options:
                           fieldSource === "project"
                              ? field.values
                              : field.values?.map((value: string) => ({
                                   id: field.name + "_" + value,
                                   label: value,
                                })) || [],
                        onSearch: false,
                        ...(field.name === "timezone" && {
                           searchComparator: timeZoneSearchComparator,
                        }),
                        placeholder: I18n.t("views.company.workforce_planning.select_option"),
                     };
                     break;
                  case CustomFieldType.MULTI_SELECT:
                     FormComponent = Form.MultiSelect;
                     additionalProps = {
                        options:
                           fieldSource === "project"
                              ? field.values
                              : field.values?.map((value: string) => ({
                                   id: field.name + "_" + value,
                                   label: value,
                                })) || [],
                        placeholder: I18n.t("views.company.workforce_planning.select_option"),
                     };
                     break;
                  case CustomFieldType.PARAGRAPH:
                     FormComponent = Form.TextArea;
                     break;
                  case CustomFieldType.BOOL:
                     FormComponent = Form.Checkbox;
                     break;
                  case CustomFieldType.CURRENCY:
                     FormComponent = Form.Currency;
                     break;
                  case CustomFieldType.HEX_COLOR:
                     FormComponent = Form.Field;
                     additionalProps = {
                        as: ColorPicker,
                     };
                     break;
                  case FieldType.PILL_SELECT:
                     FormComponent = Form.PillSelect;
                     additionalProps = {
                        options:
                           field.values?.map((value) => ({
                              ...value,
                              label: I18n.t(value.label),
                           })) || [],
                     };
                     break;
               }

               if (!FormComponent) return null; // If FormComponent is null, return null

               // Set disabled true if field is an integrated field and is locked
               // in case of custom fields property matches with id and for common fields it matches with name
               if (
                  isIntegratedField(fieldSource === "project" ? field.name : field.id) ||
                  field.integration_only ||
                  isFieldEditable(field.name, projectSensitiveFields, canEditProjectSensitiveFields)
               ) {
                  additionalProps = { ...additionalProps, disabled: true };
               }

               return (
                  <React.Fragment
                     key={
                        fieldSource === "project"
                           ? `project-field-fragment-outside-${field.name}`
                           : `custom-field-fragment-outside-${field.id}-${index}`
                     }
                  >
                     <FormComponent
                        key={
                           fieldSource === "project"
                              ? `project-field-${field.name}`
                              : `custom-field-${field.id}`
                        }
                        colStart={colStartBase}
                        colWidth={4}
                        name={field.name}
                        // @ts-expect-error: passing element as needed to append lock icon
                        label={
                           fieldSource === "project" ? (
                              generateCustomLabel(field.name, field.id)
                           ) : (
                              <div style={{ display: "flex", alignItems: "center" }}>
                                 <span>{field.name}</span>
                                 {(isIntegratedField(field.id) || field.integration_only) && (
                                    <Lock size="sm" style={{ marginLeft: "3px" }} />
                                 )}
                              </div>
                           )
                        }
                        {...additionalProps}
                        {...(field.required === true && { required: true })}
                     />
                  </React.Fragment>
               );
            })
            .filter((component: any) => component !== null); // Filter out null components

         if (validComponents.length > 0) {
            rows.push(<Form.Row key={`custom-fields-row-${i}`}>{validComponents}</Form.Row>);
         }
      }
      return rows;
   }

   if (projectData?.data && groupOptions) {
      initialValues = getFormValues(projectData.data, groupOptions);
   }

   // Function to process custom fields
   function processCustomFields(
      customFields: CustomField[],
      values: Record<string, any>,
   ): { [key: string]: any } {
      const resultantCustomField: { [key: string]: any } = {};
      customFields.forEach((customField: CustomField) => {
         // Process the custom field if it is not an integrated field and not integration-only
         if (!(isIntegratedField(customField.id) || customField.integration_only)) {
            const processedValue = processCustomFieldValue(customField, values);
            if (processedValue) {
               resultantCustomField[customField.id] = processedValue;
            }
         }
         // If the custom field type is currency  and canViewProjectFinancials is false, then remove the field from the payload
         if (!canViewProjectFinancials && customField.type === CustomFieldType.CURRENCY) {
            delete resultantCustomField[customField.id];
         }
         // If the custom field is sensitive and user does not have permission to view it, then remove the field from the payload
         if (
            isFieldEditable(customField.name, projectSensitiveFields, canEditProjectSensitiveFields)
         ) {
            delete resultantCustomField[customField.id];
         }
      });
      return resultantCustomField;
   }

   // Function to render link to procore entity
   function renderLinkToProcoreEntity() {
      // Check if projectData is available and procoreCompanyId is provided
      if (projectData?.data.procore_id && procoreCompanyId) {
         return (
            <LinkToProcoreEntity
               entityType={CustomFieldEntity.PROJECT}
               procoreEntityId={projectData.data.procore_id}
               procoreCompanyId={procoreCompanyId}
            />
         );
      }
   }

   async function handleSubmit(
      values: ProjectDetailFormValues,
      { setValues, resetForm }: FormikHelpers<ProjectDetailFormValues>,
   ) {
      const payload: any = {
         name: values.name,
         job_number: values.job_number,
         status: values.status.id,
         group_ids: groupOptions!
            .map(({ id }) => ({
               [id]: Boolean(values.group_ids.find(({ id: groupId }) => groupId === id)),
            }))
            .reduce((acc, next) => ({ ...acc, ...next })),
         start_date: values.start_date ? new Date(values.start_date).getTime() : null,
         est_end_date: values.est_end_date ? new Date(values.est_end_date).getTime() : null,
         daily_start_time: values.daily_start_time.id,
         daily_end_time: values.daily_end_time.id,
         timezone: TIME_ZONES_MAP[values.timezone?.id] ?? null,
         address_1: values.address_1,
         address_2: values.address_2,
         city_town: values.city_town,
         state_province: values.state_province,
         country: values.country,
         zipcode: values.zipcode,
         bid_rate: values.bid_rate,
         color: values.color,
         customer_name: values.customer_name,
         percent_complete: values.percent_complete,
         project_type: values.project_type,
      };
      // Removing integrated fields from payload as they are not editable
      Object.entries(payload).forEach(([fieldName]) => {
         if (isIntegratedField(fieldName)) {
            delete payload[fieldName];
         }
      });

      // Removing sensitive fields from payload if user does not have permission to view them or if they are noneditable
      Object.entries(payload).forEach(([fieldName]) => {
         if (!canEditProjectSensitiveFields && projectSensitiveFields.includes(fieldName)) {
            delete payload[fieldName];
         }
      });

      if (!canViewProjectFinancials) {
         delete payload["bid_rate"];
      }

      // Adding custom fields to payload updating the values as per the custom field type
      if (customFields) {
         payload.custom_fields = processCustomFields(customFields, values);
      }
      try {
         const updatedProject = await updateProjectData(payload);
         if (updatedProject.data && groupOptions) {
            refetch();
            setValues(getFormValues(updatedProject.data, groupOptions));
            setFormViewMode("read");
            onUpdateProject();
         }
      } catch {
         // will rollback changes by resetting form for now
         resetForm();
      }
   }

   async function updateProjectData(payload: any) {
      return await ProjectStore.updateProject(projectId, payload).payload;
   }

   /* istanbul ignore next */
   async function handleUploadPhoto(url: string) {
      await updateProjectData({ profile_pic_url: url });
   }

   async function handleSubmitSpecifications(
      values: ProjectSpecificationsFormValues,
      { resetForm }: FormikHelpers<ProjectSpecificationsFormValues>,
   ) {
      const payload: any = {
         default_recipient_ids: values.default_recipient_ids,
      };

      try {
         const updatedProject = await ProjectStore.updateProject(projectId, payload).payload;
         if (updatedProject.data) {
            setFormViewMode("read");
            refetch();
         }
      } catch {
         // will rollback changes by resetting form for now
         resetForm();
      }
   }

   return (
      <Page>
         <Page.Main
            style={{
               width: "952px",
               display: "flex",
               flexDirection: "column",
            }}
         >
            {dataIsLoading ? (
               <Spinner loading />
            ) : (
               <React.Fragment>
                  <Page.Header>
                     <Page.Title>
                        <H1>
                           {projectData?.data.name} {renderLinkToProcoreEntity()}
                        </H1>
                     </Page.Title>
                     <CustomTabs
                        tabs={projectDetailTabsState}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                     />
                  </Page.Header>
                  {/** Specifications Tab */}
                  {activeTab === projectDetailTabsState[1].id && (
                     <Form
                        view={formViewMode}
                        validationSchema={validationSchemaSpecifications}
                        onSubmit={handleSubmitSpecifications}
                     >
                        {/* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */}
                        {({
                           submitForm,
                           isSubmitting,
                           handleChange,
                        }: FormikProps<ProjectDetailFormValues>) => (
                           <React.Fragment>
                              <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                                 {canViewProjectTags && (
                                    <ProjectDetailsTagsCard
                                       projectData={projectData}
                                       refreshTable={onUpdateProject}
                                       refetchData={refetch}
                                       loading={projectDataLoading}
                                       updateProjectData={updateProjectData}
                                    />
                                 )}
                                 {canViewProjectAttachments && (
                                    <ProjectDetailsAttachmentsCard
                                       projectData={projectData}
                                       refetchData={refetch}
                                       loading={projectDataLoading}
                                       updateProjectData={updateProjectData}
                                    />
                                 )}
                                 {canViewProjectWageOverrides && (
                                    <ProjectDetailsWageOverridesCard
                                       projectData={projectData}
                                       refreshTable={onUpdateProject}
                                       refetchData={refetch}
                                       loading={projectDataLoading}
                                       updateProjectData={updateProjectData}
                                    />
                                 )}
                                 {canViewAssignments && (
                                    <ProjectCustomAssignmentAlerts
                                       projectData={projectData}
                                       editMode={formViewMode === "update"}
                                       setEditMode={() => setFormViewMode("update")}
                                       handleChange={handleChange}
                                       loading={projectDataLoading}
                                    />
                                 )}
                                 {qrCodeEnabled && projectQrCodeEnabled && (
                                    <QRCodeCard
                                       entityAbbreviation="pr"
                                       companyQrId={companyData?.data.qr_id}
                                       entityQrId={projectData?.data.qr_id}
                                       entityTitle={projectData?.data.name}
                                       entitySubtitle={projectData?.data.job_number}
                                    />
                                 )}
                              </Page.Body>
                              {formViewMode === "update" && (
                                 <Page.Footer data-testid="specification-tab-footer">
                                    <Box padding="md">
                                       <Flex justifyContent="space-between" alignItems="center">
                                          <Required showLabel />
                                          <FlexList space="sm">
                                             <Button
                                                variant="tertiary"
                                                onClick={() => {
                                                   setFormViewMode("read");
                                                }}
                                             >
                                                {I18n.t("views.company.workforce_planning.cancel")}
                                             </Button>
                                             <Button
                                                type="submit"
                                                disabled={isSubmitting}
                                                onClick={submitForm}
                                             >
                                                {I18n.t("views.company.workforce_planning.save")}
                                             </Button>
                                          </FlexList>
                                       </Flex>
                                    </Box>
                                 </Page.Footer>
                              )}
                           </React.Fragment>
                        )}
                     </Form>
                  )}
                  {/** Notes Tab */}
                  {activeTab === projectDetailTabsState[2].id && (
                     <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                        <ProjectDetailsNotesCard
                           projectId={projectId}
                           projectData={projectData}
                           refetchData={refetch}
                           loading={projectDataLoading}
                        />
                     </Page.Body>
                  )}
                  {/** Activity Tab */}
                  {activeTab === projectDetailTabsState[3].id && (
                     <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                        <ProjectDetailsActivities projectId={projectId} />
                     </Page.Body>
                  )}
                  {/** Overview Tab */}
                  {activeTab === projectDetailTabsState[0].id && (
                     <Form
                        view={formViewMode}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                     >
                        {/* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */}
                        {({
                           submitForm,
                           isSubmitting,
                           resetForm,
                        }: FormikProps<ProjectDetailFormValues>) => (
                           <React.Fragment>
                              <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                                 <LaborGraphCard
                                    projectId={projectId}
                                    projectName={projectData?.data.name ?? ""}
                                    handleCreateLaborPlanClick={handleCreateLaborPlanClick}
                                 />
                                 <Card style={{ marginBottom: "5px" }}>
                                    <Form.Form>
                                       <Box padding="md">
                                          <FlexList justifyContent="space-between">
                                             <H2>Information</H2>
                                             {formViewMode === "read" && canEditProjectDetails && (
                                                <Button
                                                   variant="secondary"
                                                   onClick={() => setFormViewMode("update")}
                                                   data-testid="edit-project-details"
                                                >
                                                   {I18n.t("views.company.workforce_planning.edit")}
                                                </Button>
                                             )}
                                          </FlexList>
                                       </Box>
                                       <Box padding="md">
                                          {projectDetailFieldsSection1 && (
                                             <>
                                                {" "}
                                                {renderFormFields(
                                                   projectDetailFieldsSection1 as ProjectDetailsFields[],
                                                   "project",
                                                )}{" "}
                                             </>
                                          )}
                                          <hr />
                                          {projectDetailFieldsSection2 && (
                                             <>
                                                {" "}
                                                {renderFormFields(
                                                   projectDetailFieldsSection2 as ProjectDetailsFields[],
                                                   "project",
                                                )}{" "}
                                             </>
                                          )}
                                          <hr />
                                          {projectDetailFieldsSection3 && (
                                             <>
                                                {" "}
                                                {renderFormFields(
                                                   projectDetailFieldsSection3 as ProjectDetailsFields[],
                                                   "project",
                                                )}{" "}
                                             </>
                                          )}

                                          {customFields && (
                                             <>
                                                <hr />
                                                {renderFormFields(
                                                   customFields as ProjectDetailsFields[],
                                                )}
                                             </>
                                          )}
                                       </Box>
                                    </Form.Form>
                                 </Card>
                                 <ProjectDetailsRolesCard
                                    projectData={projectData}
                                    refetchData={refetch}
                                    updateProjectData={updateProjectData}
                                    loading={projectDataLoading}
                                 />

                                 <Card style={{ marginBottom: "5px" }}>
                                    <Box padding="md">
                                       <H2>
                                          {I18n.t(
                                             "views.company.workforce_planning.projects.project_photo",
                                          )}
                                       </H2>
                                       <PhotoUploader
                                          imgUrl={projectData?.data.profile_pic_url ?? ""}
                                          onUploadPhoto={handleUploadPhoto}
                                       />
                                    </Box>
                                 </Card>
                              </Page.Body>
                              {formViewMode === "update" && (
                                 <Page.Footer>
                                    <Box padding="md">
                                       <Flex justifyContent="space-between" alignItems="center">
                                          <Required showLabel />
                                          <FlexList space="sm">
                                             <Button
                                                variant="tertiary"
                                                disabled={isSubmitting}
                                                onClick={() => {
                                                   setFormViewMode("read");
                                                   resetForm();
                                                }}
                                             >
                                                {I18n.t("views.company.workforce_planning.cancel")}
                                             </Button>
                                             <Button
                                                type="submit"
                                                disabled={isSubmitting}
                                                onClick={submitForm}
                                             >
                                                {I18n.t("views.company.workforce_planning.save")}
                                             </Button>
                                          </FlexList>
                                       </Flex>
                                    </Box>
                                 </Page.Footer>
                              )}
                           </React.Fragment>
                        )}
                     </Form>
                  )}
               </React.Fragment>
            )}
         </Page.Main>
      </Page>
   );
};

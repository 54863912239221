import { Checkbox, useI18nContext } from "@procore/core-react";
import type { ColumnDefinition } from "@procore/data-table";
import React, { useEffect } from "react";

type CheckboxFilterComponentProps = {
   columnDefinition: ColumnDefinition;
   onChange: (newValues: any[]) => void;
   value: any;
};

type Option = {
   id: string;
   label: string;
   selected?: boolean;
};

const initOptions = (options: Option[] = []) => {
   return options.map((option) => ({ ...option, selected: false, value: option.id }));
};

export const CheckboxFilterRenderer = (props: CheckboxFilterComponentProps) => {
   const I18n = useI18nContext();
   const [options, setOptions] = React.useState<Option[]>([]);
   const [loaded, setLoaded] = React.useState(false);

   useEffect(() => {
      const getFilterOptions = props.columnDefinition.filterProps?.getFilterOptions;
      if (!getFilterOptions) return;

      const response = getFilterOptions();
      if (response instanceof Promise) {
         response.then((options: Option[]) => {
            setOptions(initOptions(options));
            setLoaded(true);
         });
      } else {
         const options = response;
         setOptions(initOptions(options));
         setLoaded(true);
      }
   }, []);

   useEffect(() => {
      if (!loaded) return;
      const preSelected = (props.value ?? []).map((v: Option) => v.id);
      const newOptions = options.map((option) => ({
         ...option,
         selected: preSelected.includes(option.id),
      }));
      setOptions([...newOptions]);
   }, [props.value, loaded]);

   const handleChange = (index: number) => {
      options[index].selected = !options[index].selected;
      const newOptions = [...options];

      const onChangeParam = newOptions.filter((option) => option.selected);
      props.onChange(onChangeParam);
      setOptions([...newOptions]);
   };

   return (
      <React.Fragment>
         {options.map((option, index) => (
            <Checkbox
               key={option.id}
               checked={option.selected}
               onChange={() => handleChange(index)}
            >
               {I18n.t(option.label)}
            </Checkbox>
         ))}
      </React.Fragment>
   );
};

import * as React from "react";
import { ProjectStore } from "@/stores/project-store.core";
import type { GetProjectQrInfoResponse } from "@laborchart-modules/lc-core-api/dist/api/projects/get-project-qr-info";

export function useGetProjectQrProfile(projectQrId: string, companyQrId: string) {
   const [data, setData] = React.useState<GetProjectQrInfoResponse | null>(null);
   const [isLoading, setIsLoading] = React.useState(true);
   const fetchProjectQrProfile = React.useCallback(async () => {
      setIsLoading(true);
      setData(null);
      const data = await ProjectStore.getProjectQrInfo(projectQrId, companyQrId).payload;
      setData(data);
      setIsLoading(false);
   }, [projectQrId, companyQrId]);

   React.useEffect(() => {
      if (!projectQrId || !companyQrId) return;
      fetchProjectQrProfile();
   }, [projectQrId, companyQrId, fetchProjectQrProfile]);

   return { data, isLoading };
}

import React from "react";
import {
   Card,
   Box,
   EmptyState,
   FlexList,
   H2,
   Button,
   Spinner,
   useI18nContext,
   SegmentedController,
} from "@procore/core-react";
import { Gantt, ViewGrid } from "@procore/core-icons";
import { useGetRawProjectTotalsQuery } from "./queries";
import { LaborGraph as HighchartsLaborGraph } from "../../labor-graph/labor-graph";
import { processTotalsDataForGraph } from "./helpers";
import { AssignmentType } from "./types";
import { router } from "@/lib/router";
import { useGroupContext } from "@/react/providers/group-context-provider";
import { onCreateAssignmentClick, useProjectTearsheet } from "./project-tearsheet";

export type LaborGraphPropTypes = {
   projectId: string;
   projectName: string;
   handleCreateLaborPlanClick: (projectId: string) => void;
};

const navigateTo = (chartType: AssignmentType, groupId: string, projectName: string) => {
   const basePath = `/groups/${groupId}`;
   const queryParams = `?projectSearchFilter=${projectName}`;

   let path = "";
   switch (chartType) {
      case AssignmentType.GANTT:
         path = `${basePath}/gantt${queryParams}`;
         break;
      case AssignmentType.BOARD:
         path = `${basePath}/assignments${queryParams}`;
         break;
   }

   router.navigate(null, path);
};

const EmptyLaborGraph = (props: LaborGraphPropTypes) => {
   const I18n = useI18nContext();
   const { groupId } = useGroupContext();
   const { projectId, projectName, handleCreateLaborPlanClick } = props;
   const { dispatch: assignmentTearsheetDispatch } = useProjectTearsheet();
   return (
      <React.Fragment>
         <Box padding="md">
            <FlexList justifyContent="space-between">
               <H2>{I18n.t("views.company.workforce_planning.projects.labor_graph.title")}</H2>
               <FlexList space="sm">
                  <Button
                     icon={<Gantt />}
                     variant="secondary"
                     onClick={() => {
                        navigateTo(AssignmentType.GANTT, groupId, projectName);
                     }}
                  >
                     {I18n.t("views.company.workforce_planning.projects.labor_graph.gantt")}
                  </Button>
                  <Button
                     icon={<ViewGrid />}
                     variant="secondary"
                     onClick={() => {
                        navigateTo(AssignmentType.BOARD, groupId, projectName);
                     }}
                  >
                     {I18n.t("views.company.workforce_planning.projects.labor_graph.board")}
                  </Button>
               </FlexList>
            </FlexList>
         </Box>
         <Box padding="md">
            <EmptyState compact>
               <EmptyState.NoItems />
               <EmptyState.Title className="empty-state-description">
                  {I18n.t(
                     "views.company.workforce_planning.projects.labor_graph.empty_state.title",
                  )}
               </EmptyState.Title>
               <EmptyState.Description className="empty-state-description">
                  {I18n.t(
                     "views.company.workforce_planning.projects.labor_graph.empty_state.description",
                  )}
               </EmptyState.Description>
               <EmptyState.Actions>
                  <FlexList direction="column" space="sm" alignItems="center">
                     <Button onClick={() => handleCreateLaborPlanClick(projectId)}>
                        {I18n.t(
                           "views.company.workforce_planning.projects.labor_graph.create_labor_plan",
                        )}
                     </Button>
                     <Button
                        variant="secondary"
                        onClick={() => {
                           onCreateAssignmentClick(assignmentTearsheetDispatch, projectId);
                        }}
                     >
                        {I18n.t(
                           "views.company.workforce_planning.projects.labor_graph.create_assignment",
                        )}
                     </Button>
                  </FlexList>
               </EmptyState.Actions>
            </EmptyState>
         </Box>
      </React.Fragment>
   );
};

const LaborGraph = (props: LaborGraphPropTypes & { graphData: any }) => {
   const I18n = useI18nContext();
   const { groupId } = useGroupContext();
   const { projectId, projectName, handleCreateLaborPlanClick, graphData } = props;
   const [selectedView, setSelectedView] = React.useState<"both" | "assignments" | "requests">(
      "both",
   );

   return (
      <Box padding="md">
         <FlexList justifyContent="space-between">
            <H2>{I18n.t("views.company.workforce_planning.projects.labor_graph.title")}</H2>
            <FlexList space="sm">
               <Button variant="secondary" onClick={() => handleCreateLaborPlanClick(projectId)}>
                  {I18n.t("views.company.workforce_planning.labor_plan.create_title")}
               </Button>
               <Button
                  icon={<Gantt />}
                  variant="secondary"
                  onClick={() => {
                     navigateTo(AssignmentType.GANTT, groupId, projectName);
                  }}
               >
                  {I18n.t("views.company.workforce_planning.projects.labor_graph.project_gantt")}
               </Button>
            </FlexList>
         </FlexList>
         <SegmentedController>
            <SegmentedController.Segment
               onClick={() => setSelectedView("assignments")}
               active={selectedView === "assignments"}
            >
               {I18n.t("views.company.workforce_planning.assignments")}
            </SegmentedController.Segment>
            <SegmentedController.Segment
               onClick={() => setSelectedView("requests")}
               active={selectedView === "requests"}
            >
               {I18n.t("views.company.workforce_planning.requests")}
            </SegmentedController.Segment>
            <SegmentedController.Segment
               onClick={() => setSelectedView("both")}
               active={selectedView === "both"}
            >
               {I18n.t("views.company.workforce_planning.both")}
            </SegmentedController.Segment>
         </SegmentedController>
         <Box paddingTop="md">
            <HighchartsLaborGraph
               data={processTotalsDataForGraph(graphData, selectedView, I18n)}
               graphStart={new Date().getTime()}
               height={"300px"}
               enableZoom={true}
            />
         </Box>
      </Box>
   );
};
export const LaborGraphCard = (props: LaborGraphPropTypes) => {
   const { projectId, projectName, handleCreateLaborPlanClick } = props;
   const { data: totals, isLoading } = useGetRawProjectTotalsQuery(projectId);

   return (
      <Card style={{ marginBottom: "5px" }}>
         <Spinner loading={isLoading}>
            {totals?.breakdown_data?.length ?? 0 > 0 ? (
               <LaborGraph
                  projectId={projectId}
                  projectName={projectName}
                  handleCreateLaborPlanClick={handleCreateLaborPlanClick}
                  graphData={totals}
               />
            ) : (
               <EmptyLaborGraph
                  projectId={projectId}
                  projectName={projectName}
                  handleCreateLaborPlanClick={handleCreateLaborPlanClick}
               />
            )}
         </Spinner>
      </Card>
   );
};

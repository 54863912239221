import { Trash } from "@procore/core-icons";
import {
   Button,
   Modal,
   Tooltip,
   Typography,
   useI18nContext,
   ZIndexProvider,
} from "@procore/core-react";
import type { TableApi } from "@procore/data-table";
import { useRowSelectionState } from "@procore/data-table";
import React, { useEffect, useState } from "react";

export type ConfirmDeleteModalPeoplePropTypes = {
   successText: string;
   deleteText: string;
   headerText: string;
   tooltipText: string;
   id?: string;
   isBulk?: boolean;
   tableApi?: TableApi;
};

export const ConfirmDeletePeopleModal = (props: ConfirmDeleteModalPeoplePropTypes) => {
   const { isBulk = false } = props;
   const I18n = useI18nContext();
   const [open, setOpen] = React.useState(false);
   const selectedRows = useRowSelectionState().map((x: any) => x.data);
   const [itHasAdmin, setItHasAdmin] = useState(false);
   const count = isBulk ? selectedRows.length : 1;

   useEffect(() => {
      if (selectedRows.length > 0) {
         const adminCount = selectedRows.filter((user) => user.permission === "Admin").length;
         setItHasAdmin(adminCount > 1 || (adminCount === 1 && selectedRows.length > 1));
      }
   }, [selectedRows]);

   return (
      <>
         <ZIndexProvider>
            <Tooltip
               overlay={I18n.t(props.tooltipText)}
               placement="left"
               trigger={itHasAdmin ? "hover" : "none"}
            >
               <div>
                  <Button
                     icon={<Trash />}
                     onClick={() => setOpen(true)}
                     variant="tertiary"
                     data-testid="trash-btn"
                     disabled={itHasAdmin}
                  />
               </div>
            </Tooltip>
         </ZIndexProvider>
         <Modal open={open} onClose={() => setOpen(false)} width="md">
            <Modal.Header>{I18n.t(props.headerText, { count })}</Modal.Header>
            <Modal.Body>
               <Typography>{I18n.t(props.deleteText, { count })}</Typography>
            </Modal.Body>
            <Modal.Footer>
               <Modal.FooterButtons>
                  <Button variant="secondary" onClick={() => setOpen(false)}>
                     {I18n.t("views.company.workforce_planning.cancel")}
                  </Button>
                  <Button variant="primary" onClick={() => setOpen(false)}>
                     {I18n.t("views.company.workforce_planning.delete")}
                  </Button>
               </Modal.FooterButtons>
            </Modal.Footer>
         </Modal>
      </>
   );
};

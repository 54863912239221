import React from "react";
import type { ActivitiesResponseProps } from "../types";
import { Box, Typography, useI18nContext } from "@procore/core-react";
import { customFieldsCard, formatCurrency, formatDate, getTranslationLabel } from "../helpers";
import classnames from "classnames";
import styles from "./project-details.scss";
const cx = classnames.bind(styles);

export const renderChange = (
   label: string,
   oldValue?: string | number | boolean | null,
   newValue?: string | number | boolean | null,
) => {
   const I18n = useI18nContext();
   return (
      <>
         <span className={cx("light-text")}>{`Changed ${getTranslationLabel(
            label,
            I18n,
         )} from `}</span>
         <span className={cx("dark-highlighted-text")}>{`${oldValue} `}</span>
         <span className={cx("light-text")}>{`to `}</span>
         <span className={cx("dark-highlighted-text")}>{`${newValue}`}</span>
      </>
   );
};

export const renderAdd = (label: string, value: string | number | boolean | null) => {
   const I18n = useI18nContext();
   return (
      <>
         <span className={cx("light-text")}>{`Added ${getTranslationLabel(label, I18n)}: `}</span>
         <span className={cx("dark-highlighted-text")}>{value}</span>
      </>
   );
};

export const renderRemove = (label: string, value: string | number | boolean | null) => {
   const I18n = useI18nContext();
   return (
      <>
         <span className={cx("light-text")}>{`Removed ${getTranslationLabel(label, I18n)}: `}</span>
         <span className={cx("dark-highlighted-text")}>{value}</span>
      </>
   );
};

export const ProjectActiviesInfoCard: React.FC<{ activity: ActivitiesResponseProps }> = ({
   activity,
}) => {
   const I18n = useI18nContext();
   const dataCardActivities = (data: ActivitiesResponseProps) => {
      const { meta } = data;
      const { property_name, new_val, old_val } = meta || {};

      if (!meta) return null;

      switch (property_name) {
         case "name":
         case "color":
         case "status":
         case "timezone":
         case "address_1":
         case "address_2":
         case "city_town":
         case "state_province":
         case "zipcode":
         case "country":
         case "profile_pic_url":
         case "job_number":
         case "project_type":
            if (new_val !== null && old_val !== null)
               return renderChange(property_name, old_val, new_val);
            if (new_val === null && old_val !== undefined)
               return renderRemove(property_name, old_val);
            if (old_val === null && new_val !== undefined) return renderAdd(property_name, new_val);
            break;
         case "start_date":
         case "est_end_date":
            return renderChange(
               property_name,
               formatDate(old_val as number),
               formatDate(new_val as number),
            );
         case "daily_start_time":
         case "daily_end_time":
            return renderChange(property_name, old_val, new_val);
         case "bid_rate":
            if (new_val !== null && old_val !== null)
               return renderChange(
                  property_name,
                  formatCurrency(old_val as number, I18n),
                  formatCurrency(new_val as number, I18n),
               );
            if (new_val === null && old_val !== null)
               return renderRemove(property_name, formatCurrency(old_val as number, I18n));
            if (old_val === null && new_val !== null)
               return renderAdd(property_name, formatCurrency(new_val as number, I18n));
            break;
         case "percent_complete":
            if (new_val !== null && old_val !== null)
               return renderChange(property_name, `${old_val}%`, `${new_val}%`);
            if (new_val === null && old_val !== null)
               return renderRemove(property_name, `${old_val}%`);
            if (old_val === null && new_val !== null)
               return renderAdd(property_name, `${new_val}%`);
            break;
         case "customer_name":
            if (new_val !== null && old_val !== null)
               return renderChange(property_name, old_val, new_val);
            if (new_val === null && old_val !== undefined)
               return renderRemove(property_name, old_val);
            if (old_val === null && new_val !== undefined) return renderAdd(property_name, new_val);
            break;
         case "group_ids":
            return (
               <>
                  <span>{`${
                     activity?.action_type === "create"
                        ? "Added Project to the "
                        : "Removed Project from the "
                  }`}</span>
                  <span className={cx("dark-highlighted-text")}>{`${data.subject_2_name} `}</span>
                  <span>{`Group`}</span>
               </>
            );
         case "custom_fields":
            return customFieldsCard(data);
         default:
            return null;
      }
   };

   return (
      <Box display="flex" style={{ marginTop: "10px" }}>
         <Typography intent="body" color="gray30">
            {dataCardActivities(activity)}
         </Typography>
      </Box>
   );
};

import { Checkbox, useI18nContext } from "@procore/core-react";
import type { ColumnDefinition } from "@procore/data-table";
import React, { useEffect } from "react";

type CustomFilterComponentPropTypes = {
   onChange: (newValues: any[]) => void;
   value?: any[];
   columnDefinition?: ColumnDefinition;
};

export const BooleanFilter = (props: CustomFilterComponentPropTypes) => {
   const I18n = useI18nContext();
   const [selected, setSelected] = React.useState<boolean[]>([]);
   const [labels, setLabels] = React.useState<{ [key: string]: string }>({
      true: I18n.t("views.company.workforce_planning.boolean_values.yes_value"),
      false: I18n.t("views.company.workforce_planning.boolean_values.no_value"),
   });

   useEffect(() => {
      const getFilterOptions = props.columnDefinition?.filterProps?.getFilterOptions;
      if (getFilterOptions) {
         const options = getFilterOptions();
         setLabels(options);
      }
   }, []);

   useEffect(() => {
      const preSelected = props.value ? props.value.map((x: { value: boolean }) => x.value) : [];
      setSelected(preSelected);
   }, [props.value]);

   function handleChange(value: boolean) {
      const newSelected = [...selected]; // Copying current state
      const index = newSelected.indexOf(value);
      if (index > -1) {
         newSelected.splice(index, 1); // Remove if already selected
      } else {
         newSelected.push(value); // Add if not selected
      }
      const onChangeParam = newSelected.map((x: boolean) => ({
         value: x,
         label: x
            ? I18n.t("views.company.workforce_planning.boolean_values.yes_value")
            : I18n.t("views.company.workforce_planning.boolean_values.no_value"), // Set label based on boolean value
      }));
      props.onChange(onChangeParam);
      setSelected(newSelected);
   }

   return (
      <React.Fragment>
         <Checkbox checked={selected.includes(true)} onChange={() => handleChange(true)}>
            {labels.true}
         </Checkbox>
         <Checkbox checked={selected.includes(false)} onChange={() => handleChange(false)}>
            {labels.false}
         </Checkbox>
      </React.Fragment>
   );
};
